// NeighborhoodType.model.ts

import { FileMedia, IFileMedia } from "./file-media.model";

export interface INeighborhoodType {
  neighborhoodTypeId: string;
  name: string;
  fileId?: string;
  uidCreatedBy?: string; // Usando ? para indicar que es opcional
  uidUpdatedBy?: string; // Usando ? para indicar que es opcional
  createdAt: string;
  updatedAt: string;
  image?: IFileMedia;
}

export class NeighborhoodType {
  neighborhoodTypeId: string;
  name: string;
  fileId?: string;
  uidCreatedBy?: string; // Usando ? para indicar que es opcional
  uidUpdatedBy?: string; // Usando ? para indicar que es opcional
  createdAt: Date;
  updatedAt: Date;
  image?: FileMedia;

  constructor(data: INeighborhoodType) {
    this.neighborhoodTypeId = data.neighborhoodTypeId;
    this.name = data.name;
    this.fileId = data.fileId;
    this.uidCreatedBy = data.uidCreatedBy;
    this.uidUpdatedBy = data.uidUpdatedBy;
    this.createdAt = new Date(data.createdAt);
    this.updatedAt = new Date(data.updatedAt);
    this.image = data.image 
      ? new FileMedia(data.image) 
      : undefined;
  }
}
