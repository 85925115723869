export const environment = {
  production: false,
  urlApi: 'https://plk1lh8c88.execute-api.us-east-1.amazonaws.com/dev/api/v1',
  firebase: {
    projectId:"cradleresident",
    appId:"1:392168572149:web:dc2a1905640cc2564431bc",
    storageBucket:"cradleresident.firebasestorage.app",
    apiKey:"AIzaSyCA4w3n0x5ZxkHMZ80D3njzbuUc6NlZ1-M",
    authDomain:"cradleresident.firebaseapp.com",
    messagingSenderId:"392168572149",
    measurementId:"G-9G3X8ZXJ57"
  }
};
