import { Component } from '@angular/core';
import { AccessDoorGroupTypeService } from '../../../services/access-door-group-type.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SpinnerService } from '../../../services/spinner.service';
import { AccessDoorGroupType } from '../../../models/access-door-group-type.model';
import { AccessDoorGroupTypeModalComponent } from '../../../components/access-door-group-type-modal/access-door-group-type-modal.component';

@Component({
  selector: 'app-access-door-group-types-page',
  templateUrl: './access-door-group-types-page.component.html',
  styleUrl: './access-door-group-types-page.component.scss'
})
export class AccessDoorGroupTypesPageComponent {

  constructor(
    public accessDoorGroupTypeService: AccessDoorGroupTypeService,
    private modalService: NgbModal,
    public spinnerService: SpinnerService
  ) {
    this.accessDoorGroupTypeService.getAll();
  }

  async delete(
    accessDoorGroupType: AccessDoorGroupType
  ) {
    const flag = confirm(`¿Está seguro que desea borrar el grupo "${accessDoorGroupType.accessDoorGroup}"?`);

    if (flag) {
      await this.accessDoorGroupTypeService.delete(accessDoorGroupType);
    }
  }

  open(
    accessDoorGroupType?: AccessDoorGroupType
  ) {
    const modalRef = this.modalService.open(
      AccessDoorGroupTypeModalComponent
    );

    modalRef.componentInstance.accessDoorGroupType = accessDoorGroupType;
  }

}
