import { Component, Input } from '@angular/core';
import { HouseFlat } from '../../models/house-flat.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NeighborhoodService } from '../../services/neighborhood.service';

@Component({
  selector: 'app-devices-modal',
  templateUrl: './devices-modal.component.html',
  styleUrl: './devices-modal.component.scss'
})
export class DevicesModalComponent {

  @Input() houseFlat?: HouseFlat;

  constructor(
    public activeModal: NgbActiveModal,
    public neighborhoodService: NeighborhoodService,
  ) {}

}
