<div class="container-fluid py-3">
  <div class="row">

    @for (streetBuilding of streetBuildingService.streetsBuildings; track $index) {

      <app-street-building-item-2
        [streetBuilding]="streetBuilding">
      </app-street-building-item-2>

    }

  </div>
</div>
