// FileMedia.model.ts

export interface IFileMedia {
  fileId: string;
  urlFile: string;
  path: string;
  service: string;
  fileType: string;
  uidCreatedBy?: string; // Usando ? para indicar que es opcional
  uidUpdatedBy?: string; // Usando ? para indicar que es opcional
  createdAt: string;
  updatedAt: string;
}

export class FileMedia {
  fileId: string;
  urlFile: string;
  path: string;
  service: string;
  fileType: string;
  uidCreatedBy?: string; // Usando ? para indicar que es opcional
  uidUpdatedBy?: string; // Usando ? para indicar que es opcional
  createdAt: Date;
  updatedAt: Date;

  constructor(data: IFileMedia) {
    this.fileId = data.fileId;
    this.urlFile = data.urlFile;
    this.path = data.path;
    this.service = data.service;
    this.fileType = data.fileType;
    this.uidCreatedBy = data.uidCreatedBy;
    this.uidUpdatedBy = data.uidUpdatedBy;
    this.createdAt = new Date(data.createdAt);
    this.updatedAt = new Date(data.updatedAt);
  }
}
