<div class="container-fluid py-3">
  <div class="row">
    <div class="col-12">
      <p-table #dt
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        [filterDelay]="0"
        [loading]="loading"
        [paginator]="true"
        [rows]="10"
        [rowHover]="true"
        [rowsPerPageOptions]="[10, 25, 50]"
        [showCurrentPageReport]="true" 
        [value]="visits"
        pDroppable="row">
        
        <ng-template pTemplate="caption">
          <div class="container-fluid">
            <div class="row">
              <div class="col-8">
                <!-- @if(enableReports) {
                  <button
                    class="btn btn-primary d-flex align-items-center justify-content-center me-2"
                    type="button">
                    <i class="material-icons me-2">download</i> Descargar reporte
                  </button>
                } -->
              </div>
              <div class="col-4 text-end">
                <button 
                  (click)="visitService.getAll(neighborhoodId!)"
                  class="btn btn-primary rounded-circle me-2">
                  <i class="material-icons mt-1">refresh</i>
                </button>
              </div>
            </div>
          </div>
        </ng-template>

        <ng-template pTemplate="header">
          <tr>

            <th>
              
            </th>

            <th pSortableColumn="fullName">
              Nombre
              <p-sortIcon field="fullName"></p-sortIcon>
              <p-columnFilter type="text" field="fullName" display="menu"></p-columnFilter>
            </th>

            <th pSortableColumn="visitType.description">
              Tipo
              <p-sortIcon field="visitType.description"></p-sortIcon>
              <p-columnFilter type="text" field="visitType.description" display="menu"></p-columnFilter>
            </th>

            <th pSortableColumn="houseFlat.streetBuilding.name">
              Calle
              <p-sortIcon field="houseFlat.streetBuilding.name"></p-sortIcon>
              <p-columnFilter type="text" field="houseFlat.streetBuilding.name" display="menu"></p-columnFilter>
            </th>

            <th pSortableColumn="houseFlat.number">
              Casa
              <p-sortIcon field="houseFlat.number"></p-sortIcon>
              <p-columnFilter type="text" field="houseFlat.number" display="menu"></p-columnFilter>
            </th>

            <th>
              Creado por
            </th>

            <th pSortableColumn="arrivedAt">
              Llegada
              <p-sortIcon field="arrivedAt"></p-sortIcon>
              <p-columnFilter type="text" field="arrivedAt" display="menu"></p-columnFilter>
            </th>

            <th pSortableColumn="departedAt">
              Salida
              <p-sortIcon field="departedAt"></p-sortIcon>
              <p-columnFilter type="text" field="departedAt" display="menu"></p-columnFilter>
            </th>

            @if(moreDetails) {
              <th pSortableColumn="status">
                Estatus
                <p-sortIcon field="status"></p-sortIcon>
              </th>
            }

            <th>Acciones</th>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-visit>
          <tr class="p-selectable-row">

            <td>
              @if(visit.service) {
                <img
                  [src]="visit.service.image.urlFile"
                  [alt]="visit.service.service"
                  style="width: calc(1.5vh + 1.5rem);">
              } @else {
                <img
                  [src]="visit.visitType.image.urlFile"
                  [alt]="visit.visitType.description"
                  style="width: calc(1.5vh + 1.5rem);">
              }
            </td>

            <td>
              <span class="p-column-title">Nombre</span>
              {{visit.fullName}}
            </td>

            <td>
              <span class="p-column-title">Tipo</span>
              {{ visit.visitType.description }}
              @if(visit.service) {
                - {{ visit.service.service }}
              }
            </td>

            <td>
              <span class="p-column-title">Calle</span>
              {{ visit.houseFlat ? visit.houseFlat.streetBuilding.name : '-' }}
            </td>

            <td>
              <span class="p-column-title">Casa</span>
              @if(visit.houseFlat) {
                <span [ngClass]="{
                    'text-danger': !visit.houseFlat.status,
                    'text-success': visit.houseFlat.status
                  }"
                  [ngbTooltip]="visit.houseFlat.status ? 'Activo' : 'Moroso'">
                  {{ visit.houseFlat.number }}
                </span>
              } @else {
                -
              }
            </td>

            <td>
              <span class="p-column-title">Creado por</span>
              @if (visit.token) {
                Residente
              } @else {
                Vigilancia
              }
            </td>

            <td>
              <span class="p-column-title">Llegada</span>
              @if(visit.arrivedAt) {
                {{ visit.arrivedAt | dateToMoment: 'DD MMM yyyy HH:mm' : neighborhoodService.currentNeighborhood?.timeZone }}
              } @else {
                -
              }
            </td>

            <td>
              <span class="p-column-title">Salida</span>
              @if(visit.departedAt) {
                {{ visit.departedAt | dateToMoment: 'DD MMM yyyy HH:mm' : neighborhoodService.currentNeighborhood?.timeZone }}
              } @else {
                -
              }
            </td>

            @if(moreDetails) {
              <td>
                <span class="p-column-title">Estatus</span>
                <i 
                  class="material-icons"
                  [ngbTooltip]="visit.status ? 'Aprobado' : 'Rechazado'"
                  [ngClass]="{
                    'text-success': visit.status,
                    'text-danger': !visit.status
                  }">
                  {{ visit.status ? 'check' : 'close' }}
                </i>
              </td>
            }

            <td>
              <button 
                class="btn btn-sm btn-light rounded-circle me-2"
                (click)="open(visit)">
                <i class="material-icons mt-1">more_vert</i>
              </button>

              @if(authService.currentUser?.profile === 'sudo') {
                <button 
                  class="btn btn-sm btn-danger rounded-circle"
                  (click)="delete(visit)">
                  <i class="material-icons mt-1">delete</i>
                </button>
              }

            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>
