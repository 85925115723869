import { Component, Input } from '@angular/core';
import { ParcelService } from '../../models/parcel-service.model';
import { FormGroup, Validators } from '@angular/forms';
import { DataFormModel } from '../../models/data-form-model.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UtilService } from '../../services/util.service';
import { ParcelServiceService } from '../../services/parcel-service.service';

@Component({
  selector: 'app-parcel-service-modal',
  templateUrl: './parcel-service-modal.component.html',
  styleUrl: './parcel-service-modal.component.scss'
})
export class ParcelServiceModalComponent {

  @Input() parcelService?: ParcelService;

  public dataForm: DataFormModel = {
      image: {
        value: '',
        type: 'image',
        label: '',
        required: false,
        validators: []
      },
      parcelService: {
        value: '',
        type: 'text',
        label: 'Nombre de la paquetería',
        requiredMessage: 'Por favor, ingrese el nombre de la paquetería',
        required: true,
        validators: [Validators.required]
      },
      showExtraField: {
        value: false,
        type: 'switch',
        label: 'Mostrar campo extra',
        required: false,
      },
      status: {
        value: true,
        type: 'switch',
        label: 'Publicado',
        required: false,
      },
    };

    public submitted: boolean = false;
    public loading: boolean = false;
  
    public formParcelService!: FormGroup;
  
    public imageBase64: string | null = null;
  
    constructor(
      public activeModal: NgbActiveModal,
      public parcelServiceService: ParcelServiceService,
      public utilService: UtilService,
    ) {}
  
    ngOnInit(): void {
      this.loadData();
    }
  
    get f() {
      return this.formParcelService.controls;
    }
  
  
    /*
      ██████╗██████╗ ███████╗ █████╗ ████████╗███████╗
    ██╔════╝██╔══██╗██╔════╝██╔══██╗╚══██╔══╝██╔════╝
    ██║     ██████╔╝█████╗  ███████║   ██║   █████╗
    ██║     ██╔══██╗██╔══╝  ██╔══██║   ██║   ██╔══╝
    ╚██████╗██║  ██║███████╗██║  ██║   ██║   ███████╗
      ╚═════╝╚═╝  ╚═╝╚══════╝╚═╝  ╚═╝   ╚═╝   ╚══════╝
  
    */
      async create() {
  
      this.formParcelService.value.imageBase64 = this.imageBase64;
  
      const flag = await this.parcelServiceService.create(
        this.formParcelService.value,
      );
  
      if(flag) {
        for(let key of Object.keys(this.dataForm)) {
          this.dataForm[key].value = '';
        }
  
        this.activeModal.close();
      }
  
    }
  
    /*
    ██╗      ██████╗  █████╗ ██████╗ ██████╗  █████╗ ████████╗ █████╗
    ██║     ██╔═══██╗██╔══██╗██╔══██╗██╔══██╗██╔══██╗╚══██╔══╝██╔══██╗
    ██║     ██║   ██║███████║██║  ██║██║  ██║███████║   ██║   ███████║
    ██║     ██║   ██║██╔══██║██║  ██║██║  ██║██╔══██║   ██║   ██╔══██║
    ███████╗╚██████╔╝██║  ██║██████╔╝██████╔╝██║  ██║   ██║   ██║  ██║
    ╚══════╝ ╚═════╝ ╚═╝  ╚═╝╚═════╝ ╚═════╝ ╚═╝  ╚═╝   ╚═╝   ╚═╝  ╚═╝
  
    */
    async loadData() {
      this.loading = true;
  
      if(this.parcelService) {
  
        this.dataForm = {
          ...this.dataForm,
        }
  
        for(let key of Object.keys(this.dataForm)) {
  
          if (key in this.parcelService!) {
            const standardKey = key as keyof typeof this.parcelService;
        
            // Ahora puedes acceder a `this.machine[machineKey]` sin errores de tipo.
            this.dataForm[key].value = this.parcelService[standardKey]!;
          }
        } 
      }
      
      this.formParcelService = this.utilService.buildForm(this.dataForm);
  
      this.loading = false;
    }
  
    /*
      ██████╗ ███╗   ██╗███████╗██╗██╗     ███████╗ ██████╗██╗  ██╗ █████╗ ███╗   ██╗ ██████╗ ███████╗
    ██╔═══██╗████╗  ██║██╔════╝██║██║     ██╔════╝██╔════╝██║  ██║██╔══██╗████╗  ██║██╔════╝ ██╔════╝
    ██║   ██║██╔██╗ ██║█████╗  ██║██║     █████╗  ██║     ███████║███████║██╔██╗ ██║██║  ███╗█████╗
    ██║   ██║██║╚██╗██║██╔══╝  ██║██║     ██╔══╝  ██║     ██╔══██║██╔══██║██║╚██╗██║██║   ██║██╔══╝
    ╚██████╔╝██║ ╚████║██║     ██║███████╗███████╗╚██████╗██║  ██║██║  ██║██║ ╚████║╚██████╔╝███████╗
      ╚═════╝ ╚═╝  ╚═══╝╚═╝     ╚═╝╚══════╝╚══════╝ ╚═════╝╚═╝  ╚═╝╚═╝  ╚═╝╚═╝  ╚═══╝ ╚═════╝ ╚══════╝
  
    */
    /**
     * FUNCTION onFileChange
     * 
     * @param event 
     */
    onFileChange(event: any) {
      console.log(event);
  
      const file = event.target.files[0];
  
      const reader = new FileReader();
      reader.readAsDataURL(file); // toBase64
      reader.onload = () => {
        this.imageBase64 = reader.result as string; // base64 Image src
        console.log(this.imageBase64);
        
      };
      
    }
  
    /*
      ██████╗ ███╗   ██╗███████╗██╗   ██╗██████╗ ███╗   ███╗██╗████████╗
    ██╔═══██╗████╗  ██║██╔════╝██║   ██║██╔══██╗████╗ ████║██║╚══██╔══╝
    ██║   ██║██╔██╗ ██║███████╗██║   ██║██████╔╝██╔████╔██║██║   ██║
    ██║   ██║██║╚██╗██║╚════██║██║   ██║██╔══██╗██║╚██╔╝██║██║   ██║
    ╚██████╔╝██║ ╚████║███████║╚██████╔╝██████╔╝██║ ╚═╝ ██║██║   ██║
      ╚═════╝ ╚═╝  ╚═══╝╚══════╝ ╚═════╝ ╚═════╝ ╚═╝     ╚═╝╚═╝   ╚═╝
  
    */
    async onSubmit() {
  
      console.log(this.formParcelService.value);
      
      this.submitted = true;
  
      if(this.formParcelService.invalid) {
        return;
      }
  
      if(this.parcelService) {
  
        await this.update();
      } else {
        await this.create();
      }
    }
  
    /*
    ██╗   ██╗██████╗ ██████╗  █████╗ ████████╗███████╗
    ██║   ██║██╔══██╗██╔══██╗██╔══██╗╚══██╔══╝██╔════╝
    ██║   ██║██████╔╝██║  ██║███████║   ██║   █████╗
    ██║   ██║██╔═══╝ ██║  ██║██╔══██║   ██║   ██╔══╝
    ╚██████╔╝██║     ██████╔╝██║  ██║   ██║   ███████╗
      ╚═════╝ ╚═╝     ╚═════╝ ╚═╝  ╚═╝   ╚═╝   ╚══════╝
  
    */
    async update() {
  
      this.formParcelService.value.parcelServiceId = this.parcelService!.parcelServiceId;
  
      this.formParcelService.value.imageBase64 = this.imageBase64;
  
      const flag = await this.parcelServiceService.update(
        this.formParcelService.value
      );
  
      if(flag) {
  
        for(let key of Object.keys(this.dataForm)) {
          this.dataForm[key].value = '';
        }
  
        this.activeModal.close();
      }
    }

}
