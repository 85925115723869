export interface IDevice {
  deviceId: string;
  uidUser: string;
  houseFlatId?: string;
  os: string;
  version: string;
  brand: string;
  model: string;
  description: string;
  token: string;
  status: boolean;
  uidCreatedBy?: string;
  uidUpdatedBy?: string;
  createdAt: string;
  updatedAt: string;
}

export class Device {
  deviceId: string;
  uidUser: string;
  houseFlatId?: string;
  os: string;
  version: string;
  brand: string;
  model: string;
  description: string;
  token: string;
  status: boolean;
  uidCreatedBy?: string;
  uidUpdatedBy?: string;
  createdAt: string;
  updatedAt: string;

  constructor(data: IDevice) {
    this.deviceId = data.deviceId;
    this.uidUser = data.uidUser;
    this.houseFlatId = data.houseFlatId;
    this.os = data.os;
    this.version = data.version;
    this.brand = data.brand;
    this.model = data.model;
    this.description = data.description;
    this.token = data.token;
    this.status = data.status;
    this.uidCreatedBy = data.uidCreatedBy;
    this.uidUpdatedBy = data.uidUpdatedBy;
    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;
  }
}
