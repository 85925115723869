import { Injectable } from '@angular/core';
import { HouseFlat, IHouseFlat } from '../models/house-flat.model';
import { AuthService } from './auth.service';
import { HttpClient } from '@angular/common/http';
import { SpinnerService } from './spinner.service';
import { ToastService } from './toast.service';
import { StreetBuildingService } from './street-building.service';
import { environment } from '../../environments/environment';
import { IServerResponse, ServerResponse } from '../models/server-response.model';

@Injectable({
  providedIn: 'root'
})
export class HouseFlatService {

  public loading: boolean = false;
  public housesFlats: HouseFlat[] = [];

  constructor(
    public authService: AuthService,
    private http: HttpClient,
    public spinnerService: SpinnerService,
    public streetBuildingService: StreetBuildingService,
    public toastService: ToastService
  ) { 

  }

  /*
   ██████╗██████╗ ███████╗ █████╗ ████████╗███████╗
  ██╔════╝██╔══██╗██╔════╝██╔══██╗╚══██╔══╝██╔════╝
  ██║     ██████╔╝█████╗  ███████║   ██║   █████╗
  ██║     ██╔══██╗██╔══╝  ██╔══██║   ██║   ██╔══╝
  ╚██████╗██║  ██║███████╗██║  ██║   ██║   ███████╗
   ╚═════╝╚═╝  ╚═╝╚══════╝╚═╝  ╚═╝   ╚═╝   ╚══════╝

  */
   async create(
    neighborhoodId: string,
    streetBuildingId: string,
    houseFlat: IHouseFlat,
    messageOdd: boolean = false,
    message: string = ''
  ): Promise<boolean> {

    let flag = false;

    const urlApi = `${environment.urlApi}/neighborhoods/${neighborhoodId}/streets-buildings/${streetBuildingId}/houses-flats`;
    this.spinnerService.loading = true;
    this.spinnerService.message = `Registrando casa${messageOdd ? ` ${message}` : ''}`;

    try {

      const token = await this.authService.getToken();

      const response = await new Promise<ServerResponse>(
        (resolve, reject) => {
          this.http.post<IServerResponse>(
            urlApi,
            JSON.stringify(houseFlat),
            {
              headers: {
                "content-type": "application/json",
                'Authorization': token.toString()
              }
            }
          ).subscribe({
            next: (
                response: IServerResponse
              ) => resolve(
                new ServerResponse(response)
              ),
            error: (error) => {
              if (error.error) {
                resolve(new ServerResponse(error.error));
              } else {
                reject(error);
                this.toastService.show(
                  {
                    header: 'Error de servidor',
                    body: `${error}`,
                    classname: 'bg-danger text-white'
                  }
                );
              }
            },
          });
        }
      );

      console.log(response);

      if(response.statusCode === 201) {
        flag = true;

        this.toastService.show(
          {
            body: `${response.message}`,
            classname: 'bg-success text-white'
          }
        );

        this.getAll(
          neighborhoodId,
          streetBuildingId
        );

        this.streetBuildingService.getAll(
          neighborhoodId
        );

      } else {

        this.toastService.show(
          {
            header: 'Error de servidor',
            body: `${response.message}`,
            classname: 'bg-danger text-white'
          }
        );

      }

    } catch(error) {
      console.log(error);

      this.toastService.show(
        {
          header: 'Error',
          body: `${error}`,
          classname: 'bg-danger text-white'
        }
      );
    }

    this.spinnerService.loading = false;
    this.spinnerService.message = '';

    return flag;

  }

  /*
  ██████╗ ███████╗██╗     ███████╗████████╗███████╗
  ██╔══██╗██╔════╝██║     ██╔════╝╚══██╔══╝██╔════╝
  ██║  ██║█████╗  ██║     █████╗     ██║   █████╗
  ██║  ██║██╔══╝  ██║     ██╔══╝     ██║   ██╔══╝
  ██████╔╝███████╗███████╗███████╗   ██║   ███████╗
  ╚═════╝ ╚══════╝╚══════╝╚══════╝   ╚═╝   ╚══════╝

  */
  async delete(
    neighborhoodId: string,
    houseFlat: HouseFlat
  ): Promise<boolean> {

    let flag = false;

    const urlApi = `${environment.urlApi}/neighborhoods/${neighborhoodId}/streets-buildings/${houseFlat.streetBuildingId}/houses-flats/${houseFlat.houseFlatId}`;

    this.spinnerService.loading = true;
    this.spinnerService.message = 'Eliminando casa';

    try {

      const token = await this.authService.getToken();

      const response = await new Promise<ServerResponse>(
        (resolve, reject) => {
          this.http.delete<IServerResponse>(
            urlApi,
            {
              headers: {
                'Authorization': token.toString()
              }
            }
          ).subscribe({
            next: (
                response: IServerResponse
              ) => resolve(
                new ServerResponse(response)
              ),
            error: (error) => {
              if (error.error) {
                resolve(new ServerResponse(error.error));
              } else {
                reject(error);
                this.toastService.show(
                  {
                    header: 'Error de servidor',
                    body: `${error}`,
                    classname: 'bg-danger text-white'
                  }
                );
              }
            },
          });
        }
      );

      console.log(response);

      if(response.statusCode === 200) {
        flag = true;

        this.toastService.show(
          {
            body: `${response.message}`,
            classname: 'bg-success text-white'
          }
        );

        this.getAll(
          neighborhoodId,
          houseFlat.streetBuildingId
        );

        this.streetBuildingService.getAll(
          neighborhoodId
        );

      } else {

        this.toastService.show(
          {
            header: 'Error de servidor',
            body: `${response.message}`,
            classname: 'bg-danger text-white'
          }
        );

      }

    } catch(error) {
      console.log(error);

      this.toastService.show(
        {
          header: 'Error',
          body: `${error}`,
          classname: 'bg-danger text-white'
        }
      );
    }

    this.spinnerService.loading = false;
    this.spinnerService.message = '';
  
    return flag;

  }

  /*
   ██████╗ ███████╗████████╗ █████╗ ██╗     ██╗
  ██╔════╝ ██╔════╝╚══██╔══╝██╔══██╗██║     ██║
  ██║  ███╗█████╗     ██║   ███████║██║     ██║
  ██║   ██║██╔══╝     ██║   ██╔══██║██║     ██║
  ╚██████╔╝███████╗   ██║   ██║  ██║███████╗███████╗
   ╚═════╝ ╚══════╝   ╚═╝   ╚═╝  ╚═╝╚══════╝╚══════╝

  */
  /**
   * FUNCTION getAll
   */
  async getAll(
    neighborhoodId: string,
    streetBuildingId: string,
  ) {

    this.loading = true;
    this.housesFlats = [];

    const urlApi = `${environment.urlApi}/neighborhoods/${neighborhoodId}/streets-buildings/${streetBuildingId}/houses-flats`;
    try {

      const token = await this.authService.getToken();

      const response = await new Promise<ServerResponse<{ housesFlats: IHouseFlat[] }>>(
        (resolve, reject) => {
          this.http.get<IServerResponse<{ housesFlats: IHouseFlat[] }>>(
            urlApi,
            {
              headers: {
                "content-type": "application/json",
                "Authorization": token.toString()
              }
            }
          ).subscribe({
            next: (
                response: IServerResponse<{ housesFlats: IHouseFlat[] }>
              ) => resolve(
                new ServerResponse<{ housesFlats: IHouseFlat[] }>(response)
              ),
            error: (error) => {
              if (error.error) {
                resolve(new ServerResponse(error.error));
              } else {
                reject(error);
              }
            },
          });
        }
      );

      console.log(response);

      if(response.statusCode === 200) {
        this.housesFlats = response.data!.housesFlats.map(
          houseFlat => new HouseFlat(houseFlat)
        );
      } else {

        this.toastService.show(
          {
            header: 'Error de servidor',
            body: `${response.message}`,
            classname: 'bg-danger text-white'
          }
        );

      }

    } catch(error) {
      console.log(error);

      this.toastService.show(
        {
          header: 'Error',
          body: `${error}`,
          classname: 'bg-danger text-white'
        }
      );
      
    }

    this.loading = false;

  }

  /*
  ██╗   ██╗██████╗ ██████╗  █████╗ ████████╗███████╗
  ██║   ██║██╔══██╗██╔══██╗██╔══██╗╚══██╔══╝██╔════╝
  ██║   ██║██████╔╝██║  ██║███████║   ██║   █████╗
  ██║   ██║██╔═══╝ ██║  ██║██╔══██║   ██║   ██╔══╝
  ╚██████╔╝██║     ██████╔╝██║  ██║   ██║   ███████╗
   ╚═════╝ ╚═╝     ╚═════╝ ╚═╝  ╚═╝   ╚═╝   ╚══════╝

  */
  async update(
    neighborhoodId: string,
    houseFlat: HouseFlat
  ): Promise<boolean> {

    let flag = false;

    const urlApi = `${environment.urlApi}/neighborhoods/${neighborhoodId}/streets-buildings/${houseFlat.streetBuildingId}/houses-flats/${houseFlat.houseFlatId}`;

    this.spinnerService.loading = true;
    this.spinnerService.message = 'Actualizando casa';

    try {

      const token = await this.authService.getToken();

      const response = await new Promise<ServerResponse>(
        (resolve, reject) => {
          this.http.put<IServerResponse>(
            urlApi,
            JSON.stringify(houseFlat),
            {
              headers: {
                "content-type": "application/json",
                'Authorization': token.toString()
              }
            }
          ).subscribe({
            next: (
                response: IServerResponse
              ) => resolve(
                new ServerResponse(response)
              ),
            error: (error) => {
              if (error.error) {
                resolve(new ServerResponse(error.error));
              } else {
                reject(error);
                this.toastService.show(
                  {
                    header: 'Error de servidor',
                    body: `${error}`,
                    classname: 'bg-danger text-white'
                  }
                );
              }
            },
          });
        }
      );

      console.log(response);

      if(response.statusCode === 200) {
        flag = true;

        this.toastService.show(
          {
            body: `${response.message}`,
            classname: 'bg-success text-white'
          }
        );

        this.getAll(
          neighborhoodId,
          houseFlat.streetBuildingId
        );

        this.streetBuildingService.getAll(
          neighborhoodId
        );

      } else {

        this.toastService.show(
          {
            header: 'Error de servidor',
            body: `${response.message}`,
            classname: 'bg-danger text-white'
          }
        );

      }

    } catch(error) {
      console.log(error);

      this.toastService.show(
        {
          header: 'Error',
          body: `${error}`,
          classname: 'bg-danger text-white'
        }
      );
    }

    this.spinnerService.loading = false;
    this.spinnerService.message = '';

    return flag;

  }
}
