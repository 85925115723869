export interface IAccessDoor {
  accessDoorId: string;
  accessDoorGroupId: string;
  type: boolean;
  label: string;
  firestoreUid: string;
  status: boolean;
  uidCreatedBy: string;
  uidUpdatedBy: string;
  createdAt: string;
  updatedAt: string;
}

export class AccessDoor {
  accessDoorId: string;
  accessDoorGroupId: string;
  type: boolean;
  label: string;
  firestoreUid: string;
  status: boolean;
  uidCreatedBy: string;
  uidUpdatedBy: string;
  createdAt: string;
  updatedAt: string;

  constructor(data: IAccessDoor) {
    this.accessDoorId = data.accessDoorId;
    this.accessDoorGroupId = data.accessDoorGroupId;
    this.type = data.type;
    this.label = data.label;
    this.firestoreUid = data.firestoreUid;
    this.status = data.status;
    this.uidCreatedBy = data.uidCreatedBy;
    this.uidUpdatedBy = data.uidUpdatedBy;
    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;
  }
}
