import { Injectable } from '@angular/core';
import { AccessDoorGroupType, IAccessDoorGroupType } from '../models/access-door-group-type.model';
import { AuthService } from './auth.service';
import { HttpClient } from '@angular/common/http';
import { SpinnerService } from './spinner.service';
import { ToastService } from './toast.service';
import { environment } from '../../environments/environment';
import { IServerResponse, ServerResponse } from '../models/server-response.model';

@Injectable({
  providedIn: 'root'
})
export class AccessDoorGroupTypeService {

  public loading: boolean = false;
  public accessDoorGroupTypes: AccessDoorGroupType[] = [];

  constructor(
    public authService: AuthService,
    private http: HttpClient,
    public spinnerService: SpinnerService,
    public toastService: ToastService
  ) { }

  /*
   ██████╗██████╗ ███████╗ █████╗ ████████╗███████╗
  ██╔════╝██╔══██╗██╔════╝██╔══██╗╚══██╔══╝██╔════╝
  ██║     ██████╔╝█████╗  ███████║   ██║   █████╗
  ██║     ██╔══██╗██╔══╝  ██╔══██║   ██║   ██╔══╝
  ╚██████╗██║  ██║███████╗██║  ██║   ██║   ███████╗
   ╚═════╝╚═╝  ╚═╝╚══════╝╚═╝  ╚═╝   ╚═╝   ╚══════╝

  */
  async create(
    accessDoorGroupType: IAccessDoorGroupType
  ): Promise<boolean> {

    let flag = false;
    const urlApi = `${environment.urlApi}/access-door-group-types`;

    this.spinnerService.loading = true;
    this.spinnerService.message = 'Registrando grupo de puertas de acceso';

    try {
      const token = await this.authService.getToken();

      const response = await new Promise<ServerResponse>(
        (resolve, reject) => {
          this.http.post<IServerResponse>(
            urlApi,
            JSON.stringify(accessDoorGroupType),
            {
              headers: {
                "content-type": "application/json",
                "Authorization": token.toString()
              }
            }
          ).subscribe({
            next: (
              response: IServerResponse
            ) => resolve(new ServerResponse(response)),
            error: (error) => {
              if (error.error) {
                resolve(new ServerResponse(error.error));
              } else {
                reject(error);
                this.toastService.show({
                  header: 'Error de servidor',
                  body: `${error}`,
                  classname: 'bg-danger text-white'
                });
              }
            }
          });
        }
      );

      if (response.statusCode === 201) {
        flag = true;
        this.toastService.show({
          body: `${response.message}`,
          classname: 'bg-success text-white'
        });
        this.getAll();
      } else {
        this.toastService.show({
          header: 'Error de servidor',
          body: `${response.message}`,
          classname: 'bg-danger text-white'
        });
      }

    } catch (error) {
      console.log(error);
      this.toastService.show({
        header: 'Error',
        body: `${error}`,
        classname: 'bg-danger text-white'
      });
    }

    this.spinnerService.loading = false;
    this.spinnerService.message = '';
    return flag;
  }

  /*
  ██████╗ ███████╗██╗     ███████╗████████╗███████╗
  ██╔══██╗██╔════╝██║     ██╔════╝╚══██╔══╝██╔════╝
  ██║  ██║█████╗  ██║     █████╗     ██║   █████╗
  ██║  ██║██╔══╝  ██║     ██╔══╝     ██║   ██╔══╝
  ██████╔╝███████╗███████╗███████╗   ██║   ███████╗
  ╚═════╝ ╚══════╝╚══════╝╚══════╝   ╚═╝   ╚══════╝

  */
  async delete(
    accessDoorGroupType: AccessDoorGroupType
  ): Promise<boolean> {

    let flag = false;
    const urlApi = `${environment.urlApi}/access-door-group-types/${accessDoorGroupType.accessDoorGroupTypeId}`;

    this.spinnerService.loading = true;
    this.spinnerService.message = 'Eliminando grupo de puertas de acceso';

    try {
      const token = await this.authService.getToken();

      const response = await new Promise<ServerResponse>(
        (resolve, reject) => {
          this.http.delete<IServerResponse>(
            urlApi,
            {
              headers: {
                "Authorization": token.toString()
              }
            }
          ).subscribe({
            next: (
              response: IServerResponse
            ) => resolve(new ServerResponse(response)),
            error: (error) => {
              if (error.error) {
                resolve(new ServerResponse(error.error));
              } else {
                reject(error);
                this.toastService.show({
                  header: 'Error de servidor',
                  body: `${error}`,
                  classname: 'bg-danger text-white'
                });
              }
            }
          });
        }
      );

      if (response.statusCode === 200) {
        flag = true;
        this.toastService.show({
          body: `${response.message}`,
          classname: 'bg-success text-white'
        });
        this.getAll();
      } else {
        this.toastService.show({
          header: 'Error de servidor',
          body: `${response.message}`,
          classname: 'bg-danger text-white'
        });
      }

    } catch (error) {
      console.log(error);
      this.toastService.show({
        header: 'Error',
        body: `${error}`,
        classname: 'bg-danger text-white'
      });
    }

    this.spinnerService.loading = false;
    this.spinnerService.message = '';
    return flag;
  }

  /*
   ██████╗ ███████╗████████╗
  ██╔════╝ ██╔════╝╚══██╔══╝
  ██║  ███╗█████╗     ██║
  ██║   ██║██╔══╝     ██║
  ╚██████╔╝███████╗   ██║
   ╚═════╝ ╚══════╝   ╚═╝

  */
  async get(
    accessDoorGroupTypeId: string
  ): Promise<AccessDoorGroupType | null> {

    let accessDoorGroupType: AccessDoorGroupType | null = null;
    const urlApi = `${environment.urlApi}/access-door-group-types/${accessDoorGroupTypeId}`;

    this.spinnerService.loading = true;
    this.spinnerService.message = 'Obteniendo grupo de puertas de acceso';

    try {
      const token = await this.authService.getToken();

      const response = await new Promise<ServerResponse<{ accessDoorGroupType: IAccessDoorGroupType }>>(
        (resolve, reject) => {
          this.http.get<IServerResponse<{ accessDoorGroupType: IAccessDoorGroupType }>>(
            urlApi,
            {
              headers: {
                "content-type": "application/json",
                "Authorization": token.toString()
              }
            }
          ).subscribe({
            next: (
              response: IServerResponse<{ accessDoorGroupType: IAccessDoorGroupType }>
            ) => resolve(
              new ServerResponse<{ accessDoorGroupType: IAccessDoorGroupType }>(response)
            ),
            error: (error) => {
              if (error.error) {
                resolve(new ServerResponse(error.error));
              } else {
                reject(error);
              }
            }
          });
        }
      );

      if (response.statusCode === 200) {
        accessDoorGroupType = new AccessDoorGroupType(response.data!.accessDoorGroupType);
      } else {
        this.toastService.show({
          header: 'Error de servidor',
          body: `${response.message}`,
          classname: 'bg-danger text-white'
        });
      }

    } catch (error) {
      console.log(error);
    }

    this.spinnerService.loading = false;
    this.spinnerService.message = '';
    return accessDoorGroupType;
  }

  /*
   ██████╗ ███████╗████████╗ █████╗ ██╗     ██╗
  ██╔════╝ ██╔════╝╚══██╔══╝██╔══██╗██║     ██║
  ██║  ███╗█████╗     ██║   ███████║██║     ██║
  ██║   ██║██╔══╝     ██║   ██╔══██║██║     ██║
  ╚██████╔╝███████╗   ██║   ██║  ██║███████╗███████╗
   ╚═════╝ ╚══════╝   ╚═╝   ╚═╝  ╚═╝╚══════╝╚══════╝

  */
  async getAll(): Promise<AccessDoorGroupType[]> {
    this.loading = true;
    this.accessDoorGroupTypes = [];

    const urlApi = `${environment.urlApi}/access-door-group-types`;

    try {
      const token = await this.authService.getToken();

      const response = await new Promise<ServerResponse<{ accessDoorGroupTypes: IAccessDoorGroupType[] }>>(
        (resolve, reject) => {
          this.http.get<IServerResponse<{ accessDoorGroupTypes: IAccessDoorGroupType[] }>>(
            urlApi,
            {
              headers: {
                "content-type": "application/json",
                "Authorization": token.toString()
              }
            }
          ).subscribe({
            next: (
              response: IServerResponse<{ accessDoorGroupTypes: IAccessDoorGroupType[] }>
            ) => resolve(
              new ServerResponse<{ accessDoorGroupTypes: IAccessDoorGroupType[] }>(response)
            ),
            error: (error) => {
              if (error.error) {
                resolve(new ServerResponse(error.error));
              } else {
                reject(error);
              }
            }
          });
        }
      );

      if (response.statusCode === 200) {
        this.accessDoorGroupTypes = response.data!.accessDoorGroupTypes.map(
          accessDoorGroupType => new AccessDoorGroupType(accessDoorGroupType)
        );
      } else {
        this.toastService.show({
          header: 'Error de servidor',
          body: `${response.message}`,
          classname: 'bg-danger text-white'
        });
      }

    } catch (error) {
      console.log(error);
    }

    this.loading = false;

    return this.accessDoorGroupTypes;
  }

  /*
  ██╗   ██╗██████╗ ██████╗  █████╗ ████████╗███████╗
  ██║   ██║██╔══██╗██╔══██╗██╔══██╗╚══██╔══╝██╔════╝
  ██║   ██║██████╔╝██║  ██║███████║   ██║   █████╗
  ██║   ██║██╔═══╝ ██║  ██║██╔══██║   ██║   ██╔══╝
  ╚██████╔╝██║     ██████╔╝██║  ██║   ██║   ███████╗
   ╚═════╝ ╚═╝     ╚═════╝ ╚═╝  ╚═╝   ╚═╝   ╚══════╝

  */
  async update(
    accessDoorGroupType: IAccessDoorGroupType
  ): Promise<boolean> {

    let flag = false;
    const urlApi = `${environment.urlApi}/access-door-group-types/${accessDoorGroupType.accessDoorGroupTypeId}`;

    this.spinnerService.loading = true;
    this.spinnerService.message = 'Actualizando grupo de puertas de acceso';

    try {
      const token = await this.authService.getToken();

      await this.http.put<IServerResponse>(
        urlApi,
        JSON.stringify(accessDoorGroupType),
        {
          headers: {
            "content-type": "application/json",
            "Authorization": token.toString()
          }
        }
      ).toPromise();
      
      flag = true;

    } catch (error) {
      console.log(error);
    }

    this.spinnerService.loading = false;
    return flag;
  }
}
