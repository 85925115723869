// HouseFlat.model.ts

import { IDevice, Device } from "./device.model";
import { IInvitation, Invitation } from "./invitation.model";
import { IPayment, Payment } from "./payment.model";
import { IResidentType, ResidentType } from "./resident-type.model";
import { IResident, Resident } from "./resident.model";
import { IStreetBuilding, StreetBuilding } from "./street-building.model";

export interface IHouseFlat {
  houseFlatId: string;
  streetBuildingId: string;
  number: string;
  fullName: string;
  residentTypeId?: string;
  phone: string;
  notes: string;
  status: boolean;
  uidCreatedBy?: string; // Usando ? para indicar que es opcional
  uidUpdatedBy?: string; // Usando ? para indicar que es opcional
  createdAt: string;
  updatedAt: string;
  invitations: IInvitation[];
  residents: IResident[];
  streetBuilding?: IStreetBuilding;
  payment?: IPayment;
  devices?: IDevice[];
  residentType?: IResidentType;
}

export class HouseFlat {
  houseFlatId: string;
  streetBuildingId: string;
  number: string;
  fullName: string;
  residentTypeId?: string;
  phone: string;
  notes: string;
  status: boolean;
  uidCreatedBy?: string; // Usando ? para indicar que es opcional
  uidUpdatedBy?: string; // Usando ? para indicar que es opcional
  createdAt: Date;
  updatedAt: Date;
  invitations: Invitation[];
  residents: Resident[];
  streetBuilding?: StreetBuilding;
  payment?: Payment;
  devices: Device[];
  residentType?: ResidentType;

  constructor(data: IHouseFlat) {
    this.houseFlatId = data.houseFlatId;
    this.streetBuildingId = data.streetBuildingId;
    this.number = data.number;
    this.fullName = data.fullName;
    this.residentTypeId = data.residentTypeId;
    this.phone = data.phone;
    this.notes = data.notes;
    this.status = data.status;
    this.uidCreatedBy = data.uidCreatedBy;
    this.uidUpdatedBy = data.uidUpdatedBy;
    this.createdAt = new Date(data.createdAt);
    this.updatedAt = new Date(data.updatedAt);
    this.streetBuilding = data.streetBuilding
      ? new StreetBuilding(data.streetBuilding)
      : undefined;
    this.invitations = data.invitations.map(
      (invitation) => new Invitation(invitation)
    );
    this.residents = data.residents.map(
      (resident) => new Resident(resident)
    );
    this.payment = data.payment 
      ? new Payment(data.payment) 
      : undefined;
    this.devices = data.devices
      ? data.devices.map((device) => new Device(device))
      : [];
    this.residentType = data.residentType
      ? new ResidentType(data.residentType)
      : undefined;
  }
}
