import { Component, Input } from '@angular/core';
import { Announcement } from '../../models/announcement.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-announcement-modal',
  templateUrl: './announcement-modal.component.html',
  styleUrl: './announcement-modal.component.scss'
})
export class AnnouncementModalComponent {

  @Input() announcement?: Announcement;
  @Input() neighborhoodId: string = '';

  constructor(
    public activeModal: NgbActiveModal,
  ) {}

}
