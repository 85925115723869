<div 
    class="container-fluid position-fixed bg-transparent-dark" 
    style="top: 0px; left: 0px; height: 100vh; z-index: 1024 !important"
>
  <div class="row h-100">

    <div class="col-7 col-sm-7 col-md-4 col-lg-3 col-xl-2 h-100 bg-primary pt-4 px-0 mb-5" style="height: 100vh;">
      <div class="row">
          <div class="col-12 pt-3">
            <div class="row">

              @for (menu of menuData; track menu.route) {
                <app-menu-item [menuItem]="menu"></app-menu-item>
              }

            </div>
          </div>
        </div>
    </div>

    <a class="col-5 col-sm-5 col-md-8 col-lg-9 col-xl-10" (click)="slideMenuService.showed = false">
    
    </a>

  </div>
</div>
