export interface IMovementItemInsight {
  income: number;
  expenses: number;
  balance: number;
  pendingIncome: number;
  pendingExpenses: number;
}

export class MovementItemInsight {
  income: number;
  expenses: number;
  balance: number;
  pendingIncome: number;
  pendingExpenses: number;

  constructor(data: IMovementItemInsight) {
    this.income = data.income;
    this.expenses = data.expenses;
    this.balance = data.balance;
    this.pendingIncome = data.pendingIncome;
    this.pendingExpenses = data.pendingExpenses;
  }
}
