import { FileMedia, IFileMedia } from "./file-media.model";

export interface IAccessDoorGroupType {
  accessDoorGroupTypeId: string;
  accessDoorGroup: string;
  fileId?: string;
  uidCreatedBy?: string;
  uidUpdatedBy?: string;
  createdAt: string;
  updatedAt: string;
  image?: IFileMedia;
}

export class AccessDoorGroupType {
  accessDoorGroupTypeId: string;
  accessDoorGroup: string;
  fileId?: string;
  uidCreatedBy?: string;
  uidUpdatedBy?: string;
  createdAt: string;
  updatedAt: string;
  image?: FileMedia;

  constructor(data: IAccessDoorGroupType) {
    this.accessDoorGroupTypeId = data.accessDoorGroupTypeId;
    this.accessDoorGroup = data.accessDoorGroup;
    this.fileId = data.fileId;
    this.uidCreatedBy = data.uidCreatedBy;
    this.uidUpdatedBy = data.uidUpdatedBy;
    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;
    this.image = data.image 
      ? new FileMedia(data.image) 
      : undefined;
  }
}
