import { Component } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { SlideMenuService } from '../../services/slide-menu.service';
import { NeighborhoodService } from '../../services/neighborhood.service';

@Component({
  selector: 'app-main-header',
  templateUrl: './main-header.component.html',
  styleUrl: './main-header.component.scss'
})
export class MainHeaderComponent {

  constructor(
    public authService: AuthService,
    public neighborhoodService: NeighborhoodService,
    public router: Router,
    public slideMenuService: SlideMenuService
  ) {

  }

}
