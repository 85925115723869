import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SessionLayoutComponent } from './layouts/session-layout/session-layout.component';
import { LogInPageComponent } from './pages/log-in-page/log-in-page.component';
import { DashboardLayoutComponent } from './layouts/dashboard-layout/dashboard-layout.component';
import { DashboardPageComponent } from './pages/dashboard/dashboard-page/dashboard-page.component';
import { NeighborhoodsPageComponent } from './pages/dashboard/neighborhoods-page/neighborhoods-page.component';
import { NeighborhoodTypesPageComponent } from './pages/dashboard/neighborhood-types-page/neighborhood-types-page.component';
import { ProofTypesPageComponent } from './pages/dashboard/proof-types-page/proof-types-page.component';
import { VisitTypesPageComponent } from './pages/dashboard/visit-types-page/visit-types-page.component';
import { ModulesPageComponent } from './pages/dashboard/security/modules-page/modules-page.component';
import { ProfilesPageComponent } from './pages/dashboard/security/profiles-page/profiles-page.component';
import { UsersPageComponent } from './pages/dashboard/security/users-page/users-page.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: '',
    component: SessionLayoutComponent,
    children: [
      {
        path: 'login',
        component: LogInPageComponent
      }
    ]
  },
  {
    path: 'dashboard',
    component: DashboardLayoutComponent,
    children: [
      {
        path: '',
        component: DashboardPageComponent
      },
      {
        path: 'neighborhoods',
        component: NeighborhoodsPageComponent
      },
      {
        path: 'catalogues',
        children: [
          {
            path: 'neighborhood-types',
            component: NeighborhoodTypesPageComponent
          },
          {
            path: 'proof-types',
            component: ProofTypesPageComponent
          },
          {
            path: 'visit-types',
            component: VisitTypesPageComponent
          }
        ]
      },
      {
        path: 'security',
        children: [
          {
            path: 'modules',
            component: ModulesPageComponent
          },
          {
            path: 'profiles',
            component: ProfilesPageComponent
          },
          {
            path: 'users',
            component: UsersPageComponent
          }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
