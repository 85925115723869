<div class="container-fluid py-3">
  <div class="row">

    @for(neighborhood of neighborhoodService.neighborhoods; track $index) {

      <a 
        class="col-12 bg-white py-3 px-3 rounded mb-4 text-decoration-none text-black"
        [routerLink]="['/neighborhoods', neighborhood.neighborhoodId]">
        <div class="row">

          <div class="col-12">
            <h4><b>{{ neighborhood.name }}</b></h4>
            <!-- <small class="text-secondary">{{ neighborhood.test ? 'Prueba' : 'Producción' }}</small> -->
          </div>

          <div class="col-12">
            <div class="row">

              <div class="col-6 col-md-4 col-lg-2">
                <div class="row">

                  <div class="col-12 mb-1">
                    <b><small class="text-secondary">Requiere de pruebas</small></b>
                  </div>
                  <div class="col-12">
                    @if(neighborhood.proofsRequired) {
                      <i class="material-icons text-success">
                        done
                      </i>
                    } @else {
                      <i class="material-icons text-danger">
                        close
                      </i>
                    }
                  </div>

                </div>
              </div>

              <div class="col-6 col-md-4 col-lg-2">
                <div class="row">

                  <div class="col-12 mb-1">
                    <b><small class="text-secondary">Requiere placas</small></b>
                  </div>
                  <div class="col-12">
                    @if(neighborhood.platesRequired) {
                      <i class="material-icons text-success">
                        done
                      </i>
                    } @else {
                      <i class="material-icons text-danger">
                        close
                      </i>
                    }
                  </div>
                  
                </div>
              </div>

              <div class="col-6 col-md-4 col-lg-2">
                <div class="row">

                  <div class="col-12 mb-1">
                    <b><small class="text-secondary">Requiere de marca</small></b>
                  </div>
                  <div class="col-12">
                    @if(neighborhood.brandRequired) {
                      <i class="material-icons text-success">
                        done
                      </i>
                    } @else {
                      <i class="material-icons text-danger">
                        close
                      </i>
                    }
                  </div>
                  
                </div>
              </div>

              <div class="col-6 col-md-4 col-lg-2">
                <div class="row">

                  <div class="col-12 mb-1">
                    <b><small class="text-secondary">Requiere de modelo</small></b>
                  </div>
                  <div class="col-12">
                    @if(neighborhood.modelRequired) {
                      <i class="material-icons text-success">
                        done
                      </i>
                    } @else {
                      <i class="material-icons text-danger">
                        close
                      </i>
                    }
                  </div>
                  
                </div>
              </div>

              <div class="col-6 col-md-4 col-lg-2">
                <div class="row">

                  <div class="col-12 mb-1">
                    <b><small class="text-secondary">Requiere de color</small></b>
                  </div>
                  <div class="col-12">
                    @if(neighborhood.colorRequired) {
                      <i class="material-icons text-success">
                        done
                      </i>
                    } @else {
                      <i class="material-icons text-danger">
                        close
                      </i>
                    }
                  </div>
                  
                </div>
              </div>

              <div class="col-6 col-md-4 col-lg-2">
                <div class="row">

                  <div class="col-12">
                    <b><small class="text-secondary">Zona horaro</small></b>
                  </div>
                  <div class="col-12">
                    <b>{{ neighborhood.timeZone}}</b>
                  </div>
                  
                </div>
              </div>

            </div>
          </div>

        </div>
      </a>

    }

  </div>
</div>