import { Component } from '@angular/core';
import { StreetBuildingService } from '../../../services/street-building.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StreetBuilding } from '../../../models/street-building.model';
import { StreetBuildingModalComponent } from '../../../components/street-building-modal/street-building-modal.component';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-streets-building-page',
  templateUrl: './streets-building-page.component.html',
  styleUrl: './streets-building-page.component.scss'
})
export class StreetsBuildingPageComponent {

  public neighborhoodId?: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    public streetBuildingService: StreetBuildingService,
    private modalService: NgbModal,
  ) {

    this.activatedRoute.params.subscribe(
      async params => {

        this.neighborhoodId = params['neighborhoodId'];

        await this.streetBuildingService.getAll(
          this.neighborhoodId!
        );
      }
    );

  }

  /*
   ██████╗ ███████╗████████╗████████╗ ██████╗ ████████╗ █████╗ ██╗     ██╗  ██╗ ██████╗ ██╗   ██╗███████╗███████╗███████╗███████╗██╗      █████╗ ████████╗███████╗
  ██╔════╝ ██╔════╝╚══██╔══╝╚══██╔══╝██╔═══██╗╚══██╔══╝██╔══██╗██║     ██║  ██║██╔═══██╗██║   ██║██╔════╝██╔════╝██╔════╝██╔════╝██║     ██╔══██╗╚══██╔══╝██╔════╝
  ██║  ███╗█████╗     ██║      ██║   ██║   ██║   ██║   ███████║██║     ███████║██║   ██║██║   ██║███████╗█████╗  ███████╗█████╗  ██║     ███████║   ██║   ███████╗
  ██║   ██║██╔══╝     ██║      ██║   ██║   ██║   ██║   ██╔══██║██║     ██╔══██║██║   ██║██║   ██║╚════██║██╔══╝  ╚════██║██╔══╝  ██║     ██╔══██║   ██║   ╚════██║
  ╚██████╔╝███████╗   ██║      ██║   ╚██████╔╝   ██║   ██║  ██║███████╗██║  ██║╚██████╔╝╚██████╔╝███████║███████╗███████║██║     ███████╗██║  ██║   ██║   ███████║
   ╚═════╝ ╚══════╝   ╚═╝      ╚═╝    ╚═════╝    ╚═╝   ╚═╝  ╚═╝╚══════╝╚═╝  ╚═╝ ╚═════╝  ╚═════╝ ╚══════╝╚══════╝╚══════╝╚═╝     ╚══════╝╚═╝  ╚═╝   ╚═╝   ╚══════╝

  */
  getTotalHousesFlats() {
    const total = this.streetBuildingService.streetsBuildings
      .reduce((sum, building) => sum + building.housesFlats.length, 0);
    return total;
  }

  /*
   ██████╗ ███████╗████████╗████████╗ ██████╗ ████████╗ █████╗ ██╗      █████╗  ██████╗████████╗██╗██╗   ██╗███████╗██╗  ██╗ ██████╗ ██╗   ██╗███████╗███████╗███████╗███████╗██╗      █████╗ ████████╗███████╗
  ██╔════╝ ██╔════╝╚══██╔══╝╚══██╔══╝██╔═══██╗╚══██╔══╝██╔══██╗██║     ██╔══██╗██╔════╝╚══██╔══╝██║██║   ██║██╔════╝██║  ██║██╔═══██╗██║   ██║██╔════╝██╔════╝██╔════╝██╔════╝██║     ██╔══██╗╚══██╔══╝██╔════╝
  ██║  ███╗█████╗     ██║      ██║   ██║   ██║   ██║   ███████║██║     ███████║██║        ██║   ██║██║   ██║█████╗  ███████║██║   ██║██║   ██║███████╗█████╗  ███████╗█████╗  ██║     ███████║   ██║   ███████╗
  ██║   ██║██╔══╝     ██║      ██║   ██║   ██║   ██║   ██╔══██║██║     ██╔══██║██║        ██║   ██║╚██╗ ██╔╝██╔══╝  ██╔══██║██║   ██║██║   ██║╚════██║██╔══╝  ╚════██║██╔══╝  ██║     ██╔══██║   ██║   ╚════██║
  ╚██████╔╝███████╗   ██║      ██║   ╚██████╔╝   ██║   ██║  ██║███████╗██║  ██║╚██████╗   ██║   ██║ ╚████╔╝ ███████╗██║  ██║╚██████╔╝╚██████╔╝███████║███████╗███████║██║     ███████╗██║  ██║   ██║   ███████║
   ╚═════╝ ╚══════╝   ╚═╝      ╚═╝    ╚═════╝    ╚═╝   ╚═╝  ╚═╝╚══════╝╚═╝  ╚═╝ ╚═════╝   ╚═╝   ╚═╝  ╚═══╝  ╚══════╝╚═╝  ╚═╝ ╚═════╝  ╚═════╝ ╚══════╝╚══════╝╚══════╝╚═╝     ╚══════╝╚═╝  ╚═╝   ╚═╝   ╚══════╝

  */
  getTotalActiveHousesFlats() {
    const total = this.streetBuildingService.streetsBuildings
      .reduce((sum, building) => sum + building.housesFlats.filter(houseFlat => houseFlat.status).length, 0);
    return total;
  }

  /*
   ██████╗ ███████╗████████╗████████╗ ██████╗ ████████╗ █████╗ ██╗     ██╗███╗   ██╗ █████╗  ██████╗████████╗██╗██╗   ██╗███████╗██╗  ██╗ ██████╗ ██╗   ██╗███████╗███████╗███████╗███████╗██╗      █████╗ ████████╗███████╗
  ██╔════╝ ██╔════╝╚══██╔══╝╚══██╔══╝██╔═══██╗╚══██╔══╝██╔══██╗██║     ██║████╗  ██║██╔══██╗██╔════╝╚══██╔══╝██║██║   ██║██╔════╝██║  ██║██╔═══██╗██║   ██║██╔════╝██╔════╝██╔════╝██╔════╝██║     ██╔══██╗╚══██╔══╝██╔════╝
  ██║  ███╗█████╗     ██║      ██║   ██║   ██║   ██║   ███████║██║     ██║██╔██╗ ██║███████║██║        ██║   ██║██║   ██║█████╗  ███████║██║   ██║██║   ██║███████╗█████╗  ███████╗█████╗  ██║     ███████║   ██║   ███████╗
  ██║   ██║██╔══╝     ██║      ██║   ██║   ██║   ██║   ██╔══██║██║     ██║██║╚██╗██║██╔══██║██║        ██║   ██║╚██╗ ██╔╝██╔══╝  ██╔══██║██║   ██║██║   ██║╚════██║██╔══╝  ╚════██║██╔══╝  ██║     ██╔══██║   ██║   ╚════██║
  ╚██████╔╝███████╗   ██║      ██║   ╚██████╔╝   ██║   ██║  ██║███████╗██║██║ ╚████║██║  ██║╚██████╗   ██║   ██║ ╚████╔╝ ███████╗██║  ██║╚██████╔╝╚██████╔╝███████║███████╗███████║██║     ███████╗██║  ██║   ██║   ███████║
   ╚═════╝ ╚══════╝   ╚═╝      ╚═╝    ╚═════╝    ╚═╝   ╚═╝  ╚═╝╚══════╝╚═╝╚═╝  ╚═══╝╚═╝  ╚═╝ ╚═════╝   ╚═╝   ╚═╝  ╚═══╝  ╚══════╝╚═╝  ╚═╝ ╚═════╝  ╚═════╝ ╚══════╝╚══════╝╚══════╝╚═╝     ╚══════╝╚═╝  ╚═╝   ╚═╝   ╚══════╝

  */
  getTotalInactiveHousesFlats() {
    const total = this.streetBuildingService.streetsBuildings
      .reduce((sum, building) => sum + building.housesFlats.filter(houseFlat => !houseFlat.status).length, 0);
    return total;
  }

  /*
  ██████╗ ███████╗██╗     ███████╗████████╗███████╗
  ██╔══██╗██╔════╝██║     ██╔════╝╚══██╔══╝██╔════╝
  ██║  ██║█████╗  ██║     █████╗     ██║   █████╗
  ██║  ██║██╔══╝  ██║     ██╔══╝     ██║   ██╔══╝
  ██████╔╝███████╗███████╗███████╗   ██║   ███████╗
  ╚═════╝ ╚══════╝╚══════╝╚══════╝   ╚═╝   ╚══════╝

  */
  async delete(
    streetBuilding: StreetBuilding
  ) {

    const flag = confirm(
      `¿Esta seguro que desea borrar la calle ${streetBuilding.name}?`
    );

    if(flag) {
      await this.streetBuildingService.delete(
        streetBuilding
      );
    }

  }

  /*
   ██████╗ ██████╗ ███████╗███╗   ██╗
  ██╔═══██╗██╔══██╗██╔════╝████╗  ██║
  ██║   ██║██████╔╝█████╗  ██╔██╗ ██║
  ██║   ██║██╔═══╝ ██╔══╝  ██║╚██╗██║
  ╚██████╔╝██║     ███████╗██║ ╚████║
   ╚═════╝ ╚═╝     ╚══════╝╚═╝  ╚═══╝

  */
  open(
    streetBuilding?: StreetBuilding,
  ) {
    const modalRef = this.modalService.open(
      StreetBuildingModalComponent
    );

    modalRef.componentInstance.streetBuilding = streetBuilding;
    modalRef.componentInstance.neighborhoodId = this.neighborhoodId;

  }

}
