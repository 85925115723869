import { Component } from '@angular/core';
import { NeighborhoodService } from '../../../services/neighborhood.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-neighborhoods-page',
  templateUrl: './neighborhoods-page.component.html',
  styleUrl: './neighborhoods-page.component.scss'
})
export class NeighborhoodsPageComponent {

  constructor(
    public neighborhoodService: NeighborhoodService,
    private modalService: NgbModal,
  ) {

  }

}
