import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppGuardService } from '../../../services/app-guard.service';
import { AppUser } from '../../../models/app-user.model';

@Component({
  selector: 'app-guards-page',
  templateUrl: './guards-page.component.html',
  styleUrl: './guards-page.component.scss'
})
export class GuardsPageComponent {

  public neighborhoodId?: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    public guardService: AppGuardService
  ) {
    
    this.activatedRoute.params.subscribe(
      async params => {
        this.neighborhoodId = params['neighborhoodId'];
        await this.guardService.getAll(
          this.neighborhoodId!
        );
      }
    );

  }

  /*
   ██████╗██████╗ ███████╗ █████╗ ████████╗███████╗
  ██╔════╝██╔══██╗██╔════╝██╔══██╗╚══██╔══╝██╔════╝
  ██║     ██████╔╝█████╗  ███████║   ██║   █████╗
  ██║     ██╔══██╗██╔══╝  ██╔══██║   ██║   ██╔══╝
  ╚██████╗██║  ██║███████╗██║  ██║   ██║   ███████╗
   ╚═════╝╚═╝  ╚═╝╚══════╝╚═╝  ╚═╝   ╚═╝   ╚══════╝

  */
  async create() {
    const flag = confirm('¿Estás seguro de crear un nuevo vigilante?');

    if (flag) {
      const appUser = await this.guardService.create(
        this.neighborhoodId!
      );

      if (appUser) {
        this.downloadUser(appUser);
      }
    }
  }

  /*
  ██████╗ ███████╗██╗     ███████╗████████╗███████╗
  ██╔══██╗██╔════╝██║     ██╔════╝╚══██╔══╝██╔════╝
  ██║  ██║█████╗  ██║     █████╗     ██║   █████╗
  ██║  ██║██╔══╝  ██║     ██╔══╝     ██║   ██╔══╝
  ██████╔╝███████╗███████╗███████╗   ██║   ███████╗
  ╚═════╝ ╚══════╝╚══════╝╚══════╝   ╚═╝   ╚══════╝

  */
  async delete(
    guard: AppUser
  ) {

    const flag = confirm('¿Estás seguro de eliminar este vigilante?');

    if (flag) {
      await this.guardService.delete(
        this.neighborhoodId!,
        guard.uid
      );
    }

  }

  /*
  ██████╗  ██████╗ ██╗    ██╗███╗   ██╗██╗      ██████╗  █████╗ ██████╗ ██╗   ██╗███████╗███████╗██████╗
  ██╔══██╗██╔═══██╗██║    ██║████╗  ██║██║     ██╔═══██╗██╔══██╗██╔══██╗██║   ██║██╔════╝██╔════╝██╔══██╗
  ██║  ██║██║   ██║██║ █╗ ██║██╔██╗ ██║██║     ██║   ██║███████║██║  ██║██║   ██║███████╗█████╗  ██████╔╝
  ██║  ██║██║   ██║██║███╗██║██║╚██╗██║██║     ██║   ██║██╔══██║██║  ██║██║   ██║╚════██║██╔══╝  ██╔══██╗
  ██████╔╝╚██████╔╝╚███╔███╔╝██║ ╚████║███████╗╚██████╔╝██║  ██║██████╔╝╚██████╔╝███████║███████╗██║  ██║
  ╚═════╝  ╚═════╝  ╚══╝╚══╝ ╚═╝  ╚═══╝╚══════╝ ╚═════╝ ╚═╝  ╚═╝╚═════╝  ╚═════╝ ╚══════╝╚══════╝╚═╝  ╚═╝

  */
  downloadUser(
    appUser: AppUser
  ) {

    const headers = ['Usuario', 'Contraseña'];

    const rows = [
      [
        appUser.username, 
        appUser.password
      ]
    ];

    // Generar el contenido del archivo CSV
    const content = [
      headers.join(','), // Encabezados
      ...rows.map(row => row.join(',')) // Filas de datos
    ].join('\n');

    // Agregar el BOM (Byte Order Mark) para codificación UTF-8
    const bom = '\uFEFF';

    // Crear un Blob con el contenido UTF-8
    const blob = new Blob([bom + content], { type: 'text/csv;charset=utf-8;' });

    // Crear un enlace para la descarga
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);

    // Asignar el nombre del archivo
    link.download = `guard-${appUser.username}.csv`;

    // Agregar el enlace al documento y disparar la descarga
    document.body.appendChild(link);
    link.click();

    // Limpiar el enlace del DOM
    document.body.removeChild(link);
  }

  /*
  ██╗     ██╗███╗   ██╗██╗  ██╗██╗   ██╗███████╗███████╗██████╗
  ██║     ██║████╗  ██║██║ ██╔╝██║   ██║██╔════╝██╔════╝██╔══██╗
  ██║     ██║██╔██╗ ██║█████╔╝ ██║   ██║███████╗█████╗  ██████╔╝
  ██║     ██║██║╚██╗██║██╔═██╗ ██║   ██║╚════██║██╔══╝  ██╔══██╗
  ███████╗██║██║ ╚████║██║  ██╗╚██████╔╝███████║███████╗██║  ██║
  ╚══════╝╚═╝╚═╝  ╚═══╝╚═╝  ╚═╝ ╚═════╝ ╚══════╝╚══════╝╚═╝  ╚═╝

  */
  async linkUser() {

    const value = prompt('Por favor, ingrese el correo electrónico de usuario');

    if (value) {
      const appUser = await this.guardService.create(
        this.neighborhoodId!,
        value
      );
    }

  }

}
