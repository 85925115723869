<div class="container-fluid py-3">
  <div class="row">
    <div class="col-12">
      <p-table #dt
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        [filterDelay]="0"
        [loading]="accessDoorGroupTypeService.loading"
        [paginator]="true"
        [rows]="10"
        [rowHover]="true"
        [rowsPerPageOptions]="[10, 25, 50]"
        [showCurrentPageReport]="true" 
        [value]="accessDoorGroupTypeService.accessDoorGroupTypes"
        pDroppable="row">
        
        <ng-template pTemplate="caption">
          <div class="container-fluid">
            <div class="row">
              <div class="col-4 d-flex align-items-center">
                Tipos de grupos de puertas de acceso
              </div>
              <div class="col-8 text-end">
                <button (click)="open()" class="btn btn-success rounded-circle me-2">
                  <i class="material-icons mt-1">add</i>
                </button>
                <button (click)="accessDoorGroupTypeService.getAll()" class="btn btn-primary rounded-circle me-2">
                  <i class="material-icons mt-1">refresh</i>
                </button>
              </div>
            </div>
          </div>
        </ng-template>

        <ng-template pTemplate="header">
          <tr>
            <th></th>
            <th pSortableColumn="accessDoorGroupTypeId">
              ID
              <p-sortIcon field="accessDoorGroupTypeId"></p-sortIcon>
              <p-columnFilter type="text" field="accessDoorGroupTypeId" display="menu"></p-columnFilter>
            </th>

            <th pSortableColumn="accessDoorGroup">
              Grupo
              <p-sortIcon field="accessDoorGroup"></p-sortIcon>
              <p-columnFilter type="text" field="accessDoorGroup" display="menu"></p-columnFilter>
            </th>

            <th>Acciones</th>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-accessDoorGroupType>
          <tr class="p-selectable-row">

            <td>
              @if(accessDoorGroupType.image) {
                <div 
                  class="bg-center"
                  style="width: 5vh; height: 5vh;"
                  [ngStyle]="{
                    'background-image': 'url(' + accessDoorGroupType.image.urlFile + ')'
                  }">
                </div>
              }
            </td>

            <td>
              <span class="p-column-title">ID</span>
              {{accessDoorGroupType.accessDoorGroupTypeId}}
            </td>

            <td>
              <span class="p-column-title">Grupo</span>
              {{accessDoorGroupType.accessDoorGroup}}
            </td>

            <td>
              <button (click)="open(accessDoorGroupType)" class="btn btn-sm btn-light rounded-circle me-2">
                <i class="material-icons mt-1">more_vert</i>
              </button>
              <button (click)="delete(accessDoorGroupType)" class="btn btn-sm btn-danger rounded-circle">
                <i class="material-icons mt-1">delete</i>
              </button>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>
