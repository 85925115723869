import { NgModule } from '@angular/core';
import { HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { environment } from '../environments/environment';
import { ToastsComponent } from './components/toasts/toasts.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { SessionLayoutComponent } from './layouts/session-layout/session-layout.component';
import { LogInPageComponent } from './pages/log-in-page/log-in-page.component';
import { DashboardLayoutComponent } from './layouts/dashboard-layout/dashboard-layout.component';
import { SideBarComponent } from './components/side-bar/side-bar.component';
import { MenuItemComponent } from './components/menu-item/menu-item.component';
import { MainHeaderComponent } from './components/main-header/main-header.component';
import { DashboardPageComponent } from './pages/dashboard/dashboard-page/dashboard-page.component';
import { NeighborhoodsPageComponent } from './pages/dashboard/neighborhoods-page/neighborhoods-page.component';
import { SideMenuComponent } from './components/side-menu/side-menu.component';
import { NeighborhoodTypesPageComponent } from './pages/dashboard/neighborhood-types-page/neighborhood-types-page.component';
import { ProofTypesPageComponent } from './pages/dashboard/proof-types-page/proof-types-page.component';
import { VisitTypesPageComponent } from './pages/dashboard/visit-types-page/visit-types-page.component';
import { ModulesPageComponent } from './pages/dashboard/security/modules-page/modules-page.component';
import { ProfilesPageComponent } from './pages/dashboard/security/profiles-page/profiles-page.component';
import { UsersPageComponent } from './pages/dashboard/security/users-page/users-page.component';
import { ModuleModalComponent } from './components/module-modal/module-modal.component';

// ANCHOR NGPRIME
import { TableModule } from 'primeng/table';
import { InputSwitchModule } from 'primeng/inputswitch';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { NeighborhoodTypeModalComponent } from './components/neighborhood-type-modal/neighborhood-type-modal.component';
import { ProofTypeModalComponent } from './components/proof-type-modal/proof-type-modal.component';
import { VisitTypeModalComponent } from './components/visit-type-modal/visit-type-modal.component';
import { NeighborhoodModalComponent } from './components/neighborhood-modal/neighborhood-modal.component';

@NgModule({
  declarations: [
    AppComponent,
    ToastsComponent,
    SpinnerComponent,
    SessionLayoutComponent,
    LogInPageComponent,
    DashboardLayoutComponent,
    SideBarComponent,
    MenuItemComponent,
    MainHeaderComponent,
    DashboardPageComponent,
    NeighborhoodsPageComponent,
    SideMenuComponent,
    NeighborhoodTypesPageComponent,
    ProofTypesPageComponent,
    VisitTypesPageComponent,
    ModulesPageComponent,
    ProfilesPageComponent,
    UsersPageComponent,
    ModuleModalComponent,
    NeighborhoodTypeModalComponent,
    ProofTypeModalComponent,
    VisitTypeModalComponent,
    NeighborhoodModalComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    HttpClientModule,
    HttpClientJsonpModule,
    TableModule,
    InputSwitchModule,
    ButtonModule,
    DropdownModule,
  ],
  providers: [
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => getAuth())
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
