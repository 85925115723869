import { Injectable } from '@angular/core';
import { AccessDoor, IAccessDoor } from '../models/access-door.model';
import { AuthService } from '../services/auth.service';
import { HttpClient } from '@angular/common/http';
import { SpinnerService } from '../services/spinner.service';
import { ToastService } from '../services/toast.service';
import { Gate } from '../models/gate.model';
import { AccessDoorGroup } from '../models/access-door-group.model';
import { environment } from '../../environments/environment';
import { IServerResponse, ServerResponse } from '../models/server-response.model';

@Injectable({
  providedIn: 'root'
})
export class AccessDoorService {

  public loading: boolean = false;
  public accessDoors: AccessDoor[] = [];

  constructor(
    public authService: AuthService,
    private http: HttpClient,
    public spinnerService: SpinnerService,
    public toastService: ToastService
  ) { }

  /*
   ██████╗██████╗ ███████╗ █████╗ ████████╗███████╗
  ██╔════╝██╔══██╗██╔════╝██╔══██╗╚══██╔══╝██╔════╝
  ██║     ██████╔╝█████╗  ███████║   ██║   █████╗
  ██║     ██╔══██╗██╔══╝  ██╔══██║   ██║   ██╔══╝
  ╚██████╗██║  ██║███████╗██║  ██║   ██║   ███████╗
   ╚═════╝╚═╝  ╚═╝╚══════╝╚═╝  ╚═╝   ╚═╝   ╚══════╝

  */
  async create(
    gate: Gate,
    accessDoorGroup: AccessDoorGroup,
    accessDoor: IAccessDoor
  ): Promise<boolean> {

    let flag = false;
    const urlApi = `${environment.urlApi}/neighborhoods/${gate.neighborhoodId}/gates/${gate.gateId}/access-door-groups/${accessDoorGroup.accessDoorGroupId}/access-doors`;

    this.spinnerService.loading = true;
    this.spinnerService.message = 'Registrando puerta de acceso';

    try {
      const token = await this.authService.getToken();

      const response = await new Promise<ServerResponse>(
        (resolve, reject) => {
          this.http.post<IServerResponse>(
            urlApi,
            JSON.stringify(accessDoor),
            { 
              headers: { 
                "content-type": "application/json", 
                "Authorization": token.toString() 
              } 
            }
          ).subscribe({
            next: (response: IServerResponse) => resolve(new ServerResponse(response)),
            error: (error) => {
              if (error.error) {
                resolve(new ServerResponse(error.error));
              } else {
                reject(error);
                this.toastService.show({
                  header: 'Error de servidor',
                  body: `${error}`,
                  classname: 'bg-danger text-white'
                });
              }
            }
          });
        }
      );

      if (response.statusCode === 201) {
        flag = true;
        this.toastService.show({
          body: `${response.message}`,
          classname: 'bg-success text-white'
        });
      } else {
        this.toastService.show({
          header: 'Error de servidor',
          body: `${response.message}`,
          classname: 'bg-danger text-white'
        });
      }

    } catch (error) {
      console.log(error);
      this.toastService.show({
        header: 'Error',
        body: `${error}`,
        classname: 'bg-danger text-white'
      });
    }

    this.spinnerService.loading = false;
    this.spinnerService.message = '';
    return flag;
  }

  /*
  ██████╗ ███████╗██╗     ███████╗████████╗███████╗
  ██╔══██╗██╔════╝██║     ██╔════╝╚══██╔══╝██╔════╝
  ██║  ██║█████╗  ██║     █████╗     ██║   █████╗
  ██║  ██║██╔══╝  ██║     ██╔══╝     ██║   ██╔══╝
  ██████╔╝███████╗███████╗███████╗   ██║   ███████╗
  ╚═════╝ ╚══════╝╚══════╝╚══════╝   ╚═╝   ╚══════╝

  */
  async delete(
    gate: Gate,
    accessDoorGroup: AccessDoorGroup,
    accessDoor: AccessDoor
  ): Promise<boolean> {

    let flag = false;
    const urlApi = `${environment.urlApi}/neighborhoods/${gate.neighborhoodId}/gates/${gate.gateId}/access-door-groups/${accessDoorGroup.accessDoorGroupId}/access-doors/${accessDoor.accessDoorId}`;

    this.spinnerService.loading = true;
    this.spinnerService.message = 'Eliminando puerta de acceso';

    try {
      const token = await this.authService.getToken();

      const response = await new Promise<ServerResponse>(
        (resolve, reject) => {
          this.http.delete<IServerResponse>(
            urlApi,
            { 
              headers: { 
                "Authorization": token.toString() 
              } 
            }
          ).subscribe({
            next: (response: IServerResponse) => resolve(new ServerResponse(response)),
            error: (error) => {
              if (error.error) {
                resolve(new ServerResponse(error.error));
              } else {
                reject(error);
                this.toastService.show({
                  header: 'Error de servidor',
                  body: `${error}`,
                  classname: 'bg-danger text-white'
                });
              }
            }
          });
        }
      );

      if (response.statusCode === 200) {
        flag = true;
        this.toastService.show({
          body: `${response.message}`,
          classname: 'bg-success text-white'
        });
      } else {
        this.toastService.show({
          header: 'Error de servidor',
          body: `${response.message}`,
          classname: 'bg-danger text-white'
        });
      }

    } catch (error) {
      console.log(error);
      this.toastService.show({
        header: 'Error',
        body: `${error}`,
        classname: 'bg-danger text-white'
      });
    }

    this.spinnerService.loading = false;
    this.spinnerService.message = '';
    return flag;
  }

  /*
  ██╗   ██╗██████╗ ██████╗  █████╗ ████████╗███████╗
  ██║   ██║██╔══██╗██╔══██╗██╔══██╗╚══██╔══╝██╔════╝
  ██║   ██║██████╔╝██║  ██║███████║   ██║   █████╗
  ██║   ██║██╔═══╝ ██║  ██║██╔══██║   ██║   ██╔══╝
  ╚██████╔╝██║     ██████╔╝██║  ██║   ██║   ███████╗
   ╚═════╝ ╚═╝     ╚═════╝ ╚═╝  ╚═╝   ╚═╝   ╚══════╝

  */
  async update(
    gate: Gate,
    accessDoorGroup: AccessDoorGroup,
    accessDoor: IAccessDoor
  ): Promise<boolean> {
  
    let flag = false;
    const urlApi = `${environment.urlApi}/neighborhoods/${gate.neighborhoodId}/gates/${gate.gateId}/access-door-groups/${accessDoorGroup.accessDoorGroupId}/access-doors/${accessDoor.accessDoorId}`;
  
    this.spinnerService.loading = true;
    this.spinnerService.message = 'Actualizando puerta de acceso';
  
    try {
      const token = await this.authService.getToken();
  
      const response = await new Promise<ServerResponse>(
        (resolve, reject) => {
          this.http.put<IServerResponse>(
            urlApi,
            JSON.stringify(accessDoor),
            {
              headers: {
                "content-type": "application/json",
                "Authorization": token.toString()
              }
            }
          ).subscribe({
            next: (
              response: IServerResponse
            ) => resolve(new ServerResponse(response)),
            error: (error) => {
              if (error.error) {
                resolve(new ServerResponse(error.error));
              } else {
                this.toastService.show({
                  header: 'Error de servidor',
                  body: `${error}`,
                  classname: 'bg-danger text-white'
                });
                reject(error);
              }
            }
          });
        }
      );
  
      if (response.statusCode === 200) {
        flag = true;
        this.toastService.show({
          body: `${response.message}`,
          classname: 'bg-success text-white'
        });
      } else {
        this.toastService.show({
          header: 'Error de servidor',
          body: `${response.message}`,
          classname: 'bg-danger text-white'
        });
      }
    } catch (error) {
      console.log(error);
  
      this.toastService.show({
        header: 'Error',
        body: `${error}`,
        classname: 'bg-danger text-white'
      });
    }
  
    this.spinnerService.loading = false;
    this.spinnerService.message = '';
    return flag;
  }
  
  
}
