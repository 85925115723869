// Module.model.ts

export interface IModule {
  moduleId: string;
  superModuleId?: string;
  slug: string;
  description: string;
  uidCreatedBy?: string; // Usando ? para indicar que es opcional
  uidUpdatedBy?: string; // Usando ? para indicar que es opcional
  createdAt: string;
  updatedAt: string;
  children?: IModule[];
}

export class Module {
  moduleId: string;
  superModuleId?: string;
  slug: string;
  description: string;
  uidCreatedBy?: string; // Usando ? para indicar que es opcional
  uidUpdatedBy?: string; // Usando ? para indicar que es opcional
  createdAt: Date;
  updatedAt: Date;
  children: Module[] = [];

  constructor(data: IModule) {
    this.moduleId = data.moduleId;
    this.superModuleId = data.superModuleId;
    this.slug = data.slug;
    this.description = data.description;
    this.uidCreatedBy = data.uidCreatedBy;
    this.uidUpdatedBy = data.uidUpdatedBy;
    this.createdAt = new Date(data.createdAt);
    this.updatedAt = new Date(data.updatedAt);
    this.children = data.children?.map(
      (child) => new Module(child)
    ) || [];
  }
}
