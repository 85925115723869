import { FileMedia, IFileMedia } from "./file-media.model";

export interface IAnnouncement {
  announcementId: string;
  announcementType: string;
  neighborhoodId: string;
  title: string;
  content: string;
  uidCreatedBy?: string; // Opcional
  createdAt: string;
  files?: IFileMedia[];
}

export class Announcement {
  announcementId: string;
  announcementType: string;
  neighborhoodId: string;
  title: string;
  content: string;
  uidCreatedBy?: string; // Opcional
  createdAt: Date;
  files?: FileMedia[];

  constructor(data: IAnnouncement) {
    this.announcementId = data.announcementId;
    this.announcementType = data.announcementType;
    this.neighborhoodId = data.neighborhoodId;
    this.title = data.title;
    this.content = data.content;
    this.uidCreatedBy = data.uidCreatedBy;
    this.createdAt = new Date(data.createdAt);
    this.files = data.files
      ? data.files.map(file => new FileMedia(file))
      : [];
  }
}
