// Invitation.model.ts

export interface IInvitation {
  invitationId: string;
  houseFlatId: string;
  email: string;
  createdAt: string;
}

export class Invitation {
  invitationId: string;
  houseFlatId: string;
  email: string;
  createdAt: Date;

  constructor(data: IInvitation) {
    this.invitationId = data.invitationId;
    this.houseFlatId = data.houseFlatId;
    this.email = data.email;
    this.createdAt = new Date(data.createdAt);
  }
}
