import { Component } from '@angular/core';
import { StreetBuildingService } from '../../../services/street-building.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StreetBuilding } from '../../../models/street-building.model';
import { StreetBuildingModalComponent } from '../../../components/street-building-modal/street-building-modal.component';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../../services/auth.service';
import * as XLSX from 'xlsx';
import { HouseFlatService } from '../../../services/house-flat.service';
import { IHouseFlat } from '../../../models/house-flat.model';
import { SpinnerService } from '../../../services/spinner.service';
import { ResidentService } from '../../../services/resident.service';

@Component({
  selector: 'app-streets-building-page',
  templateUrl: './streets-building-page.component.html',
  styleUrl: './streets-building-page.component.scss'
})
export class StreetsBuildingPageComponent {

  public neighborhoodId?: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    public authService: AuthService,
    public houseFlatService: HouseFlatService,
    private modalService: NgbModal,
    private residentService: ResidentService,
    private spinnerService: SpinnerService,
    public streetBuildingService: StreetBuildingService,
  ) {

    this.activatedRoute.params.subscribe(
      async params => {

        this.neighborhoodId = params['neighborhoodId'];

        await this.streetBuildingService.getAll(
          this.neighborhoodId!
        );
      }
    );

  }

  /*
  ██████╗  ██████╗ ██╗    ██╗███╗   ██╗██╗      ██████╗  █████╗ ██████╗ ██████╗ ███████╗██████╗  ██████╗ ██████╗ ████████╗
  ██╔══██╗██╔═══██╗██║    ██║████╗  ██║██║     ██╔═══██╗██╔══██╗██╔══██╗██╔══██╗██╔════╝██╔══██╗██╔═══██╗██╔══██╗╚══██╔══╝
  ██║  ██║██║   ██║██║ █╗ ██║██╔██╗ ██║██║     ██║   ██║███████║██║  ██║██████╔╝█████╗  ██████╔╝██║   ██║██████╔╝   ██║
  ██║  ██║██║   ██║██║███╗██║██║╚██╗██║██║     ██║   ██║██╔══██║██║  ██║██╔══██╗██╔══╝  ██╔═══╝ ██║   ██║██╔══██╗   ██║
  ██████╔╝╚██████╔╝╚███╔███╔╝██║ ╚████║███████╗╚██████╔╝██║  ██║██████╔╝██║  ██║███████╗██║     ╚██████╔╝██║  ██║   ██║
  ╚═════╝  ╚═════╝  ╚══╝╚══╝ ╚═╝  ╚═══╝╚══════╝ ╚═════╝ ╚═╝  ╚═╝╚═════╝ ╚═╝  ╚═╝╚══════╝╚═╝      ╚═════╝ ╚═╝  ╚═╝   ╚═╝

  */
  downloadReport() {

    const data: any[] = [];
    const mergeRanges: any[] = [];

    let currentIndex = 0;

    this.streetBuildingService.streetsBuildings.forEach(
      (streetBuilding, index) => {
        data.push([
          streetBuilding.name
        ]);

        mergeRanges.push({
          s: { r: currentIndex, c: 0 }, // Celda de inicio (row, column)
          e: { r: currentIndex, c: 6 }  // Celda de fin (row, column) - 6 columnas combinadas
        });

        currentIndex++;
        data.push([
          'NÚMERO',
          'NOMBRE',
          'TELÉFONO',
          'TIPO',
          'ESTATUS',
          'NOTAS',
          'EXTRA'
        ]);

        streetBuilding.housesFlats.forEach(
          (houseFlat, jindex) => {
            currentIndex++;
            data.push([
              houseFlat.number,
              houseFlat.fullName,
              houseFlat.phone,
              houseFlat.residentType?.description,
              houseFlat.status ? 'Activo' : 'Moroso',
              houseFlat.notes,
              ''
            ]);
          }
        );

        currentIndex++;
      }
    );

    console.log(data);

    // Crear una hoja de Excel
    const worksheet = XLSX.utils.aoa_to_sheet(data);

    worksheet['!merges'] = mergeRanges;

    // Calcular el ancho máximo de cada columna
    const columnWidths = data[1].map((_: any, colIndex: string | number) => {
      const maxLength = data.reduce((max, row) => {
        const cell = row[colIndex];
        const cellLength = cell ? cell.toString().length : 0;
        return Math.max(max, cellLength);
      }, 0);
      return { wch: maxLength + 2 }; // Ajuste adicional para margen
    });

    // Asignar los anchos de columna al worksheet
    worksheet['!cols'] = columnWidths;

    // Crear un libro de trabajo y agregar la hoja
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Residentes');

    // Exportar el archivo XLSX
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

    // Crear un Blob para la descarga
    const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });

    // Crear un enlace de descarga
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);

    // Asignar el nombre del archivo
    link.download = `reporte-fraccionamiento.xlsx`;

    // Disparar la descarga
    link.click();
  }

  /*
    ██████╗ ███████╗███╗   ██╗███████╗██████╗  █████╗ ████████╗███████╗██╗   ██╗███████╗███████╗██████╗ ███████╗
  ██╔════╝ ██╔════╝████╗  ██║██╔════╝██╔══██╗██╔══██╗╚══██╔══╝██╔════╝██║   ██║██╔════╝██╔════╝██╔══██╗██╔════╝
  ██║  ███╗█████╗  ██╔██╗ ██║█████╗  ██████╔╝███████║   ██║   █████╗  ██║   ██║███████╗█████╗  ██████╔╝███████╗
  ██║   ██║██╔══╝  ██║╚██╗██║██╔══╝  ██╔══██╗██╔══██║   ██║   ██╔══╝  ██║   ██║╚════██║██╔══╝  ██╔══██╗╚════██║
  ╚██████╔╝███████╗██║ ╚████║███████╗██║  ██║██║  ██║   ██║   ███████╗╚██████╔╝███████║███████╗██║  ██║███████║
    ╚═════╝ ╚══════╝╚═╝  ╚═══╝╚══════╝╚═╝  ╚═╝╚═╝  ╚═╝   ╚═╝   ╚══════╝ ╚═════╝ ╚══════╝╚══════╝╚═╝  ╚═╝╚══════╝

  */
  async generateUsers() {

    const newPosibleUsers: {
      neighborhoodId: string;
      streetBuildingId: string;
      streetBuildingName: string;
      houseFlatId: string;
      houseFlatNumber: string;
    }[] = [];

    let numberOfHouses = 0;

    this.streetBuildingService.streetsBuildings.forEach(
      streetBuilding => {
        streetBuilding.housesFlats.forEach(
          houseFlat => {

            if(houseFlat.residents.length > 0) {

              const flag = houseFlat.residents.find(
                resident => resident.resident!.username != ""
              );

              if(!flag) {
                newPosibleUsers.push({
                  neighborhoodId: streetBuilding.neighborhoodId,
                  streetBuildingId: streetBuilding.streetBuildingId,
                  streetBuildingName: streetBuilding.name,
                  houseFlatId: houseFlat.houseFlatId,
                  houseFlatNumber: houseFlat.number,
                });
              }

            } else {

              newPosibleUsers.push({
                neighborhoodId: streetBuilding.neighborhoodId,
                streetBuildingId: streetBuilding.streetBuildingId,
                streetBuildingName: streetBuilding.name,
                houseFlatId: houseFlat.houseFlatId,
                houseFlatNumber: houseFlat.number,
              });

            }

            numberOfHouses++;
          }
        );
      }
    );

    console.log(newPosibleUsers);

    const flagConfirmGenerate = confirm(`Se generarán ${newPosibleUsers.length} usuarios de un total de ${numberOfHouses} casas. ¿Desea continuar?`);

    if(flagConfirmGenerate) {

      this.spinnerService.loading = true;
      this.spinnerService.message = 'Generando usuarios...';

      const users: {
        username: string;
        password: string;
        streetBuildingName: string;
        houseFlatNumber: string;
      }[] = [];

      for(let i = 0; i < newPosibleUsers.length; i++) {

        const appUser = await this.residentService.create(
          newPosibleUsers[i].neighborhoodId,
          newPosibleUsers[i].streetBuildingId,
          newPosibleUsers[i].houseFlatId,
          true,
          `${i + 1}/${newPosibleUsers.length}`
        );

        if(appUser) {
          users.push({
            username: appUser.username,
            password: appUser.password!,
            streetBuildingName: newPosibleUsers[i].streetBuildingName,
            houseFlatNumber: newPosibleUsers[i].houseFlatNumber
          });
        }

      }

      const headers = [
        'Usuario', 
        'Contraseña',
        'Calle',
        'Número'
      ];

      const rows: any[] = [];

      users.forEach(
        user => {
          rows.push([
            user.username,
            user.password,
            user.streetBuildingName,
            user.houseFlatNumber
          ]);
        }
      );

      const data = [headers, ...rows];

      // Crear una hoja de Excel
      const worksheet = XLSX.utils.aoa_to_sheet(data);

      // Ajustar el ancho de las columnas automáticamente
      const columnWidths = headers.map(header => ({ wch: header.length + 5 })); // Ajusta +5 para un espacio adicional
      worksheet['!cols'] = columnWidths;

      // Crear un libro de trabajo y agregar la hoja
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Usuarios');

      // Exportar el archivo XLSX
      const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

      // Crear un Blob para la descarga
      const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });

      // Crear un enlace de descarga
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);

      // Asignar el nombre del archivo
      link.download = `users-${this.neighborhoodId}.xlsx`;

      this.spinnerService.loading = false;
      this.spinnerService.message = '';

      // Disparar la descarga
      link.click();

    }

  }

  /*
   ██████╗ ███████╗████████╗████████╗ ██████╗ ████████╗ █████╗ ██╗     ██╗  ██╗ ██████╗ ██╗   ██╗███████╗███████╗███████╗███████╗██╗      █████╗ ████████╗███████╗
  ██╔════╝ ██╔════╝╚══██╔══╝╚══██╔══╝██╔═══██╗╚══██╔══╝██╔══██╗██║     ██║  ██║██╔═══██╗██║   ██║██╔════╝██╔════╝██╔════╝██╔════╝██║     ██╔══██╗╚══██╔══╝██╔════╝
  ██║  ███╗█████╗     ██║      ██║   ██║   ██║   ██║   ███████║██║     ███████║██║   ██║██║   ██║███████╗█████╗  ███████╗█████╗  ██║     ███████║   ██║   ███████╗
  ██║   ██║██╔══╝     ██║      ██║   ██║   ██║   ██║   ██╔══██║██║     ██╔══██║██║   ██║██║   ██║╚════██║██╔══╝  ╚════██║██╔══╝  ██║     ██╔══██║   ██║   ╚════██║
  ╚██████╔╝███████╗   ██║      ██║   ╚██████╔╝   ██║   ██║  ██║███████╗██║  ██║╚██████╔╝╚██████╔╝███████║███████╗███████║██║     ███████╗██║  ██║   ██║   ███████║
   ╚═════╝ ╚══════╝   ╚═╝      ╚═╝    ╚═════╝    ╚═╝   ╚═╝  ╚═╝╚══════╝╚═╝  ╚═╝ ╚═════╝  ╚═════╝ ╚══════╝╚══════╝╚══════╝╚═╝     ╚══════╝╚═╝  ╚═╝   ╚═╝   ╚══════╝

  */
  getTotalHousesFlats() {
    const total = this.streetBuildingService.streetsBuildings
      .reduce((sum, building) => sum + building.housesFlats.length, 0);
    return total;
  }

  /*
   ██████╗ ███████╗████████╗████████╗ ██████╗ ████████╗ █████╗ ██╗      █████╗  ██████╗████████╗██╗██╗   ██╗███████╗██╗  ██╗ ██████╗ ██╗   ██╗███████╗███████╗███████╗███████╗██╗      █████╗ ████████╗███████╗
  ██╔════╝ ██╔════╝╚══██╔══╝╚══██╔══╝██╔═══██╗╚══██╔══╝██╔══██╗██║     ██╔══██╗██╔════╝╚══██╔══╝██║██║   ██║██╔════╝██║  ██║██╔═══██╗██║   ██║██╔════╝██╔════╝██╔════╝██╔════╝██║     ██╔══██╗╚══██╔══╝██╔════╝
  ██║  ███╗█████╗     ██║      ██║   ██║   ██║   ██║   ███████║██║     ███████║██║        ██║   ██║██║   ██║█████╗  ███████║██║   ██║██║   ██║███████╗█████╗  ███████╗█████╗  ██║     ███████║   ██║   ███████╗
  ██║   ██║██╔══╝     ██║      ██║   ██║   ██║   ██║   ██╔══██║██║     ██╔══██║██║        ██║   ██║╚██╗ ██╔╝██╔══╝  ██╔══██║██║   ██║██║   ██║╚════██║██╔══╝  ╚════██║██╔══╝  ██║     ██╔══██║   ██║   ╚════██║
  ╚██████╔╝███████╗   ██║      ██║   ╚██████╔╝   ██║   ██║  ██║███████╗██║  ██║╚██████╗   ██║   ██║ ╚████╔╝ ███████╗██║  ██║╚██████╔╝╚██████╔╝███████║███████╗███████║██║     ███████╗██║  ██║   ██║   ███████║
   ╚═════╝ ╚══════╝   ╚═╝      ╚═╝    ╚═════╝    ╚═╝   ╚═╝  ╚═╝╚══════╝╚═╝  ╚═╝ ╚═════╝   ╚═╝   ╚═╝  ╚═══╝  ╚══════╝╚═╝  ╚═╝ ╚═════╝  ╚═════╝ ╚══════╝╚══════╝╚══════╝╚═╝     ╚══════╝╚═╝  ╚═╝   ╚═╝   ╚══════╝

  */
  getTotalActiveHousesFlats() {
    const total = this.streetBuildingService.streetsBuildings
      .reduce((sum, building) => sum + building.housesFlats.filter(houseFlat => houseFlat.status).length, 0);
    return total;
  }

  /*
   ██████╗ ███████╗████████╗████████╗ ██████╗ ████████╗ █████╗ ██╗     ██╗███╗   ██╗ █████╗  ██████╗████████╗██╗██╗   ██╗███████╗██╗  ██╗ ██████╗ ██╗   ██╗███████╗███████╗███████╗███████╗██╗      █████╗ ████████╗███████╗
  ██╔════╝ ██╔════╝╚══██╔══╝╚══██╔══╝██╔═══██╗╚══██╔══╝██╔══██╗██║     ██║████╗  ██║██╔══██╗██╔════╝╚══██╔══╝██║██║   ██║██╔════╝██║  ██║██╔═══██╗██║   ██║██╔════╝██╔════╝██╔════╝██╔════╝██║     ██╔══██╗╚══██╔══╝██╔════╝
  ██║  ███╗█████╗     ██║      ██║   ██║   ██║   ██║   ███████║██║     ██║██╔██╗ ██║███████║██║        ██║   ██║██║   ██║█████╗  ███████║██║   ██║██║   ██║███████╗█████╗  ███████╗█████╗  ██║     ███████║   ██║   ███████╗
  ██║   ██║██╔══╝     ██║      ██║   ██║   ██║   ██║   ██╔══██║██║     ██║██║╚██╗██║██╔══██║██║        ██║   ██║╚██╗ ██╔╝██╔══╝  ██╔══██║██║   ██║██║   ██║╚════██║██╔══╝  ╚════██║██╔══╝  ██║     ██╔══██║   ██║   ╚════██║
  ╚██████╔╝███████╗   ██║      ██║   ╚██████╔╝   ██║   ██║  ██║███████╗██║██║ ╚████║██║  ██║╚██████╗   ██║   ██║ ╚████╔╝ ███████╗██║  ██║╚██████╔╝╚██████╔╝███████║███████╗███████║██║     ███████╗██║  ██║   ██║   ███████║
   ╚═════╝ ╚══════╝   ╚═╝      ╚═╝    ╚═════╝    ╚═╝   ╚═╝  ╚═╝╚══════╝╚═╝╚═╝  ╚═══╝╚═╝  ╚═╝ ╚═════╝   ╚═╝   ╚═╝  ╚═══╝  ╚══════╝╚═╝  ╚═╝ ╚═════╝  ╚═════╝ ╚══════╝╚══════╝╚══════╝╚═╝     ╚══════╝╚═╝  ╚═╝   ╚═╝   ╚══════╝

  */
  getTotalInactiveHousesFlats() {
    const total = this.streetBuildingService.streetsBuildings
      .reduce((sum, building) => sum + building.housesFlats.filter(houseFlat => !houseFlat.status).length, 0);
    return total;
  }

  /*
   ██████╗ ███████╗████████╗████████╗ ██████╗ ████████╗ █████╗ ██╗     ██████╗ ███████╗██╗   ██╗██╗ ██████╗███████╗███████╗
  ██╔════╝ ██╔════╝╚══██╔══╝╚══██╔══╝██╔═══██╗╚══██╔══╝██╔══██╗██║     ██╔══██╗██╔════╝██║   ██║██║██╔════╝██╔════╝██╔════╝
  ██║  ███╗█████╗     ██║      ██║   ██║   ██║   ██║   ███████║██║     ██║  ██║█████╗  ██║   ██║██║██║     █████╗  ███████╗
  ██║   ██║██╔══╝     ██║      ██║   ██║   ██║   ██║   ██╔══██║██║     ██║  ██║██╔══╝  ╚██╗ ██╔╝██║██║     ██╔══╝  ╚════██║
  ╚██████╔╝███████╗   ██║      ██║   ╚██████╔╝   ██║   ██║  ██║███████╗██████╔╝███████╗ ╚████╔╝ ██║╚██████╗███████╗███████║
   ╚═════╝ ╚══════╝   ╚═╝      ╚═╝    ╚═════╝    ╚═╝   ╚═╝  ╚═╝╚══════╝╚═════╝ ╚══════╝  ╚═══╝  ╚═╝ ╚═════╝╚══════╝╚══════╝

  */
  getTotalDevices() {
    return this.streetBuildingService.streetsBuildings.reduce(
      (sum, building) => sum + building.totalDevices, 0
    );
  }

  /*
  ██████╗ ███████╗██╗     ███████╗████████╗███████╗
  ██╔══██╗██╔════╝██║     ██╔════╝╚══██╔══╝██╔════╝
  ██║  ██║█████╗  ██║     █████╗     ██║   █████╗
  ██║  ██║██╔══╝  ██║     ██╔══╝     ██║   ██╔══╝
  ██████╔╝███████╗███████╗███████╗   ██║   ███████╗
  ╚═════╝ ╚══════╝╚══════╝╚══════╝   ╚═╝   ╚══════╝

  */
  async delete(
    streetBuilding: StreetBuilding
  ) {

    const flag = confirm(
      `¿Esta seguro que desea borrar la calle ${streetBuilding.name}?`
    );

    if(flag) {
      await this.streetBuildingService.delete(
        streetBuilding
      );
    }

  }

  /*
   ██████╗ ██████╗ ███████╗███╗   ██╗
  ██╔═══██╗██╔══██╗██╔════╝████╗  ██║
  ██║   ██║██████╔╝█████╗  ██╔██╗ ██║
  ██║   ██║██╔═══╝ ██╔══╝  ██║╚██╗██║
  ╚██████╔╝██║     ███████╗██║ ╚████║
   ╚═════╝ ╚═╝     ╚══════╝╚═╝  ╚═══╝

  */
  open(
    streetBuilding?: StreetBuilding,
  ) {
    const modalRef = this.modalService.open(
      StreetBuildingModalComponent
    );

    modalRef.componentInstance.streetBuilding = streetBuilding;
    modalRef.componentInstance.neighborhoodId = this.neighborhoodId;

  }

  async uploadExcel(event: any) {

    const inputElement = document.createElement('input');
    inputElement.type = 'file';
    inputElement.accept = '.xlsx, .xls, .csv';
    inputElement.onchange = (event: any) => this.importHouses(event);
    inputElement.click();

  }

  async importHouses(event: any) {

    console.log(event);
    

    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = async (e: any) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const json = XLSX.utils.sheet_to_json(worksheet);
      console.log(json);

      const houses = json as {
        name: string, 
        number: number, 
        streetBuildingId: string,
        fullName: string,
        phone: string,
        notes: string,
      }[];

      const flag = confirm(
        `¿Esta seguro que desea importar ${houses.length} casas?`
      );

      console.log(houses);
      
      if(flag) {

        for(let i = 0; i < houses.length; i++) {

          await this.houseFlatService.create(
            this.neighborhoodId!,
            houses[i].streetBuildingId,
            {
              number: `#${houses[i].number}`,
              fullName: houses[i].fullName ?? '',
              phone: houses[i].phone ? houses[i].phone.toString() : '',
              notes: houses[i].notes ? houses[i].notes.toString() : '',
              status: true,
            } as IHouseFlat,
            true,
            `- Calle ${houses[i].name} - casa ${i + 1}/${houses.length}`
          )
  
        }

      }

    };

    reader.readAsArrayBuffer(file);
  }

}
