<div class="container-fluid py-3 px-4">
  <div class="row">
    <div class="col-12">
      <form id="signInForm" [formGroup]="formSignIn" (ngSubmit)="signIn()">
        <div class="row">
          <div class="col-12 text-center mb-4">
            <h2><b>
              CradleResident
            </b></h2>
          </div>

          <div class="col-12 form-group mb-4">
            <label for="email" class="form-label">Correo electrónico</label>
            <input
              type="email"
              class="form-control"
              formControlName="email"
              [ngClass]="{
                'is-invalid': submitted && formControls['email'].errors
              }"
              placeholder="Ingrese correo electrónico"
            />
            @if (submitted && formControls['email'].errors) {
              <div class="invalid-feedback">
                @if (formControls['email'].errors['required']) {
                  <div>Por favor, ingrese el correo electrónico</div>
                }
              </div>
            }
          </div>

          <div class="col-12 form-group mb-5">
            <label for="password" class="form-label">Contraseña</label>
            <div class="input-group">
              <input
                type="{{ visibilityPass ? 'text' : 'password' }}"
                class="form-control"
                formControlName="password"
                [ngClass]="{
                  'is-invalid': submitted && formControls['password'].errors
                }"
                placeholder="Ingrese contraseña"
              />
              <div class="input-group-text">
                <button
                  type="button"
                  (click)="visibilityPass = !visibilityPass"
                  class="btn p-0 d-flex align-items-center"
                >
                  <i class="material-icons">{{
                    visibilityPass ? "visibility_off" : "visibility"
                  }}</i>
                </button>
              </div>
            </div>
            @if (submitted && formControls['password'].errors) {
              <div class="invalid-feedback">
                @if (formControls['password'].errors['required']) {
                  <div>Por favor, ingrese contraseña</div>
                }
              </div>
            }
          </div>
        </div>
      </form>
    </div>

    <div class="col-12 mb-2">
      <a routerLink="/recover-password" class="btn w-100 btn-sm">
        ¿Olvidaste tu contraseña?
      </a>
    </div>

    <div class="col-12 mb-4">
      <button
        type="submit"
        form="signInForm"
        [disabled]="loading"
        class="btn btn-primary w-100"
      >
        <span
          [ngClass]="{ 'spinner-border spinner-border-sm': loading }"
          role="status"
          aria-hidden="true"
        ></span>
        Iniciar sesión
      </button>
    </div>

  </div>
</div>
