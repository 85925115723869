import { Component } from '@angular/core';
import { MENU_NEIGH } from '../../data/menu.data';
import { Menu } from '../../models/menu.model';
import { SlideMenuService } from '../../services/slide-menu.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NeighborhoodService } from '../../services/neighborhood.service';

@Component({
  selector: 'app-neighborhood-layout',
  templateUrl: './neighborhood-layout.component.html',
  styleUrl: './neighborhood-layout.component.scss'
})
export class NeighborhoodLayoutComponent {

  public outerHeight: number = document.documentElement.clientHeight;

  public menu: Array<Menu> = MENU_NEIGH;

  constructor(
    public neighborhoodService: NeighborhoodService,
    private route: Router,
    public slideMenuService: SlideMenuService
  ) {    

    // this.menu[0].route = this.route.url;

    console.log(this.menu[0]);

    const neighborhoodId = this.route.url.split('/')[2];

    this.menu.forEach((menuItem) => {

      if(menuItem.route) {

        menuItem.route = menuItem.route.replace(':neighborhoodId', neighborhoodId);

      } else {

        menuItem.children?.forEach((submenuItem) => {

          if(submenuItem.route) {

            submenuItem.route = submenuItem.route.replace(':neighborhoodId', neighborhoodId);

          }

        });

      }

    });

  }

}
