<div class="container-fluid py-3">
  <div class="row">

    @for (module of moduleService.modules; track $index) {

      <div class="col-12 bg-white py-3 px-3 rounded mb-4">
        <div class="row">

          <div class="col-6 d-flex align-items-center">
            <b>{{ module.description }}</b> <span class="text-secondary"> - {{ module.slug }}</span>
          </div>

          <div class="col-6 text-end">

            <button
              (click)="open(undefined, module.moduleId)"
              class="btn btn-sm btn-success rounded-circle me-2 my-1">
              <i class="material-icons mt-1">add</i>
            </button>

            <button
              (click)="open(module)"
              class="btn btn-sm btn-light rounded-circle me-2 my-1">
              <i class="material-icons mt-1">edit</i>
            </button>

            <button
              (click)="delete(module)"
              class="btn btn-sm btn-danger rounded-circle me-2 my-1">
              <i class="material-icons mt-1">delete</i>
            </button>
          </div>

        </div>

        <div class="col-12 mt-3">
          <div class="row">

            <div class="col-12 text-secondary">
              <b><small>Submódulos</small></b>
            </div>

            @for (submodule of module.children; track $index) {

              <div class="col-12 ps-4">
                <div class="row">

                  <div class="col-6 d-flex align-items-center">
                    <b>{{ submodule.description }}</b> <span class="text-secondary"> - {{ submodule.slug }}</span>
                  </div>
    
                  <div class="col-6 text-end">
                    <button
                      (click)="open(submodule)"
                      class="btn btn-sm btn-light rounded-circle me-2 my-1">
                      <i class="material-icons mt-1">edit</i>
                    </button>
    
                    <button
                      (click)="delete(submodule)"
                      class="btn btn-sm btn-danger rounded-circle me-2 my-1">
                      <i class="material-icons mt-1">delete</i>
                    </button>
                  </div>

                </div>
              </div>

            }

          </div>
        </div>

      </div>

    }

  </div>
</div>

<button
  (click)="open()"
  class="btn btn-sm btn-success rounded-circle me-2 my-1 position-fixed"
  style="bottom: 2rem; right: 2rem;">
  <i class="material-icons mt-1">add</i>
</button>
