import { Injectable } from '@angular/core';
import { Gate, IGate } from '../models/gate.model';
import { AuthService } from './auth.service';
import { HttpClient } from '@angular/common/http';
import { SpinnerService } from './spinner.service';
import { ToastService } from './toast.service';
import { environment } from '../../environments/environment';
import { IServerResponse, ServerResponse } from '../models/server-response.model';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GateService {

  public loading: boolean = false;
  public gates: Gate[] = [];

  public currentGate?: Gate;
  public subCurrentGate: Subject<Gate> = new Subject<Gate>();

  constructor(
    public authService: AuthService,
    private http: HttpClient,
    public spinnerService: SpinnerService,
    public toastService: ToastService
  ) { }

  /*
   ██████╗██████╗ ███████╗ █████╗ ████████╗███████╗
  ██╔════╝██╔══██╗██╔════╝██╔══██╗╚══██╔══╝██╔════╝
  ██║     ██████╔╝█████╗  ███████║   ██║   █████╗
  ██║     ██╔══██╗██╔══╝  ██╔══██║   ██║   ██╔══╝
  ╚██████╗██║  ██║███████╗██║  ██║   ██║   ███████╗
   ╚═════╝╚═╝  ╚═╝╚══════╝╚═╝  ╚═╝   ╚═╝   ╚══════╝

  */
  async create(
    neighborhoodId: string, 
    gate: IGate
  ): Promise<boolean> {
    let flag = false;
    const urlApi = `${environment.urlApi}/neighborhoods/${neighborhoodId}/gates`;

    this.spinnerService.loading = true;
    this.spinnerService.message = 'Registrando puerta de acceso';

    try {
      const token = await this.authService.getToken();

      const response = await new Promise<ServerResponse>(
        (resolve, reject) => {
          this.http.post<IServerResponse>(
            urlApi,
            JSON.stringify(gate),
            {
              headers: {
                "content-type": "application/json",
                "Authorization": token.toString()
              }
            }
          ).subscribe({
            next: (
              response: IServerResponse
            ) => resolve(new ServerResponse(response)),
            error: (error) => {
              if (error.error) {
                resolve(new ServerResponse(error.error));
              } else {
                this.toastService.show({
                  header: 'Error de servidor',
                  body: `${error}`,
                  classname: 'bg-danger text-white'
                });
                reject(error);
              }
            }
          });
        }
      );

      if (response.statusCode === 201) {
        flag = true;
        this.toastService.show({
          body: `${response.message}`,
          classname: 'bg-success text-white'
        });
        this.getAll(neighborhoodId);
      } else {
        this.toastService.show({
          header: 'Error de servidor',
          body: `${response.message}`,
          classname: 'bg-danger text-white'
        });
      }
    } catch (error) {
      console.log(error);
      this.toastService.show({
        header: 'Error',
        body: `${error}`,
        classname: 'bg-danger text-white'
      });
    }

    this.spinnerService.loading = false;
    this.spinnerService.message = '';
    return flag;
  }

  /*
  ██████╗ ███████╗██╗     ███████╗████████╗███████╗
  ██╔══██╗██╔════╝██║     ██╔════╝╚══██╔══╝██╔════╝
  ██║  ██║█████╗  ██║     █████╗     ██║   █████╗
  ██║  ██║██╔══╝  ██║     ██╔══╝     ██║   ██╔══╝
  ██████╔╝███████╗███████╗███████╗   ██║   ███████╗
  ╚═════╝ ╚══════╝╚══════╝╚══════╝   ╚═╝   ╚══════╝

  */
  async delete(
    gate: Gate
  ): Promise<boolean> {
    let flag = false;
    const urlApi = `${environment.urlApi}/neighborhoods/${gate.neighborhoodId}/gates/${gate.gateId}`;

    this.spinnerService.loading = true;
    this.spinnerService.message = 'Eliminando puerta de acceso';

    try {
      const token = await this.authService.getToken();

      const response = await new Promise<ServerResponse>(
        (resolve, reject) => {
          this.http.delete<IServerResponse>(
            urlApi,
            {
              headers: {
                "Authorization": token.toString()
              }
            }
          ).subscribe({
            next: (
              response: IServerResponse
            ) => resolve(new ServerResponse(response)),
            error: (error) => {
              if (error.error) {
                resolve(new ServerResponse(error.error));
              } else {
                this.toastService.show({
                  header: 'Error de servidor',
                  body: `${error}`,
                  classname: 'bg-danger text-white'
                });
              }
              reject(error);
            }
          });
        }
      );

      if (response.statusCode === 200) {
        flag = true;
        this.toastService.show({
          body: `${response.message}`,
          classname: 'bg-success text-white'
        });
        this.getAll(gate.neighborhoodId);
      } else {
        this.toastService.show({
          header: 'Error de servidor',
          body: `${response.message}`,
          classname: 'bg-danger text-white'
        });
      }
    } catch (error) {
      console.log(error);
      this.toastService.show({
        header: 'Error',
        body: `${error}`,
        classname: 'bg-danger text-white'
      });
    }

    this.spinnerService.loading = false;
    this.spinnerService.message = '';
    return flag;
  }

  /*
   ██████╗ ███████╗████████╗
  ██╔════╝ ██╔════╝╚══██╔══╝
  ██║  ███╗█████╗     ██║
  ██║   ██║██╔══╝     ██║
  ╚██████╔╝███████╗   ██║
   ╚═════╝ ╚══════╝   ╚═╝

  */
  async get(
    neighborhoodId: string,
    gateId: string, 
  ) {

    const urlApi = `${environment.urlApi}/neighborhoods/${neighborhoodId}/gates/${gateId}`;

    this.spinnerService.loading = true;
    this.spinnerService.message = 'Obteniendo puerta de acceso';

    try {
      const token = await this.authService.getToken();

      const response = await new Promise<ServerResponse<{ gate: IGate }>>(
        (resolve, reject) => {
          this.http.get<IServerResponse<{ gate: IGate }>>(
            urlApi,
            {
              headers: {
                "content-type": "application/json",
                "Authorization": token.toString()
              }
            }
          ).subscribe({
            next: (
              response: IServerResponse<{ gate: IGate }>
            ) => resolve(
              new ServerResponse<{ gate: IGate }>(response)
            ),
            error: (error) => {
              if (error.error) {
                resolve(new ServerResponse(error.error));
              } else {
                this.toastService.show(
                  {
                    header: 'Error de servidor',
                    body: `${error}`,
                    classname: 'bg-danger text-white'
                  }
                );
                reject(error);
              }
            },
          });
        }
      );

      if (response.statusCode === 200) {

        console.log(response.data);
        
        
        this.currentGate = new Gate(response.data!.gate);
        this.subCurrentGate.next(this.currentGate);

      } else {
        this.toastService.show({
          header: 'Error de servidor',
          body: `${response.message}`,
          classname: 'bg-danger text-white'
        });
      }
    } catch (error) {
      console.log(error);

      this.toastService.show(
        {
          header: 'Error',
          body: `${error}`,
          classname: 'bg-danger text-white'
        }
      );
    }

    this.spinnerService.loading = false;
    this.spinnerService.message = '';
  }

  /*
   ██████╗ ███████╗████████╗ █████╗ ██╗     ██╗
  ██╔════╝ ██╔════╝╚══██╔══╝██╔══██╗██║     ██║
  ██║  ███╗█████╗     ██║   ███████║██║     ██║
  ██║   ██║██╔══╝     ██║   ██╔══██║██║     ██║
  ╚██████╔╝███████╗   ██║   ██║  ██║███████╗███████╗
   ╚═════╝ ╚══════╝   ╚═╝   ╚═╝  ╚═╝╚══════╝╚══════╝

  */
  async getAll(
    neighborhoodId: string
  ) {
    this.loading = true;
    this.gates = [];
    const urlApi = `${environment.urlApi}/neighborhoods/${neighborhoodId}/gates`;

    try {
      const token = await this.authService.getToken();

      const response = await new Promise<ServerResponse<{ gates: IGate[] }>>(
        (resolve, reject) => {
          this.http.get<IServerResponse<{ gates: IGate[] }>>(
            urlApi,
            {
              headers: {
                "content-type": "application/json",
                "Authorization": token.toString()
              }
            }
          ).subscribe({
            next: (
              response: IServerResponse<{ gates: IGate[] }>
            ) => resolve(
              new ServerResponse<{ gates: IGate[] }>(response)
            ),
            error: (error) => {
              if (error.error) {
                resolve(new ServerResponse(error.error));
              } else {

                this.toastService.show(
                  {
                    header: 'Error de servidor',
                    body: `${error}`,
                    classname: 'bg-danger text-white'
                  }
                );
                
                reject(error);
              }
            }
          });
        }
      );

      if (response.statusCode === 200) {
        this.gates = response.data!.gates.map(
          gate => new Gate(gate)
        );
      } else {
        this.toastService.show({
          header: 'Error de servidor',
          body: `${response.message}`,
          classname: 'bg-danger text-white'
        });
      }
    } catch (error) {
      console.log(error);

      this.toastService.show(
        {
          header: 'Error',
          body: `${error}`,
          classname: 'bg-danger text-white'
        }
      );
    }

    this.loading = false;
  }

  /*
  ██╗   ██╗██████╗ ██████╗  █████╗ ████████╗███████╗
  ██║   ██║██╔══██╗██╔══██╗██╔══██╗╚══██╔══╝██╔════╝
  ██║   ██║██████╔╝██║  ██║███████║   ██║   █████╗
  ██║   ██║██╔═══╝ ██║  ██║██╔══██║   ██║   ██╔══╝
  ╚██████╔╝██║     ██████╔╝██║  ██║   ██║   ███████╗
   ╚═════╝ ╚═╝     ╚═════╝ ╚═╝  ╚═╝   ╚═╝   ╚══════╝

  */
  async update(
    gate: IGate
  ): Promise<boolean> {

    let flag = false;
    const urlApi = `${environment.urlApi}/neighborhoods/${gate.neighborhoodId}/gates/${gate.gateId}`;

    this.spinnerService.loading = true;
    this.spinnerService.message = 'Actualizando puerta de acceso';

    try {
      const token = await this.authService.getToken();

      const response = await new Promise<ServerResponse>(
        (resolve, reject) => {
          this.http.put<IServerResponse>(
            urlApi,
            JSON.stringify(gate),
            {
              headers: {
                "content-type": "application/json",
                "Authorization": token.toString()
              }
            }
          ).subscribe({
            next: (
              response: IServerResponse
            ) => resolve(new ServerResponse(response)),
            error: (error) => {
              if (error.error) {
                resolve(new ServerResponse(error.error));
              } else {
                this.toastService.show({
                  header: 'Error de servidor',
                  body: `${error}`,
                  classname: 'bg-danger text-white'
                });
                reject(error);
              }
            }
          });
        }
      );

      if (response.statusCode === 200) {
        flag = true;
        this.toastService.show({
          body: `${response.message}`,
          classname: 'bg-success text-white'
        });
        this.getAll(gate.neighborhoodId);
      }
    } catch (error) {
      console.log(error);

      this.toastService.show(
        {
          header: 'Error',
          body: `${error}`,
          classname: 'bg-danger text-white'
        }
      );
    }

    this.spinnerService.loading = false;
    return flag;
  }
}
