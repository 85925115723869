<div class="container-fluid py-3">
  <div class="row">

    <div class="col-12 mb-3">
      <h4><b>
        {{ gateService.currentGate?.gateName }}
      </b></h4>
      <small class="text-secondary">
        {{ gateService.currentGate?.firestoreUid }}
      </small>
    </div>

    <div class="col-12 rounded bg-white py-3 text-end mb-4">
      <button
        (click)="openAccessDoorGroupModal()"
        class="btn btn-success rounded-circle me-2">
        <i class="material-icons mt-1">add</i>
      </button>

      <button 
        (click)="gateService.get(neighborhoodId, gateId)" 
        class="btn btn-primary rounded-circle me-2">
        <i class="material-icons mt-1">refresh</i>
      </button>

    </div>

    <div class="col-12 mb-2">
      <small><b class="text-secondary">
        Grupos de acceso
      </b></small>
    </div>

    <div class="col-12">
      <div class="row">


        @for (accessDoorGroup of accessDoorGroupService.accessDoorGroups; track $index) {
          <div class="col-12 col-md-6 col-xl-4 px-3 d-flex align-items-stretch">
            <div class="row">

              <div class="col-12 py-3 rounded bg-white">
                <div class="row">
        
                  <div class="col-12 mb-4">
                    <div class="row">
                      <div class="col">
                        <div class="row">
                          <div class="col-12">
                            <b>{{ accessDoorGroup.accessDoorGroup }}</b>
                          </div>
                          <div class="col-12 text-secondary">
                            {{ accessDoorGroup.accessDoorGroupType?.accessDoorGroup }}
                          </div>
                        </div>
                      </div>
                      <div class="col text-end">
                        
                        <button
                          (click)="openAccessDoorGroupModal(accessDoorGroup)"
                          class="btn btn-light rounded-circle me-2">
                          <i class="material-icons mt-1">edit</i>
                        </button>
    
                        <button
                          (click)="deleteAccessDoorGroup(accessDoorGroup)" 
                          class="btn btn-danger rounded-circle me-2">
                          <i class="material-icons mt-1">delete</i>
                        </button>
    
                      </div>
                    </div>
                  </div>
    
                  @if(accessDoorGroup.accessDoors.length < 2) {
                    <div class="col-12 mb-3">
    
                      <button
                        (click)="openAccessDoorModal(accessDoorGroup)"
                        class="btn btn-outline-success w-100 d-flex align-items-center justify-content-center">
                        <i class="material-icons">add</i>
                      </button>
      
                    </div>
                  }
    
                  <div class="col-12">
                    <div class="row">
    
                      @for (accessDoor of accessDoorGroup.accessDoors; track $index) {
    
                        <div class="col">
                          <div 
                            ngbDropdown 
                            class="d-inline-block w-100"
                            placement="bottom-end">
                            <button 
                              type="button" 
                              class="btn w-100"
                              [ngClass]="{
                                'btn-primary': accessDoor.type,
                                'btn-outline-primary': !accessDoor.type
                              }"
                              [id]="accessDoor.accessDoorId" 
                              ngbDropdownToggle>
                              {{ accessDoor.label }}
                            </button>
                            <div 
                              ngbDropdownMenu>
                              <button 
                                ngbDropdownItem
                                (click)="openAccessDoorModal(accessDoorGroup, accessDoor)">
                                Editar
                              </button>
                              <button 
                                ngbDropdownItem
                                (click)="deleteAccessDoor(accessDoorGroup, accessDoor)">
                                Borrar
                              </button>
                              <button ngbDropdownItem>Accionar</button>
                            </div>
                          </div>
                        </div>
    
                      }
    
                    </div>
                  </div>
        
                </div>
              </div>

            </div>
          </div>
        }

      </div>
    </div>

  </div>
</div>