import { Component } from '@angular/core';

@Component({
  selector: 'app-settings-profiles-page',
  templateUrl: './settings-profiles-page.component.html',
  styleUrl: './settings-profiles-page.component.scss'
})
export class SettingsProfilesPageComponent {

}
