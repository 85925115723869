import { Component } from '@angular/core';

@Component({
  selector: 'app-profiles-page',
  templateUrl: './profiles-page.component.html',
  styleUrl: './profiles-page.component.scss'
})
export class ProfilesPageComponent {

}
