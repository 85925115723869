import { Component } from '@angular/core';

@Component({
  selector: 'app-neighborhood-page',
  templateUrl: './neighborhood-page.component.html',
  styleUrl: './neighborhood-page.component.scss'
})
export class NeighborhoodPageComponent {

}
