<div class="col-12 bg-white py-3 px-3 rounded mb-4">
  <div class="row">

    <div class="col-6 col-lg-9 d-flex align-items-center">
      <h4><b>{{ streetBuilding!.name }}</b></h4>
    </div>  

    <div class="col-6 col-lg-3 text-end">

      <!-- <a
        [routerLink]="[streetBuilding!.streetBuildingId,'houses-flats']"
        class="btn btn-sm btn-light rounded-circle me-2 my-1"
        placement="top" 
        ngbTooltip="Ver más">
        <i class="material-icons mt-1">more_vert</i>
      </a> -->

      <button
        (click)="open(streetBuilding!)"
        class="btn btn-sm btn-light rounded-circle me-2 my-1"
        placement="top" 
        ngbTooltip="Editar">
        <i class="material-icons mt-1">edit</i>
      </button>

      <button
        (click)="delete(streetBuilding!)"
        class="btn btn-sm btn-danger rounded-circle me-2 my-1">
        <i class="material-icons mt-1">delete</i>
      </button>

    </div>

    @if(authService.currentUser?.profile === 'sudo') {
      <div class="col-12 mb-3 text-secondary">
        #{{ streetBuilding!.streetBuildingId }}
        <button 
          (click)="utilService.copyToClipboard(streetBuilding!.streetBuildingId)"
          class="btn">
          <i class="material-icons text-secondary">content_copy</i>
        </button>
      </div>
    }

    <div class="col-12 mb-3">
      <div class="row">
        <div class="col">
          <div class="row">

            <div class="col-12 text-secondary">
              <small><b>No. Casas</b></small>
            </div>
            <div class="col-12">
              <h5><b>{{ streetBuilding!.housesFlats.length }}</b></h5>
            </div>

          </div>
        </div>
        <div class="col">
          <div class="row">

            <div class="col-12 text-secondary">
              <small><b>Activos</b></small>
            </div>
            <div class="col-12 text-success">
              <h5><b>{{ streetBuilding!.activeHousesFlats }}</b></h5>
            </div>

          </div>
        </div>
        <div class="col mb-2">
          <div class="row">

            <div class="col-12 text-secondary">
              <small><b>Inactivos</b></small>
            </div>
            <div class="col-12 text-danger">
              <h5><b>{{ streetBuilding!.inactiveHouseFlat }}</b></h5>
            </div>

          </div>
        </div>

        @if (authService.currentUser?.profile === 'sudo') {
          <div class="col">
            <div class="row">
  
              <div class="col-12 text-secondary">
                <small><b>Dispositivos</b></small>
              </div>
              <div class="col-12">
                <h5><b>{{ streetBuilding!.totalDevices }}</b></h5>
              </div>
  
            </div>
          </div>
        }

      </div>
    </div>

    <div class="col-12">
      <button
        type="button"
        class="btn btn-outline-dark me-1"
        (click)="collapse.toggle()"
        [attr.aria-expanded]="!isCollapsed"
        aria-controls="collapseExample"
      >
        {{ isCollapsed ? 'Mostrar' : 'Ocultar' }} casas
      </button>

      @if(!isCollapsed) {
        <button
          type="button"
          (click)="openHouseFlat()"
          class="btn btn-success ms-1"
        >
          Agregar casa
        </button>
      }
    </div>

    <div class="col-12">
      <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
        <div class="mt-4">
          
          @for(houseFlat of streetBuilding!.housesFlats; track $index) {

            <div class="col-12 px-2 py-2 border-bottom">
              <div class="row">

                <div class="col-auto d-flex align-items-center">
                  {{ houseFlat.number }}
                </div>

                <div class="col d-flex align-items-center">
                  {{ houseFlat.fullName }}
                </div>

                <div class="col d-flex align-items-center">
                  {{ houseFlat.residentType?.description }}
                </div>

                <div class="col-auto text-center d-flex align-items-center">
                  @if(houseFlat.status) {
                    <span class="badge rounded-pill text-bg-success">
                      Activo
                    </span>
                  } @else {
                    <span class="badge rounded-pill text-bg-danger">
                      Inactivo
                    </span>
                  }
                </div>

                <div class="col d-flex align-items-center">
                  <div [innerHTML]="houseFlat.notes | newlineToBr"></div>
                </div>

                <div class="col-auto d-flex align-items-center">
                  <span 
                    class="d-flex align-items-center me-3"
                    placement="top" 
                    ngbTooltip="Usuarios">
                    <i class="material-icons me-1 text-success">person_outline</i> {{ houseFlat.residents.length }}
                  </span>
  
                  <span 
                    class="d-flex align-items-center"
                    placement="top" 
                    ngbTooltip="Invitaciones">
                    <i class="material-icons me-1">mail_outline</i> {{ houseFlat.invitations.length }}
                  </span>
                </div>

                @if(authService.currentUser?.profile === 'sudo') {
                  <div class="col-auto d-flex align-items-center">
                    <span 
                      class="d-flex align-items-center me-3"
                      placement="top" 
                      ngbTooltip="Dispositivos">
                      <i class="material-icons me-1 text">phone_iphone</i> {{ houseFlat.devices.length }}
                    </span>
    
                  </div>
                }

                <!-- <div class="col text-center d-flex align-items-center">
                  
                </div> -->

                <div class="col-auto text-end d-flex align-items-center justify-content-end">

                  <p-inputSwitch
                    class="me-2"
                    (onChange)="update(houseFlat)"
                    [(ngModel)]="houseFlat.status">
                  </p-inputSwitch>

                  @if(authService.currentUser?.profile === 'sudo') {
                    <button
                      (click)="openDevices(houseFlat)"
                      class="btn btn-sm btn-light rounded-circle me-2 my-1"
                      placement="top" 
                      ngbTooltip="Dispositivos">
                      <i class="material-icons mt-1">phone_iphone</i>
                    </button>
                  }

                  <button
                    class="btn btn-sm btn-light rounded-circle me-2 my-1"
                    placement="top" 
                    ngbTooltip="Tags">
                    <i class="material-icons mt-1">style</i>
                  </button>

                  <button
                    (click)="openResidents(houseFlat)"
                    class="btn btn-sm btn-light rounded-circle me-2 my-1"
                    placement="top" 
                    ngbTooltip="Usuarios">
                    <i class="material-icons mt-1">person_outline</i>
                  </button>

                  <button
                    (click)="openHouseFlat(houseFlat)"
                    class="btn btn-sm btn-light rounded-circle me-2 my-1"
                    placement="top" 
                    ngbTooltip="Editar">
                    <i class="material-icons mt-1">edit</i>
                  </button>

                  <button
                    (click)="deleteHouseFlat(houseFlat)"
                    class="btn btn-sm btn-danger rounded-circle me-2 my-1">
                    <i class="material-icons mt-1">delete</i>
                  </button>
                </div>

              </div>
            </div>

          }

        </div>
      </div>
            
    </div>

  </div>
</div>