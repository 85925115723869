import { Component } from '@angular/core';
import { NeighborhoodService } from '../../../services/neighborhood.service';
import { MovementService } from '../../../services/movement.service';
import moment from 'moment';
import { Movement } from '../../../models/movement.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MovementModalComponent } from '../../../components/movement-modal/movement-modal.component';
import { ToastService } from '../../../services/toast.service';
import * as XLSX from 'xlsx';
import { UtilService } from '../../../services/util.service';
import { jsPDF } from "jspdf";

@Component({
  selector: 'app-movements-page',
  templateUrl: './movements-page.component.html',
  styleUrl: './movements-page.component.scss'
})
export class MovementsPageComponent {

  public years: any[] = [];
  public months: any[] = [];

  public yearSelected?: number;
  public currentYear: any;

  public currentMonth: any;
  public monthSelected?: number;

  data: any;
  options: any;
  
  dataMonth: any;
  optionsMonth: any;

  constructor(
    public movementService: MovementService,
    public neighborhoodService: NeighborhoodService,
    private modalService: NgbModal,
    private toastService: ToastService,
    private utilService: UtilService
  ) { 


  }

  ngOnInit() {

    if(this.neighborhoodService.currentNeighborhood) {

      const startDate = new Date(this.neighborhoodService.currentNeighborhood.createdAt);
      const endDate = new Date();

      let currentDate = new Date(startDate);

      while (currentDate <= endDate) {
        const year = currentDate.getFullYear();
        const month = currentDate.getMonth() + 1;
        const description = moment(currentDate).format('MMMM');

        let yearObj = this.years.find(y => y.year === year) as any;
        if (!yearObj) {
          yearObj = { year, months: [] };
          this.years.push(yearObj);
        }

        yearObj.months.push({ month, description });
        currentDate.setMonth(currentDate.getMonth() + 1);
      }

      console.log(this.years);

      this.currentYear = this.years.find(y => y.year === endDate.getFullYear());
      this.yearSelected = this.currentYear.year;
      this.months = this.currentYear.months;
      this.currentMonth = this.currentYear.months.find(
        (m: any) => m.month === endDate.getMonth() + 1
      );
      this.monthSelected = this.currentMonth.month;

      console.log(this.currentYear);
      console.log(this.currentMonth);

      console.log('Antes de get data');

      this.getMovements();
    } else {

      this.neighborhoodService.subCurrentNeighborhood.subscribe(
        (neighborhood) => {
  
          console.log(neighborhood);
  
          if (neighborhood) {
            const startDate = new Date(neighborhood.createdAt);
            const endDate = new Date();
  
            let currentDate = new Date(startDate);
  
            while (currentDate <= endDate) {
              const year = currentDate.getFullYear();
              const month = currentDate.getMonth() + 1;
              const description = moment(currentDate).format('MMMM');
  
              let yearObj = this.years.find(y => y.year === year) as any;
              if (!yearObj) {
                yearObj = { year, months: [] };
                this.years.push(yearObj);
              }
  
              yearObj.months.push({ month, description });
              currentDate.setMonth(currentDate.getMonth() + 1);
            }
  
            console.log(this.years);
  
            this.currentYear = this.years.find(y => y.year === endDate.getFullYear());
            this.yearSelected = this.currentYear.year;
            this.months = this.currentYear.months;
            this.currentMonth = this.currentYear.months.find(
              (m: any) => m.month === endDate.getMonth() + 1
            );
            this.monthSelected = this.currentMonth.month;
  
            console.log(this.currentYear);
            console.log(this.currentMonth);
            console.log(this.years);

            console.log('Antes de get data subject');
  
            this.getMovements();
            
          }
  
        }
      );

    }

    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue('--text-color');
    const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
    const surfaceBorder = documentStyle.getPropertyValue('--surface-border');
    
    this.data = {
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
        datasets: [
            {
                label: 'My First dataset',
                backgroundColor: documentStyle.getPropertyValue('--blue-500'),
                borderColor: documentStyle.getPropertyValue('--blue-500'),
                data: [65, 59, 80, 81, 56, 55, 40]
            },
            {
                label: 'My Second dataset',
                backgroundColor: documentStyle.getPropertyValue('--pink-500'),
                borderColor: documentStyle.getPropertyValue('--pink-500'),
                data: [28, 48, 40, 19, 86, 27, 90]
            }
        ]
    };

    this.options = {
        maintainAspectRatio: false,
        aspectRatio: 0.68,
        plugins: {
            legend: {
                labels: {
                    color: textColor
                }
            },
            title: {
              display: true,
              text: 'Custom Chart Title'
            }
        },
        scales: {
            x: {
                ticks: {
                    color: textColorSecondary,
                    font: {
                        weight: 500
                    }
                },
                grid: {
                    color: surfaceBorder,
                    drawBorder: false
                }
            },
            y: {
                ticks: {
                    color: textColorSecondary
                },
                grid: {
                    color: surfaceBorder,
                    drawBorder: false
                }
            }

        }
    };

    this.dataMonth = {
      labels: ['Ingresos', 'Egresos', 'Cuentas por pagar'],
      datasets: [
          {
              data: [300, 50, 100],
              backgroundColor: [documentStyle.getPropertyValue('--blue-500'), documentStyle.getPropertyValue('--yellow-500'), documentStyle.getPropertyValue('--green-500')],
              hoverBackgroundColor: [documentStyle.getPropertyValue('--blue-400'), documentStyle.getPropertyValue('--yellow-400'), documentStyle.getPropertyValue('--green-400')]
          }
      ]
    };


    this.optionsMonth = {
        maintainAspectRatio: false,
        aspectRatio: 0.68,
        cutout: '60%',
        plugins: {
            legend: {
                labels: {
                    color: textColor
                }
            },
            title: {
              display: true,
              text: 'Custom Chart Title'
            }
        }
    };

  }

  /*
  ██████╗ ███████╗██╗     ███████╗████████╗███████╗
  ██╔══██╗██╔════╝██║     ██╔════╝╚══██╔══╝██╔════╝
  ██║  ██║█████╗  ██║     █████╗     ██║   █████╗
  ██║  ██║██╔══╝  ██║     ██╔══╝     ██║   ██╔══╝
  ██████╔╝███████╗███████╗███████╗   ██║   ███████╗
  ╚═════╝ ╚══════╝╚══════╝╚══════╝   ╚═╝   ╚══════╝

  */
  async delete(movement: Movement) {

    const flag = confirm('¿Estás seguro de eliminar este movimiento?');

    if(flag) {
      const anotherFlag = await this.movementService.delete(
        movement
      );

      if(anotherFlag) {
        this.getMovements();
      }
    }

  }

  /*
  ██████╗  ██████╗ ██╗    ██╗███╗   ██╗██╗      ██████╗  █████╗ ██████╗ ██████╗ ███████╗██████╗  ██████╗ ██████╗ ████████╗
  ██╔══██╗██╔═══██╗██║    ██║████╗  ██║██║     ██╔═══██╗██╔══██╗██╔══██╗██╔══██╗██╔════╝██╔══██╗██╔═══██╗██╔══██╗╚══██╔══╝
  ██║  ██║██║   ██║██║ █╗ ██║██╔██╗ ██║██║     ██║   ██║███████║██║  ██║██████╔╝█████╗  ██████╔╝██║   ██║██████╔╝   ██║
  ██║  ██║██║   ██║██║███╗██║██║╚██╗██║██║     ██║   ██║██╔══██║██║  ██║██╔══██╗██╔══╝  ██╔═══╝ ██║   ██║██╔══██╗   ██║
  ██████╔╝╚██████╔╝╚███╔███╔╝██║ ╚████║███████╗╚██████╔╝██║  ██║██████╔╝██║  ██║███████╗██║     ╚██████╔╝██║  ██║   ██║
  ╚═════╝  ╚═════╝  ╚══╝╚══╝ ╚═╝  ╚═══╝╚══════╝ ╚═════╝ ╚═╝  ╚═╝╚═════╝ ╚═╝  ╚═╝╚══════╝╚═╝      ╚═════╝ ╚═╝  ╚═╝   ╚═╝

  */
  downloadReport() {

    // const doc = new jsPDF();

    // doc.text("Hello world!", 10, 10);
    // doc.save("a4.pdf");

    // return;

    const data: any[] = [];

    data.push([
      'DESCRIPCIÓN',
      'NOTAS',
      'FECHA',
      'INGRESO/EGRESO',
      'ESTADO',
      'MONTO'
    ]);

    this.movementService.movements.forEach(
      (movement, index) => {

        data.push([
          movement.description,
          movement.notes,
          moment(movement.createdAt).format('DD/MM/YYYY'),
          movement.movementType 
            ? 'Ingreso' 
            : 'Egreso',
          movement.statusDescription,
          movement.movementType
            ? movement.amount
            : -movement.amount
        ]);

      }
    );

    data.push([
      '',
      '',
      '',
      '',
      '',
      ''
    ]);

    data.push([
      'Cuentas por pagar',
      '',
      '',
      '',
      '',
      -this.movementService.insights!.monthBalance!.pendingExpenses
    ]);

    data.push([
      'Cobros pendientes',
      '',
      '',
      '',
      '',
      this.movementService.insights?.monthBalance?.pendingIncome
    ]);

    data.push([
      'Ingresos',
      '',
      '',
      '',
      '',
      this.movementService.insights?.monthBalance?.income
    ]);

    data.push([
      'Egresos',
      '',
      '',
      '',
      '',
      -this.movementService.insights!.monthBalance!.expenses
    ]);

    data.push([
      'En caja',
      '',
      '',
      '',
      '',
      this.movementService.insights!.totalBalance!.balance - this.movementService.insights!.monthBalance!.balance
    ]);

    data.push([
      'BALANCE NETO DEL MES',
      '',
      '',
      '',
      '',
      this.movementService.insights?.monthBalance?.balance
    ]);

    // data.push([
    //   'EN CAJA',
    //   '',
    //   '',
    //   '',
    //   '',
    //   this.movementService.insights?.lastMonthBalance?.balance
    // ]);

    data.push([
      'BALANCE TOTAL',
      '',
      '',
      '',
      '',
      this.movementService.insights?.totalBalance?.balance
    ]);

    const worksheet = XLSX.utils.aoa_to_sheet(data);

    const columnWidths = data[1].map((_: any, colIndex: string | number) => {
      const maxLength = data.reduce((max, row) => {
        const cell = row[colIndex];
        const cellLength = cell ? cell.toString().length : 0;
        return Math.max(max, cellLength);
      }, 0);
      return { wch: maxLength + 2 }; // Ajuste adicional para margen
    });

    // Asignar los anchos de columna al worksheet
      worksheet['!cols'] = columnWidths;
  
      // Crear un libro de trabajo y agregar la hoja
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, `${this.currentMonth.description} ${this.yearSelected}`);
  
      // Exportar el archivo XLSX
      const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  
      // Crear un Blob para la descarga
      const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
  
      // Crear un enlace de descarga
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
  
      // Asignar el nombre del archivo
      link.download = `${this.neighborhoodService.currentNeighborhood?.name}-estado-de-cuenta-${this.currentMonth.description}-${this.yearSelected}.xlsx`;
  
      // Disparar la descarga
        link.click();

  }

  /*
   ██████╗ ███████╗████████╗███╗   ███╗ ██████╗ ██╗   ██╗███████╗███╗   ███╗███████╗███╗   ██╗████████╗███████╗
  ██╔════╝ ██╔════╝╚══██╔══╝████╗ ████║██╔═══██╗██║   ██║██╔════╝████╗ ████║██╔════╝████╗  ██║╚══██╔══╝██╔════╝
  ██║  ███╗█████╗     ██║   ██╔████╔██║██║   ██║██║   ██║█████╗  ██╔████╔██║█████╗  ██╔██╗ ██║   ██║   ███████╗
  ██║   ██║██╔══╝     ██║   ██║╚██╔╝██║██║   ██║╚██╗ ██╔╝██╔══╝  ██║╚██╔╝██║██╔══╝  ██║╚██╗██║   ██║   ╚════██║
  ╚██████╔╝███████╗   ██║   ██║ ╚═╝ ██║╚██████╔╝ ╚████╔╝ ███████╗██║ ╚═╝ ██║███████╗██║ ╚████║   ██║   ███████║
   ╚═════╝ ╚══════╝   ╚═╝   ╚═╝     ╚═╝ ╚═════╝   ╚═══╝  ╚══════╝╚═╝     ╚═╝╚══════╝╚═╝  ╚═══╝   ╚═╝   ╚══════╝

  */
   async getMovements() {
    
    if(
      this.yearSelected &&
      this.monthSelected
    ) {

      await this.movementService.getAll(
        this.neighborhoodService.currentNeighborhood!.neighborhoodId,
        this.monthSelected!,
        this.yearSelected!,
      )

    } else {

      this.toastService.show({
        header: 'Error',
        body: 'Seleccione un mes y un año',
        classname: 'bg-danger text-white'
      });

    }

  }

  /*
   ██████╗ ███╗   ██╗ ██████╗██╗  ██╗ █████╗ ███╗   ██╗ ██████╗ ███████╗██╗   ██╗███████╗ █████╗ ██████╗
  ██╔═══██╗████╗  ██║██╔════╝██║  ██║██╔══██╗████╗  ██║██╔════╝ ██╔════╝╚██╗ ██╔╝██╔════╝██╔══██╗██╔══██╗
  ██║   ██║██╔██╗ ██║██║     ███████║███████║██╔██╗ ██║██║  ███╗█████╗   ╚████╔╝ █████╗  ███████║██████╔╝
  ██║   ██║██║╚██╗██║██║     ██╔══██║██╔══██║██║╚██╗██║██║   ██║██╔══╝    ╚██╔╝  ██╔══╝  ██╔══██║██╔══██╗
  ╚██████╔╝██║ ╚████║╚██████╗██║  ██║██║  ██║██║ ╚████║╚██████╔╝███████╗   ██║   ███████╗██║  ██║██║  ██║
   ╚═════╝ ╚═╝  ╚═══╝ ╚═════╝╚═╝  ╚═╝╚═╝  ╚═╝╚═╝  ╚═══╝ ╚═════╝ ╚══════╝   ╚═╝   ╚══════╝╚═╝  ╚═╝╚═╝  ╚═╝

  */
  onChangeYear(year: any) {

    console.log(year);

    if(year) {
      
      this.months = year.months;
      console.log(this.months);
      this.currentMonth = null;

    } else {

      this.months = [];
      this.currentMonth = null;

    }

  }

  /*
   ██████╗ ██████╗ ███████╗███╗   ██╗
  ██╔═══██╗██╔══██╗██╔════╝████╗  ██║
  ██║   ██║██████╔╝█████╗  ██╔██╗ ██║
  ██║   ██║██╔═══╝ ██╔══╝  ██║╚██╗██║
  ╚██████╔╝██║     ███████╗██║ ╚████║
   ╚═════╝ ╚═╝     ╚══════╝╚═╝  ╚═══╝

  */
  open(movement?: Movement) {

    const modalRef = this.modalService.open(
      MovementModalComponent
    );

    modalRef.componentInstance.movement = movement;
    modalRef.componentInstance.neighborhoodId = this.neighborhoodService.currentNeighborhood!.neighborhoodId;

    modalRef.closed.subscribe(
      async () => {
    
        this.getMovements();

      }
    );

  }

}
