import { Component } from '@angular/core';
import { ProofTypeService } from '../../../services/proof-type.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProofType } from '../../../models/proof-type.model';
import { ProofTypeModalComponent } from '../../../components/proof-type-modal/proof-type-modal.component';

@Component({
  selector: 'app-proof-types-page',
  templateUrl: './proof-types-page.component.html',
  styleUrl: './proof-types-page.component.scss'
})
export class ProofTypesPageComponent {

  constructor(
    public proofTypeService: ProofTypeService,
    private modalService: NgbModal,
  ) {

  }

  /*
  ██████╗ ███████╗██╗     ███████╗████████╗███████╗
  ██╔══██╗██╔════╝██║     ██╔════╝╚══██╔══╝██╔════╝
  ██║  ██║█████╗  ██║     █████╗     ██║   █████╗
  ██║  ██║██╔══╝  ██║     ██╔══╝     ██║   ██╔══╝
  ██████╔╝███████╗███████╗███████╗   ██║   ███████╗
  ╚═════╝ ╚══════╝╚══════╝╚══════╝   ╚═╝   ╚══════╝

  */
  async delete(
    proofType: ProofType
  ) {

    const flag = confirm(
      `¿Esta seguro que desea borrar el typo de prueba ${proofType.description}?`
    );

    if(flag) {
      await this.proofTypeService.delete(
        proofType
      );
    }

  }

  /*
   ██████╗ ██████╗ ███████╗███╗   ██╗
  ██╔═══██╗██╔══██╗██╔════╝████╗  ██║
  ██║   ██║██████╔╝█████╗  ██╔██╗ ██║
  ██║   ██║██╔═══╝ ██╔══╝  ██║╚██╗██║
  ╚██████╔╝██║     ███████╗██║ ╚████║
   ╚═════╝ ╚═╝     ╚══════╝╚═╝  ╚═══╝

  */
  open(
    proofType?: ProofType,
  ) {
    const modalRef = this.modalService.open(
      ProofTypeModalComponent
    );

    modalRef.componentInstance.proofType = proofType;

  }

}
