<div class="modal-header">
  <h4 class="modal-title">
    {{ announcement?.title }}
  </h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
</div>
<div class="modal-body">
 <div class="container-fluid">
  <div class="row">

    @if(announcement?.files!.length > 0) {

      <div class="col-12 mb-4">
        <div class="row">
  
          @if(announcement?.files![0].fileType == 'image') {
  
            @for (item of announcement?.files; track $index) {
  
              <div class="col-12 col-md-6 mb-1">
  
                <p-image 
                  [src]="item.urlFile"
                  alt="Image" 
                  width="100%" 
                  [preview]="true" />
    
              </div>
  
            }
  
          } @else {
            <div class="col-12 my-3">
              <a
                class="btn btn-outline-primary w-100"
                [href]="announcement?.files![0].urlFile"
                target="_blank">
                Ver documento
              </a>
            </div>
          }
  
        </div>
      </div>

    }

    <div class="col-12">
      {{ announcement?.content }}
    </div>

  </div>
 </div>
</div>
<div class="modal-footer">
  <div class="col-12 my-3">
    <button
      (click)="activeModal.close()"
      type="button"
      class="btn btn-primary w-100">
      OK
    </button>
  </div>
</div>