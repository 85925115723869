import { Component, Input } from '@angular/core';
import { AccessDoor } from '../../models/access-door.model';
import { AccessDoorGroup } from '../../models/access-door-group.model';
import { Gate } from '../../models/gate.model';
import { DataFormModel } from '../../models/data-form-model.model';
import { FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AccessDoorService } from '../../services/access-door.service';
import { UtilService } from '../../services/util.service';

@Component({
  selector: 'app-access-door-modal',
  templateUrl: './access-door-modal.component.html',
  styleUrl: './access-door-modal.component.scss'
})
export class AccessDoorModalComponent {

  @Input() gate?: Gate;
  @Input() accessDoorGroup?: AccessDoorGroup;
  @Input() accessDoor?: AccessDoor;

  public dataForm: DataFormModel = {
    type: {
      value: true,
      type: 'switch',
      label: '¿Entrada o salida?',
      requiredMessage: '',
      required: false,
      validators: []
    },
    label: {
      value: '',
      type: 'text',
      label: 'Nombre de la puerta',
      requiredMessage: 'Por favor, ingrese un nombre para la puerta',
      required: true,
      validators: [Validators.required]
    },
    firestoreUid: {
      value: '',
      type: 'text',
      label: 'Firestore UID',
      required: false,
      validators: []
    },
    status: {
      value: true,
      type: 'switch',
      label: 'Mostrar',
      requiredMessage: '',
      required: false,
      validators: []
    }
  };

  public formAccessDoor!: FormGroup;
  public submitted: boolean = false;
  public loading: boolean = false;

  constructor(
    public activeModal: NgbActiveModal,
    public accessDoorService: AccessDoorService,
    public utilService: UtilService
  ) {}

  ngOnInit(): void {
    this.loadData();
  }

  get f() {
    return this.formAccessDoor.controls;
  }

  async loadData() {

    this.loading = true;

    
    if (this.accessDoor) {
      this.dataForm = {
        ...this.dataForm
      };
      
      for (let key of Object.keys(this.dataForm)) {
        if (key in this.accessDoor) {
          const standardKey = key as keyof typeof this.accessDoor;
          this.dataForm[key].value = this.accessDoor[standardKey]!;
        }
      }
    }
    
    this.formAccessDoor = this.utilService.buildForm(this.dataForm);
    this.loading = false;
  }

  async onSubmit() {
    this.submitted = true;

    if (this.formAccessDoor.invalid) {
      return;
    }

    if (this.accessDoor) {
      await this.update();
    } else {
      await this.create();
    }
  }

  async create() {
    const flag = await this.accessDoorService.create(
      this.gate!,
      this.accessDoorGroup!,
      this.formAccessDoor.value
    );

    if (flag) {
      for (let key of Object.keys(this.dataForm)) {
        this.dataForm[key].value = '';
      }
      this.activeModal.close();
    }
  }

  async update() {
    this.formAccessDoor.value.accessDoorGroupId = this.accessDoor!.accessDoorGroupId;
    this.formAccessDoor.value.accessDoorId = this.accessDoor!.accessDoorId;

    const flag = await this.accessDoorService.update(
      this.gate!,
      this.accessDoorGroup!,
      this.formAccessDoor.value
    );

    if (flag) {
      for (let key of Object.keys(this.dataForm)) {
        this.dataForm[key].value = '';
      }
      this.activeModal.close();
    }
  }

}
