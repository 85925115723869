import { Component } from '@angular/core';
import { ModuleService } from '../../../../services/module.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Module } from '../../../../models/module.model';
import { ModuleModalComponent } from '../../../../components/module-modal/module-modal.component';

@Component({
  selector: 'app-modules-page',
  templateUrl: './modules-page.component.html',
  styleUrl: './modules-page.component.scss'
})
export class ModulesPageComponent {

  constructor(
    public moduleService: ModuleService,
    private modalService: NgbModal,
  ) {

  }

  /*
  ██████╗ ███████╗██╗     ███████╗████████╗███████╗
  ██╔══██╗██╔════╝██║     ██╔════╝╚══██╔══╝██╔════╝
  ██║  ██║█████╗  ██║     █████╗     ██║   █████╗
  ██║  ██║██╔══╝  ██║     ██╔══╝     ██║   ██╔══╝
  ██████╔╝███████╗███████╗███████╗   ██║   ███████╗
  ╚═════╝ ╚══════╝╚══════╝╚══════╝   ╚═╝   ╚══════╝

  */
  async delete(
    module: Module
  ) {

    const flag = confirm(
      `¿Esta seguro que desea borrar el módulo ${module.description}?`
    );

    if(flag) {
      await this.moduleService.delete(
        module
      );
    }

  }

  /*
   ██████╗ ██████╗ ███████╗███╗   ██╗
  ██╔═══██╗██╔══██╗██╔════╝████╗  ██║
  ██║   ██║██████╔╝█████╗  ██╔██╗ ██║
  ██║   ██║██╔═══╝ ██╔══╝  ██║╚██╗██║
  ╚██████╔╝██║     ███████╗██║ ╚████║
   ╚═════╝ ╚═╝     ╚══════╝╚═╝  ╚═══╝

  */
  open(
    module?: Module,
    moduleId?: string
  ) {
    const modalRef = this.modalService.open(
      ModuleModalComponent
    );

    modalRef.componentInstance.module = module;
    modalRef.componentInstance.moduleId = moduleId;

  }

}
