import { Injectable } from '@angular/core';
import { IParcelService, ParcelService } from '../models/parcel-service.model';
import { AuthService } from './auth.service';
import { HttpClient } from '@angular/common/http';
import { SpinnerService } from './spinner.service';
import { ToastService } from './toast.service';
import { environment } from '../../environments/environment';
import { IServerResponse, ServerResponse } from '../models/server-response.model';

@Injectable({
  providedIn: 'root'
})
export class ParcelServiceService {

  public loading: boolean = false;
  public parcelServices: ParcelService[] = [];

  constructor(
    public authService: AuthService,
    private http: HttpClient,
    public spinnerService: SpinnerService,
    public toastService: ToastService
  ) {
    this.getAll();
  }

  /*
   ██████╗██████╗ ███████╗ █████╗ ████████╗███████╗
  ██╔════╝██╔══██╗██╔════╝██╔══██╗╚══██╔══╝██╔════╝
  ██║     ██████╔╝█████╗  ███████║   ██║   █████╗
  ██║     ██╔══██╗██╔══╝  ██╔══██║   ██║   ██╔══╝
  ╚██████╗██║  ██║███████╗██║  ██║   ██║   ███████╗
   ╚═════╝╚═╝  ╚═╝╚══════╝╚═╝  ╚═╝   ╚═╝   ╚══════╝

  */
  async create(
    parcelService: IParcelService
  ): Promise<boolean> {
    let flag = false;
    const urlApi = `${environment.urlApi}/parcel-services`;

    this.spinnerService.loading = true;
    this.spinnerService.message = 'Registrando servicio de paquetería';

    try {
      const token = await this.authService.getToken();
      const response = await new Promise<ServerResponse>(
        (resolve, reject) => {
          this.http.post<IServerResponse>(
            urlApi,
            JSON.stringify(parcelService),
            {
              headers: {
                "content-type": "application/json",
                'Authorization': token.toString()
              }
            }
          ).subscribe({
            next: (response: IServerResponse) => resolve(new ServerResponse(response)),
            error: (error) => {
              if (error.error) resolve(new ServerResponse(error.error));
              else {

                this.toastService.show({
                  header: 'Error de servidor',
                  body: `${error}`,
                  classname: 'bg-danger text-white'
                });

                reject(error)
              };
            },
          });
        }
      );

      if (response.statusCode === 201) {
        flag = true;
        this.toastService.show({ body: `${response.message}`, classname: 'bg-success text-white' });
        this.getAll();
      } else {
        this.toastService.show({ header: 'Error de servidor', body: `${response.message}`, classname: 'bg-danger text-white' });
      }

    } catch (error) {
      console.log(error);
      this.toastService.show({ header: 'Error', body: `${error}`, classname: 'bg-danger text-white' });
    }

    this.spinnerService.loading = false;
    return flag;
  }

  async delete(parcelService: ParcelService): Promise<boolean> {
    let flag = false;
    const urlApi = `${environment.urlApi}/parcel-services/${parcelService.parcelServiceId}`;

    this.spinnerService.loading = true;
    this.spinnerService.message = 'Eliminando servicio de paquetería';

    try {
      const token = await this.authService.getToken();
      const response = await new Promise<ServerResponse>(
        (resolve, reject) => {
          this.http.delete<IServerResponse>(
            urlApi,
            { headers: { 'Authorization': token.toString() } }
          ).subscribe({
            next: (response: IServerResponse) => resolve(new ServerResponse(response)),
            error: (error) => {
              if (error.error) resolve(new ServerResponse(error.error));
              else reject(error);
            },
          });
        }
      );

      if (response.statusCode === 200) {
        flag = true;
        this.toastService.show({ body: `${response.message}`, classname: 'bg-success text-white' });
        this.getAll();
      } else {
        this.toastService.show({ header: 'Error de servidor', body: `${response.message}`, classname: 'bg-danger text-white' });
      }

    } catch (error) {
      console.log(error);
      this.toastService.show({ header: 'Error', body: `${error}`, classname: 'bg-danger text-white' });
    }

    this.spinnerService.loading = false;
    return flag;
  }

  async get(parcelServiceId: string): Promise<ParcelService | null> {
    let parcelService: ParcelService | null = null;
    const urlApi = `${environment.urlApi}/parcel-services/${parcelServiceId}`;

    this.spinnerService.loading = true;
    this.spinnerService.message = 'Obteniendo servicio de paquetería';

    try {
      const token = await this.authService.getToken();
      const response = await new Promise<ServerResponse<{ parcelService: IParcelService }>>(
        (resolve, reject) => {
          this.http.get<IServerResponse<{ parcelService: IParcelService }>>(
            urlApi,
            { headers: { "content-type": "application/json", "Authorization": token.toString() } }
          ).subscribe({
            next: (response: IServerResponse<{ parcelService: IParcelService }>) => resolve(new ServerResponse(response)),
            error: (error) => {
              if (error.error) resolve(new ServerResponse(error.error));
              else reject(error);
            },
          });
        }
      );

      if (response.statusCode === 200) {
        parcelService = new ParcelService(response.data!.parcelService);
      } else {
        this.toastService.show({ header: 'Error de servidor', body: `${response.message}`, classname: 'bg-danger text-white' });
      }

    } catch (error) {
      console.log(error);
    }

    this.spinnerService.loading = false;
    return parcelService;
  }

  async getAll() {
    this.loading = true;
    const urlApi = `${environment.urlApi}/parcel-services`;

    try {
      const token = await this.authService.getToken();
      const response = await new Promise<ServerResponse<{ parcelServices: IParcelService[] }>>(
        (resolve, reject) => {
          this.http.get<IServerResponse<{ parcelServices: IParcelService[] }>>(
            urlApi,
            { headers: { "content-type": "application/json", "Authorization": token.toString() } }
          ).subscribe({
            next: (response: IServerResponse<{ parcelServices: IParcelService[] }>) => resolve(new ServerResponse(response)),
            error: (error) => {
              if (error.error) resolve(new ServerResponse(error.error));
              else reject(error);
            },
          });
        }
      );

      if (response.statusCode === 200) {
        this.parcelServices = response.data!.parcelServices.map(
          parcelService => new ParcelService(parcelService)
        );
      } else {
        this.toastService.show({ header: 'Error de servidor', body: `${response.message}`, classname: 'bg-danger text-white' });
      }

    } catch (error) {
      console.log(error);
    }

    this.loading = false;
  }

  async update(parcelService: any): Promise<boolean> {
    let flag = false;
    const urlApi = `${environment.urlApi}/parcel-services/${parcelService.parcelServiceId}`;

    this.spinnerService.loading = true;
    this.spinnerService.message = 'Actualizando servicio de paquetería';

    try {
      const token = await this.authService.getToken();
      const response = await new Promise<ServerResponse>(
        (resolve, reject) => {
          this.http.put<IServerResponse>(
            urlApi,
            JSON.stringify(parcelService),
            { headers: { "content-type": "application/json", 'Authorization': token.toString() } }
          ).subscribe({
            next: (response: IServerResponse) => resolve(new ServerResponse(response)),
            error: (error) => {
              if (error.error) resolve(new ServerResponse(error.error));
              else reject(error);
            },
          });
        }
      );

      if (response.statusCode === 200) {
        flag = true;
        this.toastService.show({ body: `${response.message}`, classname: 'bg-success text-white' });
        this.getAll();
      }

    } catch (error) {
      console.log(error);
    }

    this.spinnerService.loading = false;
    return flag;
  }
}
