import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { StreetBuildingService } from '../../../services/street-building.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-houses-flats-page',
  templateUrl: './houses-flats-page.component.html',
  styleUrl: './houses-flats-page.component.scss'
})
export class HousesFlatsPageComponent {

  public neighborhoodId?: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    public streetBuildingService: StreetBuildingService,
    private modalService: NgbModal,
  ) {

    this.activatedRoute.params.subscribe(
      async params => {

        this.neighborhoodId = params['neighborhoodId'];

        await this.streetBuildingService.getAll(
          this.neighborhoodId!
        );
      }
    );

  }

}
