import { Component, Input } from '@angular/core';
import { Module } from '../../models/module.model';
import { FormGroup, Validators } from '@angular/forms';
import { DataFormModel } from '../../models/data-form-model.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModuleService } from '../../services/module.service';
import { UtilService } from '../../services/util.service';

@Component({
  selector: 'app-module-modal',
  templateUrl: './module-modal.component.html',
  styleUrl: './module-modal.component.scss'
})
export class ModuleModalComponent {
  
  @Input() module?: Module;
  @Input() moduleId?: string;

  public dataForm: DataFormModel = {
    superModuleId: {
      value: null,
      type: 'select',
      label: 'Módulo padre',
      idSelect: 'moduleId',
      labelSelect: 'description',
      required: false,
      placeholder: 'Seleccione un módulo padre',
    },
    description: {
      value: '',
      type: 'text',
      label: 'Descripción',
      requiredMessage: 'Por favor, ingrese el nombre del módulo',
      required: true,
      validators: [Validators.required]
    },
    slug: {
      value: '',
      type: 'text',
      label: 'Slug',
      requiredMessage: 'Por favor, ingrese el slug del módulo',
      required: true,
      validators: [Validators.required]
    },
  };

  public submitted: boolean = false;
  public loading: boolean = false;

  public formModel!: FormGroup;

  constructor(
    public activeModal: NgbActiveModal,
    public moduleService: ModuleService,
    public utilService: UtilService,
  ) {}

  ngOnInit(): void {
    this.loadData();
  }

  get f() {
    return this.formModel.controls;
  }


  /*
   ██████╗██████╗ ███████╗ █████╗ ████████╗███████╗
  ██╔════╝██╔══██╗██╔════╝██╔══██╗╚══██╔══╝██╔════╝
  ██║     ██████╔╝█████╗  ███████║   ██║   █████╗
  ██║     ██╔══██╗██╔══╝  ██╔══██║   ██║   ██╔══╝
  ╚██████╗██║  ██║███████╗██║  ██║   ██║   ███████╗
   ╚═════╝╚═╝  ╚═╝╚══════╝╚═╝  ╚═╝   ╚═╝   ╚══════╝

  */
   async create() {

    const flag = await this.moduleService.create(
      this.formModel.value,
    );

    if(flag) {
      for(let key of Object.keys(this.dataForm)) {
        this.dataForm[key].value = '';
      }

      this.activeModal.close();
    }

  }

  /*
  ██╗      ██████╗  █████╗ ██████╗ ██████╗  █████╗ ████████╗ █████╗
  ██║     ██╔═══██╗██╔══██╗██╔══██╗██╔══██╗██╔══██╗╚══██╔══╝██╔══██╗
  ██║     ██║   ██║███████║██║  ██║██║  ██║███████║   ██║   ███████║
  ██║     ██║   ██║██╔══██║██║  ██║██║  ██║██╔══██║   ██║   ██╔══██║
  ███████╗╚██████╔╝██║  ██║██████╔╝██████╔╝██║  ██║   ██║   ██║  ██║
  ╚══════╝ ╚═════╝ ╚═╝  ╚═╝╚═════╝ ╚═════╝ ╚═╝  ╚═╝   ╚═╝   ╚═╝  ╚═╝

  */
  async loadData() {
    this.loading = true;

    if(this.module) {

      this.dataForm = {
        ...this.dataForm,
      }

      for(let key of Object.keys(this.dataForm)) {

        if (key in this.module!) {
          const standardKey = key as keyof typeof this.module;
      
          // Ahora puedes acceder a `this.machine[machineKey]` sin errores de tipo.
          this.dataForm[key].value = this.module[standardKey]!;
        }
      } 
    }

    this.dataForm['superModuleId'].data = this.moduleService.modules;

    if(this.moduleId) {
      this.dataForm['superModuleId'].value = this.moduleId;
    }
    
    this.formModel = this.utilService.buildForm(this.dataForm);

    this.loading = false;
  }

  /*
   ██████╗ ███╗   ██╗███████╗██╗   ██╗██████╗ ███╗   ███╗██╗████████╗
  ██╔═══██╗████╗  ██║██╔════╝██║   ██║██╔══██╗████╗ ████║██║╚══██╔══╝
  ██║   ██║██╔██╗ ██║███████╗██║   ██║██████╔╝██╔████╔██║██║   ██║
  ██║   ██║██║╚██╗██║╚════██║██║   ██║██╔══██╗██║╚██╔╝██║██║   ██║
  ╚██████╔╝██║ ╚████║███████║╚██████╔╝██████╔╝██║ ╚═╝ ██║██║   ██║
   ╚═════╝ ╚═╝  ╚═══╝╚══════╝ ╚═════╝ ╚═════╝ ╚═╝     ╚═╝╚═╝   ╚═╝

  */
  async onSubmit() {

    console.log(this.formModel.value);
    
    this.submitted = true;

    if(this.formModel.invalid) {
      return;
    }

    if(this.module) {

      await this.update();
    } else {
      await this.create();
    }
  }

  /*
  ██╗   ██╗██████╗ ██████╗  █████╗ ████████╗███████╗
  ██║   ██║██╔══██╗██╔══██╗██╔══██╗╚══██╔══╝██╔════╝
  ██║   ██║██████╔╝██║  ██║███████║   ██║   █████╗
  ██║   ██║██╔═══╝ ██║  ██║██╔══██║   ██║   ██╔══╝
  ╚██████╔╝██║     ██████╔╝██║  ██║   ██║   ███████╗
   ╚═════╝ ╚═╝     ╚═════╝ ╚═╝  ╚═╝   ╚═╝   ╚══════╝

  */
  async update() {

    this.formModel.value.moduleId = this.module!.moduleId;

    const flag = await this.moduleService.update(
      this.formModel.value
    );

    if(flag) {

      for(let key of Object.keys(this.dataForm)) {
        this.dataForm[key].value = '';
      }

      this.activeModal.close();
    }
  }

}
