import { Injectable } from '@angular/core';
import { AccessDoorGroup, IAccessDoorGroup } from '../models/access-door-group.model';
import { AuthService } from './auth.service';
import { HttpClient } from '@angular/common/http';
import { SpinnerService } from './spinner.service';
import { ToastService } from './toast.service';
import { Gate } from '../models/gate.model';
import { environment } from '../../environments/environment';
import { IServerResponse, ServerResponse } from '../models/server-response.model';

@Injectable({
  providedIn: 'root'
})
export class AccessDoorGroupService {

  public loading: boolean = false;
  public accessDoorGroups: AccessDoorGroup[] = [];

  constructor(
    public authService: AuthService,
    private http: HttpClient,
    public spinnerService: SpinnerService,
    public toastService: ToastService
  ) { }

  async create(
    gate: Gate,
    accessDoorGroup: IAccessDoorGroup
  ): Promise<boolean> {
    
    let flag = false;
    const urlApi = `${environment.urlApi}/neighborhoods/${gate.neighborhoodId}/gates/${gate.gateId}/access-door-groups`;

    this.spinnerService.loading = true;
    this.spinnerService.message = 'Registrando grupo de puerta de acceso';

    try {
      const token = await this.authService.getToken();

      const response = await new Promise<ServerResponse>(
        (resolve, reject) => {
          this.http.post<IServerResponse>(
            urlApi,
            JSON.stringify(accessDoorGroup),
            { 
              headers: { 
                "content-type": "application/json", 
                "Authorization": token.toString() 
              } 
            }
          ).subscribe({
            next: (
              response: IServerResponse
            ) => resolve(new ServerResponse(response)),
            error: (error) => {
              if (error.error) {
                resolve(new ServerResponse(error.error));
              } else {
                reject(error);
                this.toastService.show({
                  header: 'Error de servidor',
                  body: `${error}`,
                  classname: 'bg-danger text-white'
                });
              }
            }
          });
        }
      );

      if (response.statusCode === 201) {
        flag = true;
        this.toastService.show({
          body: `${response.message}`,
          classname: 'bg-success text-white'
        });
        this.getAll(
          gate.neighborhoodId,
          gate.gateId
        );
      } else {
        this.toastService.show({
          header: 'Error de servidor',
          body: `${response.message}`,
          classname: 'bg-danger text-white'
        });
      }

    } catch (error) {
      console.log(error);
      this.toastService.show({
        header: 'Error',
        body: `${error}`,
        classname: 'bg-danger text-white'
      });
    }

    this.spinnerService.loading = false;
    this.spinnerService.message = '';
    return flag;
  }

  async delete(
    gate: Gate,
    accessDoorGroup: AccessDoorGroup
  ): Promise<boolean> {
    
    let flag = false;
    const urlApi = `${environment.urlApi}/neighborhoods/${gate.neighborhoodId}/gates/${gate.gateId}/access-door-groups/${accessDoorGroup.accessDoorGroupId}`;

    this.spinnerService.loading = true;
    this.spinnerService.message = 'Eliminando grupo de puerta de acceso';

    try {
      const token = await this.authService.getToken();

      const response = await new Promise<ServerResponse>(
        (resolve, reject) => {
          this.http.delete<IServerResponse>(
            urlApi,
            { 
              headers: { 
                "Authorization": token.toString() 
              } 
            }
          ).subscribe({
            next: (
              response: IServerResponse
            ) => resolve(new ServerResponse(response)),
            error: (error) => {
              if (error.error) {
                resolve(new ServerResponse(error.error));
              } else {
                reject(error);
                this.toastService.show({
                  header: 'Error de servidor',
                  body: `${error}`,
                  classname: 'bg-danger text-white'
                });
              }
            }
          });
        }
      );

      if (response.statusCode === 200) {
        flag = true;
        this.toastService.show({
          body: `${response.message}`,
          classname: 'bg-success text-white'
        });
        this.getAll(
          gate.neighborhoodId,
          gate.gateId
        );
      } else {
        this.toastService.show({
          header: 'Error de servidor',
          body: `${response.message}`,
          classname: 'bg-danger text-white'
        });
      }

    } catch (error) {
      console.log(error);
      this.toastService.show({
        header: 'Error',
        body: `${error}`,
        classname: 'bg-danger text-white'
      });
    }

    this.spinnerService.loading = false;
    this.spinnerService.message = '';
    return flag;
  }

  async get(
    gate: Gate,
    accessDoorGroupId: string
  ): Promise<AccessDoorGroup | null> {

    let accessDoorGroup: AccessDoorGroup | null = null;
    const urlApi = `${environment.urlApi}/neighborhoods/${gate.neighborhoodId}/gates/${gate.gateId}/access-door-groups/${accessDoorGroupId}`;

    this.spinnerService.loading = true;
    this.spinnerService.message = 'Obteniendo grupo de puerta de acceso';

    try {
      const token = await this.authService.getToken();

      const response = await new Promise<ServerResponse<{ accessDoorGroup: IAccessDoorGroup }>>(
        (resolve, reject) => {
          this.http.get<IServerResponse<{ accessDoorGroup: IAccessDoorGroup }>>(
            urlApi,
            { 
              headers: { 
                "content-type": "application/json", 
                "Authorization": token.toString() 
              } 
            }
          ).subscribe({
            next: (
              response: IServerResponse<{ accessDoorGroup: IAccessDoorGroup }>
            ) => resolve(new ServerResponse<{ accessDoorGroup: IAccessDoorGroup }>(response)),
            error: (error) => {
              if (error.error) {
                resolve(new ServerResponse(error.error));
              } else {
                reject(error);
                this.toastService.show({
                  header: 'Error de servidor',
                  body: `${error}`,
                  classname: 'bg-danger text-white'
                });
              }
            }
          });
        }
      );

      if (response.statusCode === 200) {
        accessDoorGroup = new AccessDoorGroup(response.data!.accessDoorGroup);
      } else {
        this.toastService.show({
          header: 'Error de servidor',
          body: `${response.message}`,
          classname: 'bg-danger text-white'
        });
      }

    } catch (error) {
      console.log(error);
      this.toastService.show({
        header: 'Error',
        body: `${error}`,
        classname: 'bg-danger text-white'
      });
    }

    this.spinnerService.loading = false;
    this.spinnerService.message = '';
    return accessDoorGroup;
  }

  async getAll(
    neighborhoodId: string,
    gateId: string
  ) {
    this.loading = true;
    this.accessDoorGroups = [];

    const urlApi = `${environment.urlApi}/neighborhoods/${neighborhoodId}/gates/${gateId}/access-door-groups`;

    try {
      const token = await this.authService.getToken();

      const response = await new Promise<ServerResponse<{ accessDoorGroups: IAccessDoorGroup[] }>>(
        (resolve, reject) => {
          this.http.get<IServerResponse<{ accessDoorGroups: IAccessDoorGroup[] }>>(
            urlApi,
            { 
              headers: { 
                "content-type": "application/json", 
                "Authorization": token.toString() 
              } 
            }
          ).subscribe({
            next: (
              response: IServerResponse<{ accessDoorGroups: IAccessDoorGroup[] }>
            ) => resolve(new ServerResponse<{ accessDoorGroups: IAccessDoorGroup[] }>(response)),
            error: (error) => {
              if (error.error) {
                resolve(new ServerResponse(error.error));
              } else {
                reject(error);
                this.toastService.show({
                  header: 'Error de servidor',
                  body: `${error}`,
                  classname: 'bg-danger text-white'
                });
              }
            }
          });
        }
      );

      if (response.statusCode === 200) {
        this.accessDoorGroups = response.data!.accessDoorGroups.map(
          accessDoorGroup => new AccessDoorGroup(accessDoorGroup)
        );
      } else {
        this.toastService.show({
          header: 'Error de servidor',
          body: `${response.message}`,
          classname: 'bg-danger text-white'
        });
      }

    } catch (error) {
      console.log(error);
      this.toastService.show({
        header: 'Error',
        body: `${error}`,
        classname: 'bg-danger text-white'
      });
    }

    this.loading = false;
  }

  async update(
    gate: Gate,
    accessDoorGroup: AccessDoorGroup
  ): Promise<boolean> {

    let flag = false;
    const urlApi = `${environment.urlApi}/neighborhoods/${gate.neighborhoodId}/gates/${gate.gateId}/access-door-groups/${accessDoorGroup.accessDoorGroupId}`;

    this.spinnerService.loading = true;
    this.spinnerService.message = 'Actualizando grupo de puerta de acceso';

    try {
      const token = await this.authService.getToken();

      const response = await new Promise<ServerResponse>(
        (resolve, reject) => {
          this.http.put<IServerResponse>(
            urlApi,
            JSON.stringify(accessDoorGroup),
            { headers: { "content-type": "application/json", "Authorization": token.toString() } }
          ).subscribe({
            next: (
              response: IServerResponse<{ accessDoorGroups: IAccessDoorGroup[] }>
            ) => resolve(new ServerResponse<{ accessDoorGroups: IAccessDoorGroup[] }>(response)),
            error: (error) => {
              if (error.error) {
                resolve(new ServerResponse(error.error));
              } else {
                reject(error);
                this.toastService.show({
                  header: 'Error de servidor',
                  body: `${error}`,
                  classname: 'bg-danger text-white'
                });
              }
            }
          });
        }
      );

      if (response.statusCode === 200) {
        flag = true;
        this.getAll(
          gate.neighborhoodId,
          gate.gateId
        );
      }

    } catch (error) {
      console.log(error);
      this.toastService.show({
        header: 'Error',
        body: `${error}`,
        classname: 'bg-danger text-white'
      });
    }

    this.spinnerService.loading = false;
    return flag;
  }
}
