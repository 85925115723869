import { Component } from '@angular/core';
import { Auth, signInWithEmailAndPassword } from '@angular/fire/auth';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-log-in-page',
  templateUrl: './log-in-page.component.html',
  styleUrl: './log-in-page.component.scss'
})
export class LogInPageComponent {

  public formSignIn: FormGroup;
  public submitted: boolean = false;
  public visibilityPass: boolean = false;
  public loading: boolean = false;

  constructor(
    public auth: Auth,
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private router: Router,
    private titleService: Title
  ) {
    this.formSignIn = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required],
    });

    this.titleService.setTitle(`Iniciar sesión`);
  }

  get formControls() {
    return this.formSignIn.controls;
  }

  /*
  ███████╗██╗ ██████╗ ███╗   ██╗██╗███╗   ██╗
  ██╔════╝██║██╔════╝ ████╗  ██║██║████╗  ██║
  ███████╗██║██║  ███╗██╔██╗ ██║██║██╔██╗ ██║
  ╚════██║██║██║   ██║██║╚██╗██║██║██║╚██╗██║
  ███████║██║╚██████╔╝██║ ╚████║██║██║ ╚████║
  ╚══════╝╚═╝ ╚═════╝ ╚═╝  ╚═══╝╚═╝╚═╝  ╚═══╝

  */
  async signIn() {
    this.submitted = true;
    this.loading = true;
    if (this.formSignIn.invalid) {
      this.loading = false;
      return;
    }

    try {
      const user = await signInWithEmailAndPassword(
        this.auth,
        this.formSignIn.value.email.trim(),
        this.formSignIn.value.password.trim()
      );

      // console.log(user.user);
      const token = await user.user.getIdToken();
      // console.log(token);

      sessionStorage.setItem('user', JSON.stringify(user.user));
      this.authService.currentFirebaseUser = user.user;
      this.authService.loggedIn = true;
      this.router.navigate(['/dashboard']);
    } catch (e: any) {
      this.loading = false;
      this.authService.signOut();
      alert("Correo electrónico o contraseña incorrecta");
    }
  }

}
