import { Component } from '@angular/core';
import { VisitTypeService } from '../../../services/visit-type.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { VisitType } from '../../../models/visit-type.model';
import { VisitTypeModalComponent } from '../../../components/visit-type-modal/visit-type-modal.component';
import { SpinnerService } from '../../../services/spinner.service';

@Component({
  selector: 'app-visit-types-page',
  templateUrl: './visit-types-page.component.html',
  styleUrl: './visit-types-page.component.scss'
})
export class VisitTypesPageComponent {

  constructor(
    public visitTypeService: VisitTypeService,
    private modalService: NgbModal,
    public spinnerService: SpinnerService,
  ) {

  }

  /*
  ██████╗ ███████╗██╗     ███████╗████████╗███████╗
  ██╔══██╗██╔════╝██║     ██╔════╝╚══██╔══╝██╔════╝
  ██║  ██║█████╗  ██║     █████╗     ██║   █████╗
  ██║  ██║██╔══╝  ██║     ██╔══╝     ██║   ██╔══╝
  ██████╔╝███████╗███████╗███████╗   ██║   ███████╗
  ╚═════╝ ╚══════╝╚══════╝╚══════╝   ╚═╝   ╚══════╝

  */
  async delete(
    visitType: VisitType
  ) {

    const flag = confirm(
      `¿Esta seguro que desea borrar el tipo de colonia ${visitType.description}?`
    );

    if(flag) {
      await this.visitTypeService.delete(
        visitType
      );
    }

  }

  /*
   ██████╗ ██████╗ ███████╗███╗   ██╗
  ██╔═══██╗██╔══██╗██╔════╝████╗  ██║
  ██║   ██║██████╔╝█████╗  ██╔██╗ ██║
  ██║   ██║██╔═══╝ ██╔══╝  ██║╚██╗██║
  ╚██████╔╝██║     ███████╗██║ ╚████║
   ╚═════╝ ╚═╝     ╚══════╝╚═╝  ╚═══╝

  */
  open(
    visitType?: VisitType,
  ) {
    const modalRef = this.modalService.open(
      VisitTypeModalComponent
    );

    modalRef.componentInstance.visitType = visitType;

  }

  /*
  ██╗   ██╗██████╗ ██████╗  █████╗ ████████╗███████╗
  ██║   ██║██╔══██╗██╔══██╗██╔══██╗╚══██╔══╝██╔════╝
  ██║   ██║██████╔╝██║  ██║███████║   ██║   █████╗
  ██║   ██║██╔═══╝ ██║  ██║██╔══██║   ██║   ██╔══╝
  ╚██████╔╝██║     ██████╔╝██║  ██║   ██║   ███████╗
   ╚═════╝ ╚═╝     ╚═════╝ ╚═╝  ╚═╝   ╚═╝   ╚══════╝

  */
  /**
   * FUNCTION update
   * 
   * @param visitType 
   */
  async update(
    visitType: VisitType
  ) {

    console.log(visitType);
    

    this.spinnerService.loading = true;
    this.spinnerService.message = 'Actualizando tipo de visita';

    await this.visitTypeService.update(
      visitType
    );

  }

}
