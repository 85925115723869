@if(!loading) {

  <div class="modal-header">
    <div class="me-3">
      @if(currentVisit?.service) {
        <img
          [src]="currentVisit!.service?.image?.urlFile"
          [alt]="currentVisit!.service?.service"
          style="width: calc(1.5vh + 1.5rem);">
      } @else {
        <img
          [src]="currentVisit?.visitType?.image?.urlFile"
          [alt]="currentVisit?.visitType?.description"
          style="width: calc(1.5vh + 1.5rem);">
      }
    </div>
  
    <div>
      <h4 class="modal-title">
        {{ currentVisit?.houseFlat?.streetBuilding?.name }} {{ currentVisit?.houseFlat?.number }}
      </h4>
      <small>
        @if(currentVisit?.service) {
          {{ currentVisit!.service?.service }} {{ currentVisit?.fullName }}
        } @else {
          {{ currentVisit?.fullName }}
        }
        <br>
        <span class="text-secondary">
          {{ currentVisit?.createdAt | dateToMoment: 'DD MMM YYYY HH:mm' : neighborhoodService.currentNeighborhood?.timeZone }}
        </span>
      </small>
  
    </div>
    <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
  </div>

}

<div class="modal-body">
  <div class="container-fluid">
    <div class="row">

      @if(loading) {

        <div class="d-flex justify-content-center">
          <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>

      }

      @if(!loading) {

        @for (proof of currentVisit?.proofs; track $index) {

          <div class="col-12 col-md-6 mb-4">
            <!-- <img
              [src]="proof.urlFile"
              [alt]="proof.fileId"
              class="w-100"> -->
  
            <div class="row">
  
              <div class="col-12 mb-2">
                <p-image 
                  [src]="proof.file?.urlFile"
                  [alt]="proof.fileId"
                  width="100%" 
                  [preview]="true" />
              </div>
  
              <div class="col-12 text-secondary">
                <small>
                  {{ proof.createdAt | dateToMoment: 'DD MMM YYYY HH:mm' : neighborhoodService.currentNeighborhood?.timeZone }}
                </small>
              </div>
  
            </div>
          </div>
  
        }
  
        <div class="col-12 text-secondary mb-1">
          <small><b>Información de la visita</b></small>
        </div>
  
        <div class="col-12 rounded rounded-3 bg-light py-2 px-3 mb-3">
          {{ currentVisit?.fullName }}
        </div>
  
        @if(currentVisit?.description != '') {
          <div class="col-12 text-secondary mb-1">
            <small><b>Descripción</b></small>
          </div>
  
          <div class="col-12 rounded rounded-3 bg-light py-2 px-3 mb-3">
            {{ currentVisit?.description }}
          </div>
        }

        @if(currentVisit?.service) {
          <div class="col-12 text-secondary mb-1">
            <small><b>Servicio</b></small>
          </div>
  
          <div class="col-12 rounded rounded-3 bg-light py-2 px-3 mb-3">
            {{ currentVisit?.service?.service }}
          </div>
        }
  
        @if(currentVisit?.isVehicle) {
          <div class="col-12 text-secondary mb-1">
            <small><b>Información vehiclar</b></small>
          </div>
  
          <div class="col-12 rounded rounded-3 bg-light py-2 px-3 mb-3">
            <div class="row">
  
              <div class="col-6 col-md-4">
                <div class="row">
                  <div class="col-12 text-secondary">
                    <small><b>Viene en moto</b></small>
                  </div>
                  <div class="col-12">
                    {{ currentVisit?.isMotorcycle ? 'Sí' : 'No' }}
                  </div>
                </div>
              </div>

              @if(currentVisit!.passengersNumber > 0) {
                <div class="col-6 col-md-4">
                  <div class="row">
                    <div class="col-12 text-secondary">
                      <small><b>No. pasajeros</b></small>
                    </div>
                    <div class="col-12">
                      {{ currentVisit?.passengersNumber }}
                    </div>
                  </div>
                </div>
              }
  
              @if(currentVisit?.plates != '') {
  
                <div class="col-6 col-md-4">
                  <div class="row">
                    <div class="col-12 text-secondary">
                      <small><b>Placas</b></small>
                    </div>
                    <div class="col-12">
                      {{ currentVisit?.plates }}
                    </div>
                  </div>
                </div>
  
              }
  
              @if(currentVisit?.brand != '') {
  
                <div class="col-6 col-md-4">
                  <div class="row">
                    <div class="col-12 text-secondary">
                      <small><b>Marca</b></small>
                    </div>
                    <div class="col-12">
                      {{ currentVisit?.brand }}
                    </div>
                  </div>
                </div>
  
              }
  
              @if(currentVisit?.model != '') {
  
                <div class="col-6 col-md-4">
                  <div class="row">
                    <div class="col-12 text-secondary">
                      <small><b>Modelo</b></small>
                    </div>
                    <div class="col-12">
                      {{ currentVisit?.model }}
                    </div>
                  </div>
                </div>
  
              }
  
              @if(currentVisit?.color != '') {
  
                <div class="col-6 col-md-4">
                  <div class="row">
                    <div class="col-12 text-secondary">
                      <small><b>Color</b></small>
                    </div>
                    <div class="col-12">
                      {{ currentVisit?.color }}
                    </div>
                  </div>
                </div>
  
              }
  
            </div>
          </div>
        }

        <div class="col-12 text-secondary mb-1">
          <small><b>Bitácora</b></small>
        </div>

        <div class="col-12 rounded rounded-3 bg-light py-2 px-3 mb-3">
          <div class="row">

            <div class="col-6 col-md-4 col-md-4">
              <div class="row">
                <div class="col-12 text-secondary">
                  <small><b>Pase creado por</b></small>
                </div>
                <div class="col-12">
                  {{ currentVisit?.createdBy?.fullName }} ( {{ currentVisit?.createdBy?.email != '' ? currentVisit?.createdBy?.email : currentVisit?.createdBy?.username }} )
                </div>
              </div>
            </div>

            <div class="col-6 col-md-4 col-md-4">
              <div class="row">
                <div class="col-12 text-secondary">
                  <small><b>Fecha de llegada</b></small>
                </div>
                <div class="col-12">
                  {{ currentVisit?.arrivedAt | dateToMoment: 'DD MMM YYYY HH:mm' : neighborhoodService.currentNeighborhood?.timeZone }}
                </div>
              </div>
            </div>

            <div class="col-6 col-md-4 col-md-4">
              <div class="row">
                <div class="col-12 text-secondary">
                  <small><b>Fecha de salida</b></small>
                </div>
                <div class="col-12">
                  {{ currentVisit?.departedAt | dateToMoment: 'DD MMM YYYY HH:mm' : neighborhoodService.currentNeighborhood?.timeZone }}
                </div>
              </div>
            </div>

          </div>
        </div>

        <div class="col-12 text-secondary mb-1">
          <small><b>Bitácora</b></small>
        </div>

        @for (inputOutput of currentVisit?.inputsOutputs; track $index) {

          <div class="col-12 mb-3 bg-light py-2 px-3 rounded rounded-3">
            <div class="row">
              <div class="col-auto">
                @if(inputOutput.status) {
                  <i class="material-icons text-success">north</i>
                } @else {
                  <i class="material-icons text-danger">south</i>
                }
              </div>

              <div class="col">
                <div class="row">

                  <div class="col-12">
                    @if (inputOutput.status) {
                      <span class="text-success">Entrada</span>
                    } @else {
                      <span class="text-danger">Salida</span>
                    }
                  </div>

                  <div class="col-12">
                    <small>
                      {{ inputOutput.createdBy?.fullName }}
                    </small>
                  </div>

                  <div class="col-12 text-secondary">
                    <small>
                      {{ inputOutput?.createdAt | dateToMoment: 'DD MMM YYYY HH:mm' : neighborhoodService.currentNeighborhood?.timeZone }}
                    </small>
                  </div>

                </div>
              </div>

            </div>
          </div>

        }

      }

    </div>
  </div>
</div>
<div class="modal-footer">
  <div class="col-12 my-3">
    <button
      (click)="activeModal.close()"
      type="button"
      class="btn btn-success w-100">
      OK
    </button>
  </div>
</div>