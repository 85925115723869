import { Injectable } from '@angular/core';
import { IModule, Module } from '../models/module.model';
import { AuthService } from './auth.service';
import { HttpClient } from '@angular/common/http';
import { SpinnerService } from './spinner.service';
import { ToastService } from './toast.service';
import { environment } from '../../environments/environment';
import { IServerResponse, ServerResponse } from '../models/server-response.model';

@Injectable({
  providedIn: 'root'
})
export class ModuleService {

  public loading: boolean = false;
  public modules: Module[] = [];

  constructor(
    public authService: AuthService,
    private http: HttpClient,
    public spinnerService: SpinnerService,
    public toastService: ToastService
  ) { 

    this.getAll();

  }

  /*
   ██████╗██████╗ ███████╗ █████╗ ████████╗███████╗
  ██╔════╝██╔══██╗██╔════╝██╔══██╗╚══██╔══╝██╔════╝
  ██║     ██████╔╝█████╗  ███████║   ██║   █████╗
  ██║     ██╔══██╗██╔══╝  ██╔══██║   ██║   ██╔══╝
  ╚██████╗██║  ██║███████╗██║  ██║   ██║   ███████╗
   ╚═════╝╚═╝  ╚═╝╚══════╝╚═╝  ╚═╝   ╚═╝   ╚══════╝

  */
   async create(
    module: IModule
  ): Promise<boolean> {

    let flag = false;

    const urlApi = `${environment.urlApi}/modules`;

    this.spinnerService.loading = true;
    this.spinnerService.message = 'Registrando módulo';

    try {

      const token = await this.authService.getToken();

      const response = await new Promise<ServerResponse>(
        (resolve, reject) => {
          this.http.post<IServerResponse>(
            urlApi,
            JSON.stringify(module),
            {
              headers: {
                "content-type": "application/json",
                'Authorization': token.toString()
              }
            }
          ).subscribe({
            next: (
                response: IServerResponse
              ) => resolve(
                new ServerResponse(response)
              ),
            error: (error) => {
              if (error.error) {
                resolve(new ServerResponse(error.error));
              } else {
                reject(error);
                this.toastService.show(
                  {
                    header: 'Error de servidor',
                    body: `${error}`,
                    classname: 'bg-danger text-white'
                  }
                );
              }
            },
          });
        }
      );

      console.log(response);

      if(response.statusCode === 201) {
        flag = true;
        this.getAll();
      } else {

        this.toastService.show(
          {
            header: 'Error de servidor',
            body: `${response.message}`,
            classname: 'bg-danger text-white'
          }
        );

      }

    } catch(error) {
      console.log(error);

      this.toastService.show(
        {
          header: 'Error',
          body: `${error}`,
          classname: 'bg-danger text-white'
        }
      );
    }

    this.spinnerService.loading = false;
    this.spinnerService.message = '';

    return flag;

  }

  /*
  ██████╗ ███████╗██╗     ███████╗████████╗███████╗
  ██╔══██╗██╔════╝██║     ██╔════╝╚══██╔══╝██╔════╝
  ██║  ██║█████╗  ██║     █████╗     ██║   █████╗
  ██║  ██║██╔══╝  ██║     ██╔══╝     ██║   ██╔══╝
  ██████╔╝███████╗███████╗███████╗   ██║   ███████╗
  ╚═════╝ ╚══════╝╚══════╝╚══════╝   ╚═╝   ╚══════╝

  */
  async delete(
    module: Module
  ): Promise<boolean> {

    let flag = false;

    const urlApi = `${environment.urlApi}/modules/${module.moduleId}`;

    this.spinnerService.loading = true;
    this.spinnerService.message = 'Eliminando módulo';

    try {

      const token = await this.authService.getToken();

      const response = await new Promise<ServerResponse>(
        (resolve, reject) => {
          this.http.delete<IServerResponse>(
            urlApi,
            {
              headers: {
                'Authorization': token.toString()
              }
            }
          ).subscribe({
            next: (
                response: IServerResponse
              ) => resolve(
                new ServerResponse(response)
              ),
            error: (error) => {
              if (error.error) {
                resolve(new ServerResponse(error.error));
              } else {
                reject(error);
                this.toastService.show(
                  {
                    header: 'Error de servidor',
                    body: `${error}`,
                    classname: 'bg-danger text-white'
                  }
                );
              }
            },
          });
        }
      );

      console.log(response);

      if(response.statusCode === 200) {
        flag = true;
        this.getAll();
      } else {

        this.toastService.show(
          {
            header: 'Error de servidor',
            body: `${response.message}`,
            classname: 'bg-danger text-white'
          }
        );

      }

    } catch(error) {
      console.log(error);

      this.toastService.show(
        {
          header: 'Error',
          body: `${error}`,
          classname: 'bg-danger text-white'
        }
      );
    }

    this.spinnerService.loading = false;
    this.spinnerService.message = '';
  
    return flag;

  }

  /*
   ██████╗ ███████╗████████╗ █████╗ ██╗     ██╗
  ██╔════╝ ██╔════╝╚══██╔══╝██╔══██╗██║     ██║
  ██║  ███╗█████╗     ██║   ███████║██║     ██║
  ██║   ██║██╔══╝     ██║   ██╔══██║██║     ██║
  ╚██████╔╝███████╗   ██║   ██║  ██║███████╗███████╗
   ╚═════╝ ╚══════╝   ╚═╝   ╚═╝  ╚═╝╚══════╝╚══════╝

  */
  /**
   * FUNCTION getAll
   */
  async getAll() {

    const urlApi = `${environment.urlApi}/modules`;

    try {

      const token = await this.authService.getToken();

      const response = await new Promise<ServerResponse<{ modules: IModule[] }>>(
        (resolve, reject) => {
          this.http.get<IServerResponse<{ modules: IModule[] }>>(
            urlApi,
            {
              headers: {
                "content-type": "application/json",
                "Authorization": token.toString()
              }
            }
          ).subscribe({
            next: (
                response: IServerResponse<{ modules: IModule[] }>
              ) => resolve(
                new ServerResponse<{ modules: IModule[] }>(response)
              ),
            error: (error) => {
              if (error.error) {
                resolve(new ServerResponse(error.error));
              } else {
                reject(error);
              }
            },
          });
        }
      );

      console.log(response);

      if(response.statusCode === 200) {
        this.modules = response.data!.modules.map(
          module => new Module(module)
        );
      } else {

        this.toastService.show(
          {
            header: 'Error de servidor',
            body: `${response.message}`,
            classname: 'bg-danger text-white'
          }
        );

      }

    } catch(error) {
      console.log(error);
      
    }

    this.loading = false;

  }

  /*
  ██╗   ██╗██████╗ ██████╗  █████╗ ████████╗███████╗
  ██║   ██║██╔══██╗██╔══██╗██╔══██╗╚══██╔══╝██╔════╝
  ██║   ██║██████╔╝██║  ██║███████║   ██║   █████╗
  ██║   ██║██╔═══╝ ██║  ██║██╔══██║   ██║   ██╔══╝
  ╚██████╔╝██║     ██████╔╝██║  ██║   ██║   ███████╗
   ╚═════╝ ╚═╝     ╚═════╝ ╚═╝  ╚═╝   ╚═╝   ╚══════╝

  */
  async update(
    module: any
  ): Promise<boolean> {

    let flag = false;

    const urlApi = `${environment.urlApi}/modules/${module.moduleId}`;

    this.spinnerService.loading = true;
    this.spinnerService.message = 'Actualizando módulo';

    try {

      const token = await this.authService.getToken();

      const response = await new Promise<ServerResponse>(
        (resolve, reject) => {
          this.http.put<IServerResponse>(
            urlApi,
            JSON.stringify(module),
            {
              headers: {
                "content-type": "application/json",
                'Authorization': token.toString()
              }
            }
          ).subscribe({
            next: (
                response: IServerResponse
              ) => resolve(
                new ServerResponse(response)
              ),
            error: (error) => {
              if (error.error) {
                resolve(new ServerResponse(error.error));
              } else {
                reject(error);
                this.toastService.show(
                  {
                    header: 'Error de servidor',
                    body: `${error}`,
                    classname: 'bg-danger text-white'
                  }
                );
              }
            },
          });
        }
      );

      console.log(response);

      if(response.statusCode === 200) {
        flag = true;
        this.getAll();
      } else {

        this.toastService.show(
          {
            header: 'Error de servidor',
            body: `${response.message}`,
            classname: 'bg-danger text-white'
          }
        );

      }

    } catch(error) {
      console.log(error);

      this.toastService.show(
        {
          header: 'Error',
          body: `${error}`,
          classname: 'bg-danger text-white'
        }
      );
    }

    this.spinnerService.loading = false;
    this.spinnerService.message = '';

    return flag;

  }
}
