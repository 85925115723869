import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { DataFormModel } from '../models/data-form-model.model';
import { Table } from 'primeng/table';
import { ToastService } from './toast.service';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  constructor(
    private formBuilder: FormBuilder,
    public toastService: ToastService,
  ) { }

  clearTable(table: Table) {
    table.clear();
  }

  objectKeys(obj: any) {
    return Object.keys(obj);
  }

  buildForm(obj: DataFormModel): FormGroup {

    const group: { [key: string]: FormControl } = {};

    Object.keys(obj).forEach(key => {
      group[key] = new FormControl(obj[key].value, obj[key].validators);
    });

    return new FormGroup(group);
    
  }

  copyToClipboard(text: string) {
    const textarea = document.createElement('textarea');
    textarea.value = text;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);

    this.toastService.show({
      body: 'Copiado en el portapapeles',
      classname: 'bg-dark text-white',
    });
  }

  formatCurrency(amount: number, currency: string = 'USD', locale: string = 'en-US'): string {
    return new Intl.NumberFormat(locale, { style: 'currency', currency }).format(amount);
  }
}
