import { Component } from '@angular/core';
import { NotificationTypeService } from '../../../../services/notification-type.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SpinnerService } from '../../../../services/spinner.service';
import { INotificationType, NotificationType } from '../../../../models/notification-type.model';
import { NotificationTypeModalComponent } from '../../../../components/notification-type-modal/notification-type-modal.component';

@Component({
  selector: 'app-notification-types-page',
  templateUrl: './notification-types-page.component.html',
  styleUrl: './notification-types-page.component.scss'
})
export class NotificationTypesPageComponent {

  constructor(
    public notificationTypeService: NotificationTypeService,
    private modalService: NgbModal,
    public spinnerService: SpinnerService,
  ) {

  }

  /*
  ██████╗ ███████╗██╗     ███████╗████████╗███████╗
  ██╔══██╗██╔════╝██║     ██╔════╝╚══██╔══╝██╔════╝
  ██║  ██║█████╗  ██║     █████╗     ██║   █████╗
  ██║  ██║██╔══╝  ██║     ██╔══╝     ██║   ██╔══╝
  ██████╔╝███████╗███████╗███████╗   ██║   ███████╗
  ╚═════╝ ╚══════╝╚══════╝╚══════╝   ╚═╝   ╚══════╝

  */
  async delete(
    notificationType: NotificationType
  ) {

    const flag = confirm(
      `¿Esta seguro que desea borrar el tipo de notificación ${notificationType.notificationType}?`
    );

    if(flag) {
      await this.notificationTypeService.delete(
        notificationType
      );
    }

  }

  /*
   ██████╗ ██████╗ ███████╗███╗   ██╗
  ██╔═══██╗██╔══██╗██╔════╝████╗  ██║
  ██║   ██║██████╔╝█████╗  ██╔██╗ ██║
  ██║   ██║██╔═══╝ ██╔══╝  ██║╚██╗██║
  ╚██████╔╝██║     ███████╗██║ ╚████║
   ╚═════╝ ╚═╝     ╚══════╝╚═╝  ╚═══╝

  */
  open(
    notificationType?: NotificationType,
  ) {
    const modalRef = this.modalService.open(
      NotificationTypeModalComponent
    );

    modalRef.componentInstance.notificationType = notificationType;

  }

  /*
  ██╗   ██╗██████╗ ██████╗  █████╗ ████████╗███████╗
  ██║   ██║██╔══██╗██╔══██╗██╔══██╗╚══██╔══╝██╔════╝
  ██║   ██║██████╔╝██║  ██║███████║   ██║   █████╗
  ██║   ██║██╔═══╝ ██║  ██║██╔══██║   ██║   ██╔══╝
  ╚██████╔╝██║     ██████╔╝██║  ██║   ██║   ███████╗
   ╚═════╝ ╚═╝     ╚═════╝ ╚═╝  ╚═╝   ╚═╝   ╚══════╝

  */
  /**
   * FUNCTION update
   * 
   * @param notificationType 
   */
  async update(
    notificationType: INotificationType
  ) {

    console.log(notificationType);
    

    this.spinnerService.loading = true;
    this.spinnerService.message = 'Actualizando tipo de notificación';

    await this.notificationTypeService.update(
      notificationType
    );

  }

}
