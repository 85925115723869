import { Component } from '@angular/core';

@Component({
  selector: 'app-platform-page',
  templateUrl: './platform-page.component.html',
  styleUrl: './platform-page.component.scss'
})
export class PlatformPageComponent {

}
