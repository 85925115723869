import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { HttpClient } from '@angular/common/http';
import { SpinnerService } from './spinner.service';
import { ToastService } from './toast.service';
import { environment } from '../../environments/environment';
import { IResidentType, ResidentType } from '../models/resident-type.model';
import { IServerResponse, ServerResponse } from '../models/server-response.model';

@Injectable({
  providedIn: 'root'
})
export class ResidentTypeService {

  public loading: boolean = false;
  public residentTypes: ResidentType[] = [];

  constructor(
    public authService: AuthService,
    private http: HttpClient,
    public spinnerService: SpinnerService,
    public toastService: ToastService
  ) { 
    
  }

  /*
   ██████╗ ███████╗████████╗ █████╗ ██╗     ██╗
  ██╔════╝ ██╔════╝╚══██╔══╝██╔══██╗██║     ██║
  ██║  ███╗█████╗     ██║   ███████║██║     ██║
  ██║   ██║██╔══╝     ██║   ██╔══██║██║     ██║
  ╚██████╔╝███████╗   ██║   ██║  ██║███████╗███████╗
   ╚═════╝ ╚══════╝   ╚═╝   ╚═╝  ╚═╝╚══════╝╚══════╝

  */
  async getAll(): Promise<ResidentType[]> {
    this.loading = true;
    let residentTypes: ResidentType[] = [];

    const urlApi = `${environment.urlApi}/resident-types`;

    try {
      const token = await this.authService.getToken();

      const response = await new Promise<ServerResponse<{ residentTypes: IResidentType[] }>>(
        (resolve, reject) => {
          this.http.get<IServerResponse<{ residentTypes: IResidentType[] }>>(
            urlApi,
            {
              headers: {
                "content-type": "application/json",
                "Authorization": token.toString()
              }
            }
          ).subscribe({
            next: (
              response: IServerResponse<{ residentTypes: IResidentType[] }>
            ) => resolve(
              new ServerResponse<{ residentTypes: IResidentType[] }>(response)
            ),
            error: (error) => {
              if (error.error) {
                resolve(new ServerResponse(error.error));
              } else {
                this.toastService.show({
                  header: 'Error de servidor',
                  body: `${error}`,
                  classname: 'bg-danger text-white'
                });
                reject(error);
              }
            }
          });
        }
      );

      if (response.statusCode === 200) {
        residentTypes = response.data!.residentTypes.map(
          residentType => new ResidentType(residentType)
        );
      } else {
        this.toastService.show({
          header: 'Error de servidor',
          body: `${response.message}`,
          classname: 'bg-danger text-white'
        });
      }

    } catch (error) {
      console.log(error);

      this.toastService.show({
        header: 'Error',
        body: `${error}`,
        classname: 'bg-danger text-white'
      });
    }

    this.loading = false;

    return residentTypes;
  }
  
}
