// Resident.model.ts

import { AppUser, IAppUser } from "./app-user.model";

export interface IResident {
  uidResident: string;
  houseFlatId: string; // Representamos BigInteger como string para mantener consistencia con claves grandes.
  uidCreatedBy?: string; // Usando ? para indicar que es opcional.
  uidUpdatedBy?: string; // Usando ? para indicar que es opcional.
  createdAt: string;
  updatedAt: string;
  resident?: IAppUser;
}

export class Resident {
  uidResident: string;
  houseFlatId: string;
  uidCreatedBy?: string; // Usando ? para indicar que es opcional.
  uidUpdatedBy?: string; // Usando ? para indicar que es opcional.
  createdAt: Date;
  updatedAt: Date;
  resident?: AppUser;

  constructor(data: IResident) {
    this.uidResident = data.uidResident;
    this.houseFlatId = data.houseFlatId;
    this.uidCreatedBy = data.uidCreatedBy;
    this.uidUpdatedBy = data.uidUpdatedBy;
    this.createdAt = new Date(data.createdAt);
    this.updatedAt = new Date(data.updatedAt);
    this.resident = data.resident 
      ? new AppUser(data.resident) 
      : undefined;
  }
}
