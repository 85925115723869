// ProofType.model.ts

export interface IProofType {
  proofTypeId: string;
  name: string;
  description: string;
  uidCreatedBy?: string; // Usando ? para indicar que es opcional
  uidUpdatedBy?: string; // Usando ? para indicar que es opcional
  createdAt: string;
  updatedAt: string;
}

export class ProofType {
  proofTypeId: string;
  name: string;
  description: string;
  uidCreatedBy?: string; // Usando ? para indicar que es opcional
  uidUpdatedBy?: string; // Usando ? para indicar que es opcional
  createdAt: Date;
  updatedAt: Date;

  constructor(data: IProofType) {
    this.proofTypeId = data.proofTypeId;
    this.name = data.name;
    this.description = data.description;
    this.uidCreatedBy = data.uidCreatedBy;
    this.uidUpdatedBy = data.uidUpdatedBy;
    this.createdAt = new Date(data.createdAt);
    this.updatedAt = new Date(data.updatedAt);
  }
}
