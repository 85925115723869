<div class="col-12">
  <div class="row">

    <div class="col-12 text-secondary mb-1">
      <small><b>{{ streetBuilding?.name }}</b></small>
    </div>

    <div class="col-12 bg-white py-3 px-3 rounded mb-4">
      <div class="row">

        @for(houseFlat of streetBuilding!.housesFlats; track $index) {

          <div class="col-12 py-2">
            <div class="row">

              <div class="col d-flex align-items-center">
                {{ houseFlat.number }}
              </div>

              <div class="col d-flex align-items-center">
                <span class="d-flex align-items-center me-3">
                  <i class="material-icons me-1 text-success">person_outline</i> {{ houseFlat.residents.length }}
                </span>

                <span class="d-flex align-items-center">
                  <i class="material-icons me-1">mail_outline</i> {{ houseFlat.invitations.length }}
                </span>
              </div>

              <div class="col text-center d-flex align-items-center">
                @if(houseFlat.status) {
                  <span class="badge rounded-pill text-bg-success">
                    Activo
                  </span>
                } @else {
                  <span class="badge rounded-pill text-bg-danger">
                    Inactivo
                  </span>
                }
              </div>

              <div class="col text-center d-flex align-items-center">
                <p-inputSwitch
                  (onChange)="update(houseFlat)"
                  [(ngModel)]="houseFlat.status">
                </p-inputSwitch>
              </div>

              <div class="col text-end">

                <button
                  (click)="open(houseFlat)"
                  class="btn btn-sm btn-light rounded-circle me-2 my-1">
                  <i class="material-icons mt-1">person_outline</i>
                </button>

                <a
                  [routerLink]="[houseFlat.houseFlatId]"
                  class="btn btn-sm btn-light rounded-circle me-2 my-1">
                  <i class="material-icons mt-1">more_vert</i>
                </a>
              </div>

            </div>
          </div>

        }

        @if(streetBuilding!.housesFlats.length === 0) {
          <div class="col-12 text-center">
            <small class="text-secondary">
              No hay casas registradas
            </small>
          </div>
        }

      </div>
    </div>

  </div>
</div>