import { Injectable } from '@angular/core';
import { IMovement, Movement } from '../models/movement.model';
import { AuthService } from './auth.service';
import { HttpClient } from '@angular/common/http';
import { SpinnerService } from './spinner.service';
import { ToastService } from './toast.service';
import { environment } from '../../environments/environment';
import { IServerResponse, ServerResponse } from '../models/server-response.model';
import { IMovementInsight, MovementInsight } from '../models/movement-insight.model';

@Injectable({
  providedIn: 'root'
})
export class MovementService {

  public loading: boolean = true;
  public movements: Movement[] = [];
  public insights?: MovementInsight;

  constructor(
    public authService: AuthService,
    private http: HttpClient,
    public spinnerService: SpinnerService,
    public toastService: ToastService
  ) { }

  /*
   ██████╗██████╗ ███████╗ █████╗ ████████╗███████╗
  ██╔════╝██╔══██╗██╔════╝██╔══██╗╚══██╔══╝██╔════╝
  ██║     ██████╔╝█████╗  ███████║   ██║   █████╗
  ██║     ██╔══██╗██╔══╝  ██╔══██║   ██║   ██╔══╝
  ╚██████╗██║  ██║███████╗██║  ██║   ██║   ███████╗
   ╚═════╝╚═╝  ╚═╝╚══════╝╚═╝  ╚═╝   ╚═╝   ╚══════╝

  */
  async create(
    movement: IMovement,
    neighborhoodId: string,
  ): Promise<boolean> {
    let flag = false;
    const urlApi = `${environment.urlApi}/neighborhoods/${neighborhoodId}/movements`;

    this.spinnerService.loading = true;
    this.spinnerService.message = 'Registrando movimiento';

    try {
      const token = await this.authService.getToken();
      const response = await new Promise<ServerResponse>(
        (resolve, reject) => {
          this.http.post<IServerResponse>(
            urlApi, 
            JSON.stringify(movement), 
            {
              headers: { 
                "content-type": "application/json", 
                'Authorization': token.toString() 
            }
          }).subscribe({
            next: (response: IServerResponse) => resolve(new ServerResponse(response)),
            error: (error) => {
              if (error.error) resolve(new ServerResponse(error.error));
              else {
                reject(error);
                this.toastService.show({ header: 'Error de servidor', body: `${error}`, classname: 'bg-danger text-white' });
              }
            },
          });
        }
      );

      if(response.statusCode === 201) {
        flag = true;
        this.toastService.show({ body: `${response.message}`, classname: 'bg-success text-white' });
        // this.getAll();
      } else {
        this.toastService.show({ header: 'Error de servidor', body: `${response.message}`, classname: 'bg-danger text-white' });
      }

    } catch(error) {
      console.log(error);
      this.toastService.show({ header: 'Error', body: `${error}`, classname: 'bg-danger text-white' });
    }

    this.spinnerService.loading = false;
    this.spinnerService.message = '';

    return flag;
  }

  /*
  ██████╗ ███████╗██╗     ███████╗████████╗███████╗
  ██╔══██╗██╔════╝██║     ██╔════╝╚══██╔══╝██╔════╝
  ██║  ██║█████╗  ██║     █████╗     ██║   █████╗
  ██║  ██║██╔══╝  ██║     ██╔══╝     ██║   ██╔══╝
  ██████╔╝███████╗███████╗███████╗   ██║   ███████╗
  ╚═════╝ ╚══════╝╚══════╝╚══════╝   ╚═╝   ╚══════╝

  */
  async delete(
    movement: Movement
  ): Promise<boolean> {
    let flag = false;
    const urlApi = `${environment.urlApi}/neighborhoods/${movement.neighborhoodId}/movements/${movement.movementId}`;

    this.spinnerService.loading = true;
    this.spinnerService.message = 'Eliminando movimiento';

    try {
      const token = await this.authService.getToken();
      const response = await new Promise<ServerResponse>(
        (resolve, reject) => {
          this.http.delete<IServerResponse>(urlApi, {
            headers: { 'Authorization': token.toString() }
          }).subscribe({
            next: (response: IServerResponse) => resolve(new ServerResponse(response)),
            error: (error) => {
              if (error.error) resolve(new ServerResponse(error.error));
              else {
                reject(error);
                this.toastService.show({ header: 'Error de servidor', body: `${error}`, classname: 'bg-danger text-white' });
              }
            },
          });
        }
      );

      if(response.statusCode === 200) {
        flag = true;
        this.toastService.show({ body: `${response.message}`, classname: 'bg-success text-white' });
      } else {
        this.toastService.show({ header: 'Error de servidor', body: `${response.message}`, classname: 'bg-danger text-white' });
      }

    } catch(error) {
      console.log(error);
      this.toastService.show({ header: 'Error', body: `${error}`, classname: 'bg-danger text-white' });
    }

    this.spinnerService.loading = false;
    this.spinnerService.message = '';
  
    return flag;
  }

  /*
   ██████╗ ███████╗████████╗
  ██╔════╝ ██╔════╝╚══██╔══╝
  ██║  ███╗█████╗     ██║
  ██║   ██║██╔══╝     ██║
  ╚██████╔╝███████╗   ██║
   ╚═════╝ ╚══════╝   ╚═╝

  */
  async get(
    movementId: string,
    neighborhoodId: string,
  ): Promise<Movement | null> {
    let movement: Movement | null = null;
    const urlApi = `${environment.urlApi}/neighborhoods/${neighborhoodId}/movements/${movementId}`;

    this.spinnerService.loading = true;
    this.spinnerService.message = 'Obteniendo movimiento';

    try {
      const token = await this.authService.getToken();
      const response = await new Promise<ServerResponse<{ movement: IMovement }>>(
        (resolve, reject) => {
          this.http.get<IServerResponse<{ movement: IMovement }>>(urlApi, {
            headers: { "content-type": "application/json", "Authorization": token.toString() }
          }).subscribe({
            next: (response: IServerResponse<{ movement: IMovement }>) => resolve(new ServerResponse<{ movement: IMovement }>(response)),
            error: (error) => {
              if (error.error) resolve(new ServerResponse(error.error));
              else reject(error);
            },
          });
        }
      );

      if(response.statusCode === 200) {
        movement = new Movement(response.data!.movement);
      } else {
        this.toastService.show({ header: 'Error de servidor', body: `${response.message}`, classname: 'bg-danger text-white' });
      }

    } catch(error) {
      console.log(error);
    }

    this.spinnerService.loading = false;
    this.spinnerService.message = '';

    return movement;
  }

  /*
   ██████╗ ███████╗████████╗ █████╗ ██╗     ██╗
  ██╔════╝ ██╔════╝╚══██╔══╝██╔══██╗██║     ██║
  ██║  ███╗█████╗     ██║   ███████║██║     ██║
  ██║   ██║██╔══╝     ██║   ██╔══██║██║     ██║
  ╚██████╔╝███████╗   ██║   ██║  ██║███████╗███████╗
   ╚═════╝ ╚══════╝   ╚═╝   ╚═╝  ╚═╝╚══════╝╚══════╝

  */
  async getAll(
    neighborhoodId: string,
    month: number,
    year: number
  ) {
    this.loading = true;
    const urlApi = `${environment.urlApi}/neighborhoods/${neighborhoodId}/movements?month=${month}&year=${year}`;

    try {
      const token = await this.authService.getToken();
      const response = await new Promise<ServerResponse<{ movements: IMovement[], insights: IMovementInsight }>>(
        (resolve, reject) => {
          this.http.get<IServerResponse<{ movements: IMovement[], insights: IMovementInsight }>>(urlApi, {
            headers: { "content-type": "application/json", "Authorization": token.toString() }
          }).subscribe({
            next: (response: IServerResponse<{ movements: IMovement[], insights: IMovementInsight }>) => resolve(new ServerResponse<{ movements: IMovement[], insights: IMovementInsight }>(response)),
            error: (error) => {
              if (error.error) resolve(new ServerResponse(error.error));
              else reject(error);
            },
          });
        }
      );

      if(response.statusCode === 200) {
        this.movements = response.data!.movements.map(
          movement => new Movement(movement)
        );

        this.insights = new MovementInsight(response.data!.insights);

      } else {
        this.toastService.show({ header: 'Error de servidor', body: `${response.message}`, classname: 'bg-danger text-white' });
      }

    } catch(error) {
      console.log(error);
    }

    this.loading = false;
  }

  /*
  ██╗   ██╗██████╗ ██████╗  █████╗ ████████╗███████╗
  ██║   ██║██╔══██╗██╔══██╗██╔══██╗╚══██╔══╝██╔════╝
  ██║   ██║██████╔╝██║  ██║███████║   ██║   █████╗
  ██║   ██║██╔═══╝ ██║  ██║██╔══██║   ██║   ██╔══╝
  ╚██████╔╝██║     ██████╔╝██║  ██║   ██║   ███████╗
   ╚═════╝ ╚═╝     ╚═════╝ ╚═╝  ╚═╝   ╚═╝   ╚══════╝

  */
  async update(
    movement: IMovement
  ): Promise<boolean> {
    let flag = false;
    const urlApi = `${environment.urlApi}/neighborhoods/${movement.neighborhoodId}/movements/${movement.movementId}`;

    this.spinnerService.loading = true;
    this.spinnerService.message = 'Actualizando movimiento';

    try {
      const token = await this.authService.getToken();
      const response = await new Promise<ServerResponse>(
        (resolve, reject) => {
          this.http.put<IServerResponse>(urlApi, JSON.stringify(movement), {
            headers: { "content-type": "application/json", 'Authorization': token.toString() }
          }).subscribe({
            next: (response: IServerResponse) => resolve(new ServerResponse(response)),
            error: (error) => {
              if (error.error) resolve(new ServerResponse(error.error));
              else {
                reject(error);
                this.toastService.show({ header: 'Error de servidor', body: `${error}`, classname: 'bg-danger text-white' });
              }
            },
          });
        }
      );

      if(response.statusCode === 200) {
        flag = true;
        this.toastService.show({ body: `${response.message}`, classname: 'bg-success text-white' });
      } else {
        this.toastService.show({ header: 'Error de servidor', body: `${response.message}`, classname: 'bg-danger text-white' });
      }

    } catch(error) {
      console.log(error);
      this.toastService.show({ header: 'Error', body: `${error}`, classname: 'bg-danger text-white' });
    }

    this.spinnerService.loading = false;
    this.spinnerService.message = '';

    return flag;
  }
  
}
