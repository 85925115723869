import { Component, Input } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { DataFormModel } from '../../models/data-form-model.model';
import { Neighborhood } from '../../models/neighborhood.model';
import { NeighborhoodService } from '../../services/neighborhood.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UtilService } from '../../services/util.service';
import { NeighborhoodTypeService } from '../../services/neighborhood-type.service';

@Component({
  selector: 'app-neighborhood-modal',
  templateUrl: './neighborhood-modal.component.html',
  styleUrl: './neighborhood-modal.component.scss'
})
export class NeighborhoodModalComponent {

  @Input() neighborhood?: Neighborhood;

  public dataForm: DataFormModel = {
    // image: {
    //   value: '',
    //   type: 'image',
    //   label: '',
    //   required: false,
    //   validators: []
    // },
    neighborhoodTypeId: {
      value: null,
      type: 'select',
      idSelect: 'neighborhoodTypeId',
      labelSelect: 'name',
      data: [],
      label: 'Tipo de conjunto',
      placeholder: 'Seleccione el tipo de conjunto',
      requiredMessage: 'Por favor, seleccione el tipo de conjunto',
      required: true,
      validators: [Validators.required],
      onChange: (event: any) => {

      }
    },
    name: {
      value: '',
      type: 'text',
      label: 'Nombre del conjunto',
      requiredMessage: 'Por favor, ingrese el nombre del conjunto',
      required: true,
      validators: [Validators.required]
    },
    street: {
      value: '',
      type: 'text',
      label: 'Calle',
      requiredMessage: 'Por favor, ingrese la calle del conjunto',
      required: true,
      validators: [Validators.required]
    },
    city: {
      value: '',
      type: 'text',
      label: 'Ciudad',
      requiredMessage: 'Por favor, ingrese la ciudad del conjunto',
      required: true,
      validators: [Validators.required]
    },
    state: {
      value: '',
      type: 'text',
      label: 'Estado',
      requiredMessage: 'Por favor, ingrese el estado del conjunto',
      required: true,
      validators: [Validators.required]
    },
    zipCode: {
      value: '',
      type: 'text',
      label: 'Código postal',
      requiredMessage: 'Por favor, ingrese el código postal del conjunto',
      required: true,
      validators: [Validators.required]
    },
    proofsRequired: {
      value: false,
      type: 'switch',
      label: 'Requiere de pruebas',
      requiredMessage: '',
      required: false,
    },
    platesRequired: {
      value: false,
      type: 'switch',
      label: 'Requiere de placas',
      requiredMessage: '',
      required: false,
    },
    brandRequired: {
      value: false,
      type: 'switch',
      label: 'Requiere de marca',
      requiredMessage: '',
      required: false,
    },
    modelRequired: {
      value: false,
      type: 'switch',
      label: 'Requiere de modelo',
      requiredMessage: '',
      required: false,
    },
    colorRequired: {
      value: false,
      type: 'switch',
      label: 'Requiere de color',
      requiredMessage: '',
      required: false,
    },
    accountsPerProperty: {
      value: '',
      type: 'number',
      label: 'Cuentas por propiedad',
      requiredMessage: 'Por favor, ingrese el número de cuentas por propiedad',
      required: true,
      validators: [Validators.required]
    },
    numberProperties: {
      value: '',
      type: 'number',
      label: 'Número de propiedades',
      requiredMessage: 'Por favor, ingrese el número de propiedades',
      required: true,
      validators: [Validators.required]
    },
    licencesNumber: {
      value: '',
      type: 'number',
      label: 'Número de licencias',
      requiredMessage: 'Por favor, ingrese el número de licencias',
      required: true,
      validators: [Validators.required]
    },
    devicesPerProperty: {
      value: '',
      type: 'number',
      label: 'Dispocitivos por cuenta',
      requiredMessage: 'Por favor, ingrese el número de dispositivos por propiedad',
      required: true,
      validators: [Validators.required]
    },
    test: {
      value: true,
      type: 'switch',
      label: 'Prueba',
      requiredMessage: '',
      required: false,
    },
    status: {
      value: false,
      type: 'switch',
      label: 'Estado',
      requiredMessage: '',
      required: false,
    },
    passDuration: {
      value: '-1',
      type: 'number',
      label: 'Duración de los pases',
      requiredMessage: 'Por favor, ingrese la duración de los pases',
      required: true,
      validators: [Validators.required]
    },
    timeZone: {
      value: '-6',
      type: 'number',
      label: 'Zona horaria',
      requiredMessage: 'Por favor, ingrese la zona horaria',
      required: true,
      validators: [Validators.required]
    },
  };

  public submitted: boolean = false;
  public loading: boolean = false;

  public formNeighborhood!: FormGroup;

  constructor(
    public activeModal: NgbActiveModal,
    public neighborhoodService: NeighborhoodService,
    public neighborhoodTypeService: NeighborhoodTypeService,
    public utilService: UtilService,
  ) { }

  ngOnInit(): void {
    this.loadData();
  }

  get f() {
    return this.formNeighborhood.controls;
  }

  /*
   ██████╗██████╗ ███████╗ █████╗ ████████╗███████╗
  ██╔════╝██╔══██╗██╔════╝██╔══██╗╚══██╔══╝██╔════╝
  ██║     ██████╔╝█████╗  ███████║   ██║   █████╗
  ██║     ██╔══██╗██╔══╝  ██╔══██║   ██║   ██╔══╝
  ╚██████╗██║  ██║███████╗██║  ██║   ██║   ███████╗
   ╚═════╝╚═╝  ╚═╝╚══════╝╚═╝  ╚═╝   ╚═╝   ╚══════╝

  */
   async create() {

    // this.formNeighborhood.value.imageBase64 = this.imageBase64;

    const flag = await this.neighborhoodService.create(
      this.formNeighborhood.value,
    );

    if(flag) {
      for(let key of Object.keys(this.dataForm)) {
        this.dataForm[key].value = '';
      }

      this.activeModal.close();
    }

  }

  /*
  ██╗      ██████╗  █████╗ ██████╗ ██████╗  █████╗ ████████╗ █████╗
  ██║     ██╔═══██╗██╔══██╗██╔══██╗██╔══██╗██╔══██╗╚══██╔══╝██╔══██╗
  ██║     ██║   ██║███████║██║  ██║██║  ██║███████║   ██║   ███████║
  ██║     ██║   ██║██╔══██║██║  ██║██║  ██║██╔══██║   ██║   ██╔══██║
  ███████╗╚██████╔╝██║  ██║██████╔╝██████╔╝██║  ██║   ██║   ██║  ██║
  ╚══════╝ ╚═════╝ ╚═╝  ╚═╝╚═════╝ ╚═════╝ ╚═╝  ╚═╝   ╚═╝   ╚═╝  ╚═╝

  */
  async loadData() {
    this.loading = true;

    await this.neighborhoodTypeService.getAll();

    this.dataForm['neighborhoodTypeId'].data = this.neighborhoodTypeService.neighborhoodTypes;

    if(this.neighborhood) {

      this.dataForm = {
        ...this.dataForm,
      }

      for(let key of Object.keys(this.dataForm)) {

        if (key in this.neighborhood!) {
          const standardKey = key as keyof typeof this.neighborhood;
      
          // Ahora puedes acceder a `this.machine[machineKey]` sin errores de tipo.
          this.dataForm[key].value = this.neighborhood[standardKey]!;
        }
      } 
    }
    
    this.formNeighborhood = this.utilService.buildForm(this.dataForm);

    this.loading = false;
  }

  /*
   ██████╗ ███╗   ██╗███████╗██╗██╗     ███████╗ ██████╗██╗  ██╗ █████╗ ███╗   ██╗ ██████╗ ███████╗
  ██╔═══██╗████╗  ██║██╔════╝██║██║     ██╔════╝██╔════╝██║  ██║██╔══██╗████╗  ██║██╔════╝ ██╔════╝
  ██║   ██║██╔██╗ ██║█████╗  ██║██║     █████╗  ██║     ███████║███████║██╔██╗ ██║██║  ███╗█████╗
  ██║   ██║██║╚██╗██║██╔══╝  ██║██║     ██╔══╝  ██║     ██╔══██║██╔══██║██║╚██╗██║██║   ██║██╔══╝
  ╚██████╔╝██║ ╚████║██║     ██║███████╗███████╗╚██████╗██║  ██║██║  ██║██║ ╚████║╚██████╔╝███████╗
   ╚═════╝ ╚═╝  ╚═══╝╚═╝     ╚═╝╚══════╝╚══════╝ ╚═════╝╚═╝  ╚═╝╚═╝  ╚═╝╚═╝  ╚═══╝ ╚═════╝ ╚══════╝

  */
  /**
   * FUNCTION onFileChange
   * 
   * @param event 
   */
  onFileChange(event: any) {
    // console.log(event);

    // const file = event.target.files[0];

    // const reader = new FileReader();
    // reader.readAsDataURL(file); // toBase64
    // reader.onload = () => {
    //   this.imageBase64 = reader.result as string; // base64 Image src
    //   console.log(this.imageBase64);
      
    // };
    
  }

  /*
   ██████╗ ███╗   ██╗███████╗██╗   ██╗██████╗ ███╗   ███╗██╗████████╗
  ██╔═══██╗████╗  ██║██╔════╝██║   ██║██╔══██╗████╗ ████║██║╚══██╔══╝
  ██║   ██║██╔██╗ ██║███████╗██║   ██║██████╔╝██╔████╔██║██║   ██║
  ██║   ██║██║╚██╗██║╚════██║██║   ██║██╔══██╗██║╚██╔╝██║██║   ██║
  ╚██████╔╝██║ ╚████║███████║╚██████╔╝██████╔╝██║ ╚═╝ ██║██║   ██║
   ╚═════╝ ╚═╝  ╚═══╝╚══════╝ ╚═════╝ ╚═════╝ ╚═╝     ╚═╝╚═╝   ╚═╝

  */
  async onSubmit() {

    console.log(this.formNeighborhood.value);
    
    this.submitted = true;

    if(this.formNeighborhood.invalid) {
      return;
    }

    this.formNeighborhood.value.accountsPerProperty = parseInt(this.formNeighborhood.value.accountsPerProperty);
    this.formNeighborhood.value.numberProperties = parseInt(this.formNeighborhood.value.numberProperties);
    this.formNeighborhood.value.licencesNumber = parseInt(this.formNeighborhood.value.licencesNumber);
    this.formNeighborhood.value.devicesPerProperty = parseInt(this.formNeighborhood.value.devicesPerProperty);
    this.formNeighborhood.value.passDuration = parseInt(this.formNeighborhood.value.passDuration);
    this.formNeighborhood.value.timeZone = parseInt(this.formNeighborhood.value.timeZone);

    console.log(this.formNeighborhood.value);

    if(this.neighborhood) {

      await this.update();
    } else {
      await this.create();
    }
  }

  /*
  ██╗   ██╗██████╗ ██████╗  █████╗ ████████╗███████╗
  ██║   ██║██╔══██╗██╔══██╗██╔══██╗╚══██╔══╝██╔════╝
  ██║   ██║██████╔╝██║  ██║███████║   ██║   █████╗
  ██║   ██║██╔═══╝ ██║  ██║██╔══██║   ██║   ██╔══╝
  ╚██████╔╝██║     ██████╔╝██║  ██║   ██║   ███████╗
   ╚═════╝ ╚═╝     ╚═════╝ ╚═╝  ╚═╝   ╚═╝   ╚══════╝

  */
  async update() {

    this.formNeighborhood.value.neighborhoodId = this.neighborhood!.neighborhoodId;

    // this.formNeighborhood.value.imageBase64 = this.imageBase64;

    // const flag = await this.neighborhoodService.update(
    // );

    // if(flag) {

    //   for(let key of Object.keys(this.dataForm)) {
    //     this.dataForm[key].value = '';
    //   }

    //   this.activeModal.close();
    // }
  }

}
