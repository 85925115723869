import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../../services/auth.service';
import { GateService } from '../../../services/gate.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AccessDoorGroupModalComponent } from '../../../components/access-door-group-modal/access-door-group-modal.component';
import { AccessDoorGroup } from '../../../models/access-door-group.model';
import { AccessDoorGroupService } from '../../../services/access-door-group.service';
import { AccessDoor } from '../../../models/access-door.model';
import { AccessDoorModalComponent } from '../../../components/access-door-modal/access-door-modal.component';
import { AccessDoorService } from '../../../services/access-door.service';

@Component({
  selector: 'app-gate-page',
  templateUrl: './gate-page.component.html',
  styleUrl: './gate-page.component.scss'
})
export class GatePageComponent {

  public neighborhoodId: string = '';
  public gateId: string = '';

  constructor(
    private accessDoorService: AccessDoorService,
    public accessDoorGroupService: AccessDoorGroupService,
    private activatedRoute: ActivatedRoute,
    public authService: AuthService,
    private modalService: NgbModal,
    public gateService: GateService,
  ) { 

    this.activatedRoute.params.subscribe(
      async params => {

        // console.log(params);
        this.neighborhoodId = params['neighborhoodId'];
        this.gateId = params['gateId'];

        await this.gateService.get(
          this.neighborhoodId, 
          this.gateId
        );

        await this.accessDoorGroupService.getAll(
          this.neighborhoodId,
          this.gateId
        );
      }
    );

  }

  /*
  ██████╗ ███████╗██╗     ███████╗████████╗███████╗ █████╗  ██████╗ ██████╗███████╗███████╗███████╗██████╗  ██████╗  ██████╗ ██████╗
  ██╔══██╗██╔════╝██║     ██╔════╝╚══██╔══╝██╔════╝██╔══██╗██╔════╝██╔════╝██╔════╝██╔════╝██╔════╝██╔══██╗██╔═══██╗██╔═══██╗██╔══██╗
  ██║  ██║█████╗  ██║     █████╗     ██║   █████╗  ███████║██║     ██║     █████╗  ███████╗███████╗██║  ██║██║   ██║██║   ██║██████╔╝
  ██║  ██║██╔══╝  ██║     ██╔══╝     ██║   ██╔══╝  ██╔══██║██║     ██║     ██╔══╝  ╚════██║╚════██║██║  ██║██║   ██║██║   ██║██╔══██╗
  ██████╔╝███████╗███████╗███████╗   ██║   ███████╗██║  ██║╚██████╗╚██████╗███████╗███████║███████║██████╔╝╚██████╔╝╚██████╔╝██║  ██║
  ╚═════╝ ╚══════╝╚══════╝╚══════╝   ╚═╝   ╚══════╝╚═╝  ╚═╝ ╚═════╝ ╚═════╝╚══════╝╚══════╝╚══════╝╚═════╝  ╚═════╝  ╚═════╝ ╚═╝  ╚═╝

  */
  async deleteAccessDoor(
    accessDoorGroup: AccessDoorGroup,
    accessDoor: AccessDoor
  ) {
    const flag = confirm(
      `¿Está seguro que desea borrar la puerta "${accessDoor.label}"?`
    );

    if (flag) {
      await this.accessDoorService.delete(
        this.gateService.currentGate!,
        accessDoorGroup,
        accessDoor
      );

      await this.accessDoorGroupService.getAll(
        this.neighborhoodId,
        this.gateId
      );
    }
  }

  /*
  ██████╗ ███████╗██╗     ███████╗████████╗███████╗ █████╗  ██████╗ ██████╗███████╗███████╗███████╗██████╗  ██████╗  ██████╗ ██████╗  ██████╗ ██████╗  ██████╗ ██╗   ██╗██████╗
  ██╔══██╗██╔════╝██║     ██╔════╝╚══██╔══╝██╔════╝██╔══██╗██╔════╝██╔════╝██╔════╝██╔════╝██╔════╝██╔══██╗██╔═══██╗██╔═══██╗██╔══██╗██╔════╝ ██╔══██╗██╔═══██╗██║   ██║██╔══██╗
  ██║  ██║█████╗  ██║     █████╗     ██║   █████╗  ███████║██║     ██║     █████╗  ███████╗███████╗██║  ██║██║   ██║██║   ██║██████╔╝██║  ███╗██████╔╝██║   ██║██║   ██║██████╔╝
  ██║  ██║██╔══╝  ██║     ██╔══╝     ██║   ██╔══╝  ██╔══██║██║     ██║     ██╔══╝  ╚════██║╚════██║██║  ██║██║   ██║██║   ██║██╔══██╗██║   ██║██╔══██╗██║   ██║██║   ██║██╔═══╝
  ██████╔╝███████╗███████╗███████╗   ██║   ███████╗██║  ██║╚██████╗╚██████╗███████╗███████║███████║██████╔╝╚██████╔╝╚██████╔╝██║  ██║╚██████╔╝██║  ██║╚██████╔╝╚██████╔╝██║
  ╚═════╝ ╚══════╝╚══════╝╚══════╝   ╚═╝   ╚══════╝╚═╝  ╚═╝ ╚═════╝ ╚═════╝╚══════╝╚══════╝╚══════╝╚═════╝  ╚═════╝  ╚═════╝ ╚═╝  ╚═╝ ╚═════╝ ╚═╝  ╚═╝ ╚═════╝  ╚═════╝ ╚═╝

  */
  async deleteAccessDoorGroup(
    accessDoorGroup: AccessDoorGroup
  ) {
    const flag = confirm(
      `¿Está seguro que desea borrar el grupo de puertas "${accessDoorGroup.accessDoorGroup}"?`
    );

    if (flag) {
      await this.accessDoorGroupService.delete(
        this.gateService.currentGate!,
        accessDoorGroup
      );
    }
  }

  /*
   ██████╗ ██████╗ ███████╗███╗   ██╗ █████╗  ██████╗ ██████╗███████╗███████╗███████╗██████╗  ██████╗  ██████╗ ██████╗ ███╗   ███╗ ██████╗ ██████╗  █████╗ ██╗
  ██╔═══██╗██╔══██╗██╔════╝████╗  ██║██╔══██╗██╔════╝██╔════╝██╔════╝██╔════╝██╔════╝██╔══██╗██╔═══██╗██╔═══██╗██╔══██╗████╗ ████║██╔═══██╗██╔══██╗██╔══██╗██║
  ██║   ██║██████╔╝█████╗  ██╔██╗ ██║███████║██║     ██║     █████╗  ███████╗███████╗██║  ██║██║   ██║██║   ██║██████╔╝██╔████╔██║██║   ██║██║  ██║███████║██║
  ██║   ██║██╔═══╝ ██╔══╝  ██║╚██╗██║██╔══██║██║     ██║     ██╔══╝  ╚════██║╚════██║██║  ██║██║   ██║██║   ██║██╔══██╗██║╚██╔╝██║██║   ██║██║  ██║██╔══██║██║
  ╚██████╔╝██║     ███████╗██║ ╚████║██║  ██║╚██████╗╚██████╗███████╗███████║███████║██████╔╝╚██████╔╝╚██████╔╝██║  ██║██║ ╚═╝ ██║╚██████╔╝██████╔╝██║  ██║███████╗
   ╚═════╝ ╚═╝     ╚══════╝╚═╝  ╚═══╝╚═╝  ╚═╝ ╚═════╝ ╚═════╝╚══════╝╚══════╝╚══════╝╚═════╝  ╚═════╝  ╚═════╝ ╚═╝  ╚═╝╚═╝     ╚═╝ ╚═════╝ ╚═════╝ ╚═╝  ╚═╝╚══════╝

  */
  openAccessDoorModal(
    accessDoorGroup: AccessDoorGroup,
    accessDoor?: AccessDoor,
  ) {
    
    const modalRef = this.modalService.open(
      AccessDoorModalComponent,
    );

    modalRef.componentInstance.accessDoor = accessDoor;
    modalRef.componentInstance.gate = this.gateService.currentGate!;
    modalRef.componentInstance.accessDoorGroup = accessDoorGroup;

    modalRef.closed.subscribe(
      async () => {
    
        await this.accessDoorGroupService.getAll(
          this.neighborhoodId,
          this.gateId
        );

      }
    );

  }

  /*
   ██████╗ ██████╗ ███████╗███╗   ██╗ █████╗  ██████╗ ██████╗███████╗███████╗███████╗██████╗  ██████╗  ██████╗ ██████╗  ██████╗ ██████╗  ██████╗ ██╗   ██╗██████╗ ███╗   ███╗ ██████╗ ██████╗  █████╗ ██╗
  ██╔═══██╗██╔══██╗██╔════╝████╗  ██║██╔══██╗██╔════╝██╔════╝██╔════╝██╔════╝██╔════╝██╔══██╗██╔═══██╗██╔═══██╗██╔══██╗██╔════╝ ██╔══██╗██╔═══██╗██║   ██║██╔══██╗████╗ ████║██╔═══██╗██╔══██╗██╔══██╗██║
  ██║   ██║██████╔╝█████╗  ██╔██╗ ██║███████║██║     ██║     █████╗  ███████╗███████╗██║  ██║██║   ██║██║   ██║██████╔╝██║  ███╗██████╔╝██║   ██║██║   ██║██████╔╝██╔████╔██║██║   ██║██║  ██║███████║██║
  ██║   ██║██╔═══╝ ██╔══╝  ██║╚██╗██║██╔══██║██║     ██║     ██╔══╝  ╚════██║╚════██║██║  ██║██║   ██║██║   ██║██╔══██╗██║   ██║██╔══██╗██║   ██║██║   ██║██╔═══╝ ██║╚██╔╝██║██║   ██║██║  ██║██╔══██║██║
  ╚██████╔╝██║     ███████╗██║ ╚████║██║  ██║╚██████╗╚██████╗███████╗███████║███████║██████╔╝╚██████╔╝╚██████╔╝██║  ██║╚██████╔╝██║  ██║╚██████╔╝╚██████╔╝██║     ██║ ╚═╝ ██║╚██████╔╝██████╔╝██║  ██║███████╗
   ╚═════╝ ╚═╝     ╚══════╝╚═╝  ╚═══╝╚═╝  ╚═╝ ╚═════╝ ╚═════╝╚══════╝╚══════╝╚══════╝╚═════╝  ╚═════╝  ╚═════╝ ╚═╝  ╚═╝ ╚═════╝ ╚═╝  ╚═╝ ╚═════╝  ╚═════╝ ╚═╝     ╚═╝     ╚═╝ ╚═════╝ ╚═════╝ ╚═╝  ╚═╝╚══════╝

  */
  openAccessDoorGroupModal(
    accessDoorGroup?: AccessDoorGroup
  ) {

    const modalRef = this.modalService.open(
      AccessDoorGroupModalComponent,
    );

    modalRef.componentInstance.accessDoorGroup = accessDoorGroup;

  }

}
