import { Component } from '@angular/core';
import { StreetBuildingService } from '../../../services/street-building.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StreetBuilding } from '../../../models/street-building.model';
import { StreetBuildingModalComponent } from '../../../components/street-building-modal/street-building-modal.component';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../../services/auth.service';
import * as XLSX from 'xlsx';
import { HouseFlatService } from '../../../services/house-flat.service';
import { IHouseFlat } from '../../../models/house-flat.model';

@Component({
  selector: 'app-streets-building-page',
  templateUrl: './streets-building-page.component.html',
  styleUrl: './streets-building-page.component.scss'
})
export class StreetsBuildingPageComponent {

  public neighborhoodId?: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    public authService: AuthService,
    public houseFlatService: HouseFlatService,
    public streetBuildingService: StreetBuildingService,
    private modalService: NgbModal,
  ) {

    this.activatedRoute.params.subscribe(
      async params => {

        this.neighborhoodId = params['neighborhoodId'];

        await this.streetBuildingService.getAll(
          this.neighborhoodId!
        );
      }
    );

  }

  /*
   ██████╗ ███████╗████████╗████████╗ ██████╗ ████████╗ █████╗ ██╗     ██╗  ██╗ ██████╗ ██╗   ██╗███████╗███████╗███████╗███████╗██╗      █████╗ ████████╗███████╗
  ██╔════╝ ██╔════╝╚══██╔══╝╚══██╔══╝██╔═══██╗╚══██╔══╝██╔══██╗██║     ██║  ██║██╔═══██╗██║   ██║██╔════╝██╔════╝██╔════╝██╔════╝██║     ██╔══██╗╚══██╔══╝██╔════╝
  ██║  ███╗█████╗     ██║      ██║   ██║   ██║   ██║   ███████║██║     ███████║██║   ██║██║   ██║███████╗█████╗  ███████╗█████╗  ██║     ███████║   ██║   ███████╗
  ██║   ██║██╔══╝     ██║      ██║   ██║   ██║   ██║   ██╔══██║██║     ██╔══██║██║   ██║██║   ██║╚════██║██╔══╝  ╚════██║██╔══╝  ██║     ██╔══██║   ██║   ╚════██║
  ╚██████╔╝███████╗   ██║      ██║   ╚██████╔╝   ██║   ██║  ██║███████╗██║  ██║╚██████╔╝╚██████╔╝███████║███████╗███████║██║     ███████╗██║  ██║   ██║   ███████║
   ╚═════╝ ╚══════╝   ╚═╝      ╚═╝    ╚═════╝    ╚═╝   ╚═╝  ╚═╝╚══════╝╚═╝  ╚═╝ ╚═════╝  ╚═════╝ ╚══════╝╚══════╝╚══════╝╚═╝     ╚══════╝╚═╝  ╚═╝   ╚═╝   ╚══════╝

  */
  getTotalHousesFlats() {
    const total = this.streetBuildingService.streetsBuildings
      .reduce((sum, building) => sum + building.housesFlats.length, 0);
    return total;
  }

  /*
   ██████╗ ███████╗████████╗████████╗ ██████╗ ████████╗ █████╗ ██╗      █████╗  ██████╗████████╗██╗██╗   ██╗███████╗██╗  ██╗ ██████╗ ██╗   ██╗███████╗███████╗███████╗███████╗██╗      █████╗ ████████╗███████╗
  ██╔════╝ ██╔════╝╚══██╔══╝╚══██╔══╝██╔═══██╗╚══██╔══╝██╔══██╗██║     ██╔══██╗██╔════╝╚══██╔══╝██║██║   ██║██╔════╝██║  ██║██╔═══██╗██║   ██║██╔════╝██╔════╝██╔════╝██╔════╝██║     ██╔══██╗╚══██╔══╝██╔════╝
  ██║  ███╗█████╗     ██║      ██║   ██║   ██║   ██║   ███████║██║     ███████║██║        ██║   ██║██║   ██║█████╗  ███████║██║   ██║██║   ██║███████╗█████╗  ███████╗█████╗  ██║     ███████║   ██║   ███████╗
  ██║   ██║██╔══╝     ██║      ██║   ██║   ██║   ██║   ██╔══██║██║     ██╔══██║██║        ██║   ██║╚██╗ ██╔╝██╔══╝  ██╔══██║██║   ██║██║   ██║╚════██║██╔══╝  ╚════██║██╔══╝  ██║     ██╔══██║   ██║   ╚════██║
  ╚██████╔╝███████╗   ██║      ██║   ╚██████╔╝   ██║   ██║  ██║███████╗██║  ██║╚██████╗   ██║   ██║ ╚████╔╝ ███████╗██║  ██║╚██████╔╝╚██████╔╝███████║███████╗███████║██║     ███████╗██║  ██║   ██║   ███████║
   ╚═════╝ ╚══════╝   ╚═╝      ╚═╝    ╚═════╝    ╚═╝   ╚═╝  ╚═╝╚══════╝╚═╝  ╚═╝ ╚═════╝   ╚═╝   ╚═╝  ╚═══╝  ╚══════╝╚═╝  ╚═╝ ╚═════╝  ╚═════╝ ╚══════╝╚══════╝╚══════╝╚═╝     ╚══════╝╚═╝  ╚═╝   ╚═╝   ╚══════╝

  */
  getTotalActiveHousesFlats() {
    const total = this.streetBuildingService.streetsBuildings
      .reduce((sum, building) => sum + building.housesFlats.filter(houseFlat => houseFlat.status).length, 0);
    return total;
  }

  /*
   ██████╗ ███████╗████████╗████████╗ ██████╗ ████████╗ █████╗ ██╗     ██╗███╗   ██╗ █████╗  ██████╗████████╗██╗██╗   ██╗███████╗██╗  ██╗ ██████╗ ██╗   ██╗███████╗███████╗███████╗███████╗██╗      █████╗ ████████╗███████╗
  ██╔════╝ ██╔════╝╚══██╔══╝╚══██╔══╝██╔═══██╗╚══██╔══╝██╔══██╗██║     ██║████╗  ██║██╔══██╗██╔════╝╚══██╔══╝██║██║   ██║██╔════╝██║  ██║██╔═══██╗██║   ██║██╔════╝██╔════╝██╔════╝██╔════╝██║     ██╔══██╗╚══██╔══╝██╔════╝
  ██║  ███╗█████╗     ██║      ██║   ██║   ██║   ██║   ███████║██║     ██║██╔██╗ ██║███████║██║        ██║   ██║██║   ██║█████╗  ███████║██║   ██║██║   ██║███████╗█████╗  ███████╗█████╗  ██║     ███████║   ██║   ███████╗
  ██║   ██║██╔══╝     ██║      ██║   ██║   ██║   ██║   ██╔══██║██║     ██║██║╚██╗██║██╔══██║██║        ██║   ██║╚██╗ ██╔╝██╔══╝  ██╔══██║██║   ██║██║   ██║╚════██║██╔══╝  ╚════██║██╔══╝  ██║     ██╔══██║   ██║   ╚════██║
  ╚██████╔╝███████╗   ██║      ██║   ╚██████╔╝   ██║   ██║  ██║███████╗██║██║ ╚████║██║  ██║╚██████╗   ██║   ██║ ╚████╔╝ ███████╗██║  ██║╚██████╔╝╚██████╔╝███████║███████╗███████║██║     ███████╗██║  ██║   ██║   ███████║
   ╚═════╝ ╚══════╝   ╚═╝      ╚═╝    ╚═════╝    ╚═╝   ╚═╝  ╚═╝╚══════╝╚═╝╚═╝  ╚═══╝╚═╝  ╚═╝ ╚═════╝   ╚═╝   ╚═╝  ╚═══╝  ╚══════╝╚═╝  ╚═╝ ╚═════╝  ╚═════╝ ╚══════╝╚══════╝╚══════╝╚═╝     ╚══════╝╚═╝  ╚═╝   ╚═╝   ╚══════╝

  */
  getTotalInactiveHousesFlats() {
    const total = this.streetBuildingService.streetsBuildings
      .reduce((sum, building) => sum + building.housesFlats.filter(houseFlat => !houseFlat.status).length, 0);
    return total;
  }

  /*
  ██████╗ ███████╗██╗     ███████╗████████╗███████╗
  ██╔══██╗██╔════╝██║     ██╔════╝╚══██╔══╝██╔════╝
  ██║  ██║█████╗  ██║     █████╗     ██║   █████╗
  ██║  ██║██╔══╝  ██║     ██╔══╝     ██║   ██╔══╝
  ██████╔╝███████╗███████╗███████╗   ██║   ███████╗
  ╚═════╝ ╚══════╝╚══════╝╚══════╝   ╚═╝   ╚══════╝

  */
  async delete(
    streetBuilding: StreetBuilding
  ) {

    const flag = confirm(
      `¿Esta seguro que desea borrar la calle ${streetBuilding.name}?`
    );

    if(flag) {
      await this.streetBuildingService.delete(
        streetBuilding
      );
    }

  }

  /*
   ██████╗ ██████╗ ███████╗███╗   ██╗
  ██╔═══██╗██╔══██╗██╔════╝████╗  ██║
  ██║   ██║██████╔╝█████╗  ██╔██╗ ██║
  ██║   ██║██╔═══╝ ██╔══╝  ██║╚██╗██║
  ╚██████╔╝██║     ███████╗██║ ╚████║
   ╚═════╝ ╚═╝     ╚══════╝╚═╝  ╚═══╝

  */
  open(
    streetBuilding?: StreetBuilding,
  ) {
    const modalRef = this.modalService.open(
      StreetBuildingModalComponent
    );

    modalRef.componentInstance.streetBuilding = streetBuilding;
    modalRef.componentInstance.neighborhoodId = this.neighborhoodId;

  }

  async uploadExcel(event: any) {

    const inputElement = document.createElement('input');
    inputElement.type = 'file';
    inputElement.accept = '.xlsx, .xls, .csv';
    inputElement.onchange = (event: any) => this.importHouses(event);
    inputElement.click();

  }

  async importHouses(event: any) {

    console.log(event);
    

    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = async (e: any) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const json = XLSX.utils.sheet_to_json(worksheet);
      console.log(json);

      const houses = json as {name: string, number: number, streetBuildingId: string}[];

      const flag = confirm(
        `¿Esta seguro que desea importar ${houses.length} casas?`
      );

      // console.log(houses);
      
      if(flag) {

        for(let i = 0; i < houses.length; i++) {

          await this.houseFlatService.create(
            this.neighborhoodId!,
            houses[i].streetBuildingId,
            {
              number: `#${houses[i].number}`,
              fullName: '',
              phone: '',
              notes: '',
              status: true,
            } as IHouseFlat,
            true,
            `- Calle ${houses[i].name} - casa ${i + 1}/${houses.length}`
          )
  
        }

      }

    };

    reader.readAsArrayBuffer(file);
  }

}
