import { Menu } from "../models/menu.model";

export const MENU: Array<Menu> = [
  {
    icon: 'dashboard',
    route: '/dashboard',
    title: 'Dashboard',
  },
  {
    icon: 'home',
    route: 'neighborhoods',
    title: 'Colonias',
  },
  {
    icon: 'category',
    title: 'Catálogos',
    children: [
      {
        icon: '',
        title: 'Tipos de colonias',
        route: 'catalogues/neighborhood-types',
      },
      {
        icon: '',
        title: 'Tipos de pruebas',
        route: 'catalogues/proof-types',
      },
      {
        icon: '',
        title: 'Tipos de visita',
        route: 'catalogues/visit-types',
      },
    ]
  },
  {
    icon: 'security',
    title: 'Seguridad',
    children: [
      {
        icon: '',
        title: 'Módulos',
        route: 'security/modules',
      },
      {
        icon: '',
        title: 'Perfiles',
        route: 'security/profiles',
      },
      {
        icon: '',
        title: 'Usuarios',
        route: 'security/users',
      },
      
    ]
  }
];