// Neighborhood.model.ts

import { IVisitType, VisitType } from "./visit-type.model";

export interface INeighborhood {
  neighborhoodId: string;
  neighborhoodTypeId: string;
  name: string;
  street: string;
  city: string;
  state: string;
  zipCode: string;
  fileId?: string;
  proofsRequired: boolean;
  platesRequired: boolean;
  brandRequired: boolean;
  modelRequired: boolean;
  colorRequired: boolean;
  accountsPerProperty: number;
  validityPeriodVisits: number;
  numberProperties: number;
  devicesPerProperty: number;
  maintenanceFee: number;
  payday: number;
  website: string;
  test: boolean;
  status: boolean;
  timeZone: number;
  dueDate?: string;
  uidCreatedBy?: string; // Usando ? para indicar que es opcional
  uidUpdatedBy?: string; // Usando ? para indicar que es opcional
  createdAt: string;
  updatedAt: string;
  visitTypes?: IVisitType[];
}

export class Neighborhood {
  neighborhoodId: string;
  neighborhoodTypeId: string;
  name: string;
  street: string;
  city: string;
  state: string;
  zipCode: string;
  fileId?: string;
  proofsRequired: boolean;
  platesRequired: boolean;
  brandRequired: boolean;
  modelRequired: boolean;
  colorRequired: boolean;
  accountsPerProperty: number;
  validityPeriodVisits: number;
  numberProperties: number;
  devicesPerProperty: number;
  maintenanceFee: number;
  payday: number;
  website: string;
  test: boolean;
  status: boolean;
  timeZone: number;
  dueDate?: Date;
  uidCreatedBy?: string; // Usando ? para indicar que es opcional
  uidUpdatedBy?: string; // Usando ? para indicar que es opcional
  createdAt: Date;
  updatedAt: Date;
  visitTypes: VisitType[];

  constructor(data: INeighborhood) {
    this.neighborhoodId = data.neighborhoodId;
    this.neighborhoodTypeId = data.neighborhoodTypeId;
    this.name = data.name;
    this.street = data.street;
    this.city = data.city;
    this.state = data.state;
    this.zipCode = data.zipCode;
    this.fileId = data.fileId;
    this.proofsRequired = data.proofsRequired;
    this.platesRequired = data.platesRequired;
    this.brandRequired = data.brandRequired;
    this.modelRequired = data.modelRequired;
    this.colorRequired = data.colorRequired;
    this.accountsPerProperty = data.accountsPerProperty;
    this.validityPeriodVisits = data.validityPeriodVisits;
    this.numberProperties = data.numberProperties;
    this.devicesPerProperty = data.devicesPerProperty;
    this.maintenanceFee = data.maintenanceFee;
    this.payday = data.payday;
    this.website = data.website;
    this.test = data.test;
    this.status = data.status;
    this.timeZone = data.timeZone;
    this.dueDate = data.dueDate
      ? new Date(data.dueDate)
      : undefined;
    this.uidCreatedBy = data.uidCreatedBy;
    this.uidUpdatedBy = data.uidUpdatedBy;
    this.createdAt = new Date(data.createdAt);
    this.updatedAt = new Date(data.updatedAt);
    this.visitTypes = data.visitTypes
      ? data.visitTypes.map(
          (visitType) => new VisitType(visitType)
        )
      : [];
  }

  get fullAddress(): string {
    return `${this.city}, ${this.state}, ${this.zipCode}`;
  }

}
