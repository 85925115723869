export interface AaUser {
}
// AppUser.model.ts

export interface IAppUser {
  profile: string;
  names: string;
  paternalLastName: string;
  maternalLastName: string;
  uid: string;
  email: string;
  temporaryPassword: boolean;
  lastLoginAt: string;
  createdAt: string;
  updatedAt: string;
}

export class AppUser {
  profile: string;
  names: string;
  paternalLastName: string;
  maternalLastName: string;
  uid: string;
  email: string;
  temporaryPassword: boolean;
  lastLoginAt: Date;
  createdAt: Date;
  updatedAt: Date;

  constructor(data: IAppUser) {
    this.profile = data.profile;
    this.names = data.names;
    this.paternalLastName = data.paternalLastName;
    this.maternalLastName = data.maternalLastName;
    this.uid = data.uid;
    this.email = data.email;
    this.temporaryPassword = data.temporaryPassword;
    this.lastLoginAt = new Date(data.lastLoginAt);
    this.createdAt = new Date(data.createdAt);
    this.updatedAt = new Date(data.updatedAt);
  }

  get fullName(): string {
    return `${this.names} ${this.paternalLastName} ${this.maternalLastName}`;
  }

}
