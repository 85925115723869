import { Component } from '@angular/core';

@Component({
  selector: 'app-settlers-page',
  templateUrl: './settlers-page.component.html',
  styleUrl: './settlers-page.component.scss'
})
export class SettlersPageComponent {

}
