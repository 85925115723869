// HouseFlat.model.ts

import { IInvitation, Invitation } from "./invitation.model";
import { IResident, Resident } from "./resident.model";

export interface IHouseFlat {
  houseFlatId: string;
  streetBuildingId: string;
  number: string;
  status: boolean;
  uidCreatedBy?: string; // Usando ? para indicar que es opcional
  uidUpdatedBy?: string; // Usando ? para indicar que es opcional
  createdAt: string;
  updatedAt: string;
  invitations: IInvitation[];
  residents: IResident[];
}

export class HouseFlat {
  houseFlatId: string;
  streetBuildingId: string;
  number: string;
  status: boolean;
  uidCreatedBy?: string; // Usando ? para indicar que es opcional
  uidUpdatedBy?: string; // Usando ? para indicar que es opcional
  createdAt: Date;
  updatedAt: Date;
  invitations: Invitation[];
  residents: Resident[];

  constructor(data: IHouseFlat) {
    this.houseFlatId = data.houseFlatId;
    this.streetBuildingId = data.streetBuildingId;
    this.number = data.number;
    this.status = data.status;
    this.uidCreatedBy = data.uidCreatedBy;
    this.uidUpdatedBy = data.uidUpdatedBy;
    this.createdAt = new Date(data.createdAt);
    this.updatedAt = new Date(data.updatedAt);
    this.invitations = data.invitations.map(
      (invitation) => new Invitation(invitation)
    );
    this.residents = data.residents.map(
      (resident) => new Resident(resident)
    );
  }
}
