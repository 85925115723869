import { Component, Input } from '@angular/core';
import { HouseFlat } from '../../models/house-flat.model';
import { StreetBuilding } from '../../models/street-building.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UtilService } from '../../services/util.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Resident } from '../../models/resident.model';
import { InvitationService } from '../../services/invitation.service';
import { ResidentService } from '../../services/resident.service';
import { IInvitation, Invitation } from '../../models/invitation.model';

@Component({
  selector: 'app-residents-modal',
  templateUrl: './residents-modal.component.html',
  styleUrl: './residents-modal.component.scss'
})
export class ResidentsModalComponent {

  @Input() houseFlat?: HouseFlat;
  @Input() streetBuilding?: StreetBuilding;

  public formInvitation: FormGroup;
  public submitted: boolean = false;

  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private invitationService: InvitationService,
    private residentService: ResidentService,
    public utilService: UtilService,
  ) {
    this.formInvitation = this.formBuilder.group({
      email: ['', Validators.required],
    });
  }

  get formControls() {
    return this.formInvitation.controls;
  }

  async deleteInvitation(
    invitation: Invitation
  ) {

    await this.invitationService.delete(
      this.streetBuilding!.neighborhoodId,
      this.streetBuilding!.streetBuildingId,
      invitation
    );

  }

  async deleteResident(
    resident: Resident
  ) {

    await this.residentService.delete(
      this.streetBuilding!.neighborhoodId,
      this.streetBuilding!.streetBuildingId,
      resident
    );

  }

  /*
   ██████╗ ███╗   ██╗███████╗██╗   ██╗██████╗ ███╗   ███╗██╗████████╗
  ██╔═══██╗████╗  ██║██╔════╝██║   ██║██╔══██╗████╗ ████║██║╚══██╔══╝
  ██║   ██║██╔██╗ ██║███████╗██║   ██║██████╔╝██╔████╔██║██║   ██║
  ██║   ██║██║╚██╗██║╚════██║██║   ██║██╔══██╗██║╚██╔╝██║██║   ██║
  ╚██████╔╝██║ ╚████║███████║╚██████╔╝██████╔╝██║ ╚═╝ ██║██║   ██║
   ╚═════╝ ╚═╝  ╚═══╝╚══════╝ ╚═════╝ ╚═════╝ ╚═╝     ╚═╝╚═╝   ╚═╝

  */
  async onSubmit() {
    this.submitted = true;
    if (this.formInvitation.invalid) {
      return;
    }

    await this.invitationService.create(
      this.streetBuilding!.neighborhoodId,
      this.streetBuilding!.streetBuildingId,
      this.houseFlat!.houseFlatId,
      this.formInvitation.value,
    )

  }

  /* -------------------------------------------------------------------------- */
  /*                              resendInvitation                              */
  /* -------------------------------------------------------------------------- */
  resendInvitation(
    invitation: any
  ) {
    this.invitationService.create(
      this.streetBuilding!.neighborhoodId,
      this.streetBuilding!.streetBuildingId,
      this.houseFlat!.houseFlatId,
      invitation
    );
  }

}
