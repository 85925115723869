import { Component } from '@angular/core';
import { NeighborhoodTypeService } from '../../../services/neighborhood-type.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NeighborhoodType } from '../../../models/neighborhood-type.model';
import { NeighborhoodTypeModalComponent } from '../../../components/neighborhood-type-modal/neighborhood-type-modal.component';

@Component({
  selector: 'app-neighborhood-types-page',
  templateUrl: './neighborhood-types-page.component.html',
  styleUrl: './neighborhood-types-page.component.scss'
})
export class NeighborhoodTypesPageComponent {

  constructor(
    public neighborhoodTypeService: NeighborhoodTypeService,
    private modalService: NgbModal,
  ) {

  }

  /*
  ██████╗ ███████╗██╗     ███████╗████████╗███████╗
  ██╔══██╗██╔════╝██║     ██╔════╝╚══██╔══╝██╔════╝
  ██║  ██║█████╗  ██║     █████╗     ██║   █████╗
  ██║  ██║██╔══╝  ██║     ██╔══╝     ██║   ██╔══╝
  ██████╔╝███████╗███████╗███████╗   ██║   ███████╗
  ╚═════╝ ╚══════╝╚══════╝╚══════╝   ╚═╝   ╚══════╝

  */
  async delete(
    neighBorhoodType: NeighborhoodType
  ) {

    const flag = confirm(
      `¿Esta seguro que desea borrar el tipo de colonia ${neighBorhoodType.name}?`
    );

    if(flag) {
      await this.neighborhoodTypeService.delete(
        neighBorhoodType
      );
    }

  }

  /*
   ██████╗ ██████╗ ███████╗███╗   ██╗
  ██╔═══██╗██╔══██╗██╔════╝████╗  ██║
  ██║   ██║██████╔╝█████╗  ██╔██╗ ██║
  ██║   ██║██╔═══╝ ██╔══╝  ██║╚██╗██║
  ╚██████╔╝██║     ███████╗██║ ╚████║
   ╚═════╝ ╚═╝     ╚══════╝╚═╝  ╚═══╝

  */
  open(
    neighBorhoodType?: NeighborhoodType,
  ) {
    const modalRef = this.modalService.open(
      NeighborhoodTypeModalComponent
    );

    modalRef.componentInstance.neighBorhoodType = neighBorhoodType;

  }

}
