import { Component, Input } from '@angular/core';
import { StreetBuilding } from '../../models/street-building.model';
import { DataFormModel } from '../../models/data-form-model.model';
import { FormGroup, Validators } from '@angular/forms';
import { StreetBuildingService } from '../../services/street-building.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UtilService } from '../../services/util.service';

@Component({
  selector: 'app-street-building-modal',
  templateUrl: './street-building-modal.component.html',
  styleUrl: './street-building-modal.component.scss'
})
export class StreetBuildingModalComponent {

  @Input() streetBuilding?: StreetBuilding;
  @Input() neighborhoodId?: string;

  public dataForm: DataFormModel = {
    name: {
      value: '',
      type: 'text',
      label: 'Nombre',
      requiredMessage: 'Por favor, ingrese el nombre de la calle',
      required: true,
      validators: [Validators.required]
    },
  };

  public submitted: boolean = false;
  public loading: boolean = false;

  public formStreetBuilding!: FormGroup;

  constructor(
    public activeModal: NgbActiveModal,
    public streetBuildingService: StreetBuildingService,
    public utilService: UtilService,
  ) {}

  ngOnInit(): void {
    this.loadData();
  }

  get f() {
    return this.formStreetBuilding.controls;
  }


  /*
   ██████╗██████╗ ███████╗ █████╗ ████████╗███████╗
  ██╔════╝██╔══██╗██╔════╝██╔══██╗╚══██╔══╝██╔════╝
  ██║     ██████╔╝█████╗  ███████║   ██║   █████╗
  ██║     ██╔══██╗██╔══╝  ██╔══██║   ██║   ██╔══╝
  ╚██████╗██║  ██║███████╗██║  ██║   ██║   ███████╗
   ╚═════╝╚═╝  ╚═╝╚══════╝╚═╝  ╚═╝   ╚═╝   ╚══════╝

  */
   async create() {

    const flag = await this.streetBuildingService.create(
      this.neighborhoodId!,
      this.formStreetBuilding.value,
    );

    if(flag) {
      for(let key of Object.keys(this.dataForm)) {
        this.dataForm[key].value = '';
      }

      this.activeModal.close();
    }

  }

  /*
  ██╗      ██████╗  █████╗ ██████╗ ██████╗  █████╗ ████████╗ █████╗
  ██║     ██╔═══██╗██╔══██╗██╔══██╗██╔══██╗██╔══██╗╚══██╔══╝██╔══██╗
  ██║     ██║   ██║███████║██║  ██║██║  ██║███████║   ██║   ███████║
  ██║     ██║   ██║██╔══██║██║  ██║██║  ██║██╔══██║   ██║   ██╔══██║
  ███████╗╚██████╔╝██║  ██║██████╔╝██████╔╝██║  ██║   ██║   ██║  ██║
  ╚══════╝ ╚═════╝ ╚═╝  ╚═╝╚═════╝ ╚═════╝ ╚═╝  ╚═╝   ╚═╝   ╚═╝  ╚═╝

  */
  async loadData() {
    this.loading = true;

    if(this.streetBuilding) {

      this.dataForm = {
        ...this.dataForm,
      }

      for(let key of Object.keys(this.dataForm)) {

        if (key in this.streetBuilding!) {
          const standardKey = key as keyof typeof this.streetBuilding;
      
          // Ahora puedes acceder a `this.machine[machineKey]` sin errores de tipo.
          this.dataForm[key].value = this.streetBuilding[standardKey]!;
        }
      } 
    }
    
    this.formStreetBuilding = this.utilService.buildForm(this.dataForm);

    this.loading = false;
  }

  /*
   ██████╗ ███╗   ██╗███████╗██╗   ██╗██████╗ ███╗   ███╗██╗████████╗
  ██╔═══██╗████╗  ██║██╔════╝██║   ██║██╔══██╗████╗ ████║██║╚══██╔══╝
  ██║   ██║██╔██╗ ██║███████╗██║   ██║██████╔╝██╔████╔██║██║   ██║
  ██║   ██║██║╚██╗██║╚════██║██║   ██║██╔══██╗██║╚██╔╝██║██║   ██║
  ╚██████╔╝██║ ╚████║███████║╚██████╔╝██████╔╝██║ ╚═╝ ██║██║   ██║
   ╚═════╝ ╚═╝  ╚═══╝╚══════╝ ╚═════╝ ╚═════╝ ╚═╝     ╚═╝╚═╝   ╚═╝

  */
  async onSubmit() {

    console.log(this.formStreetBuilding.value);
    
    this.submitted = true;

    if(this.formStreetBuilding.invalid) {
      return;
    }

    if(this.streetBuilding) {

      await this.update();
    } else {
      await this.create();
    }
  }

  /*
  ██╗   ██╗██████╗ ██████╗  █████╗ ████████╗███████╗
  ██║   ██║██╔══██╗██╔══██╗██╔══██╗╚══██╔══╝██╔════╝
  ██║   ██║██████╔╝██║  ██║███████║   ██║   █████╗
  ██║   ██║██╔═══╝ ██║  ██║██╔══██║   ██║   ██╔══╝
  ╚██████╔╝██║     ██████╔╝██║  ██║   ██║   ███████╗
   ╚═════╝ ╚═╝     ╚═════╝ ╚═╝  ╚═╝   ╚═╝   ╚══════╝

  */
  async update() {

    this.formStreetBuilding.value.streetBuildingId = this.streetBuilding!.streetBuildingId;

    const flag = await this.streetBuildingService.update(
      this.formStreetBuilding.value
    );

    if(flag) {

      for(let key of Object.keys(this.dataForm)) {
        this.dataForm[key].value = '';
      }

      this.activeModal.close();
    }
  }

}
