import { AccessDoorGroupType, IAccessDoorGroupType } from "./access-door-group-type.model";
import { AccessDoor, IAccessDoor } from "./access-door.model";

export interface IAccessDoorGroup {
  accessDoorGroupId: string;
  accessDoorGroupTypeId: string;
  gateId: string;
  accessDoorGroup: string;
  status: boolean;
  uidCreatedBy: string;
  uidUpdatedBy: string;
  createdAt: string;
  updatedAt: string;
  accessDoorGroupType?: IAccessDoorGroupType;
  accessDoors?: IAccessDoor[];
}

export class AccessDoorGroup {
  accessDoorGroupId: string;
  accessDoorGroupTypeId: string;
  gateId: string;
  accessDoorGroup: string;
  status: boolean;
  uidCreatedBy: string;
  uidUpdatedBy: string;
  createdAt: string;
  updatedAt: string;
  accessDoorGroupType?: AccessDoorGroupType;
  accessDoors: AccessDoor[];

  constructor(data: IAccessDoorGroup) {
    this.accessDoorGroupId = data.accessDoorGroupId;
    this.accessDoorGroupTypeId = data.accessDoorGroupTypeId;
    this.gateId = data.gateId;
    this.accessDoorGroup = data.accessDoorGroup;
    this.status = data.status;
    this.uidCreatedBy = data.uidCreatedBy;
    this.uidUpdatedBy = data.uidUpdatedBy;
    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;
    this.accessDoorGroupType = data.accessDoorGroupType
      ? new AccessDoorGroupType(data.accessDoorGroupType)
      : undefined;
    this.accessDoors = data.accessDoors
      ? data.accessDoors.map(
          (accessDoor) => new AccessDoor(accessDoor)
        )
      : [];
  }
}
