import { Component, Input } from '@angular/core';
import { DataFormModel } from '../../models/data-form-model.model';
import { FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UtilService } from '../../services/util.service';
import { TagType } from '../../models/tag-type.model';
import { TagTypeService } from '../../services/tag-type.service';

@Component({
  selector: 'app-tag-type-modal',
  templateUrl: './tag-type-modal.component.html',
  styleUrl: './tag-type-modal.component.scss'
})
export class TagTypeModalComponent {

  @Input() tagType?: TagType;
  
  public dataForm: DataFormModel = {
    image: {
      value: '',
      type: 'image',
      label: 'Imagen',
      required: false,
      validators: []
    },
    description: {
      value: '',
      type: 'text',
      label: 'Descripción',
      requiredMessage: 'Por favor, ingrese la descripción',
      required: true,
      validators: [Validators.required]
    },
    forVehicles: {
      value: false,
      type: 'switch',
      label: 'Para vehículos',
      requiredMessage: '',
      required: false,
      validators: []
    },
  };

  public formTagType!: FormGroup;
  public submitted: boolean = false;
  public loading: boolean = false;
  public imageBase64: string | null = null;

  constructor(
    public activeModal: NgbActiveModal,
    public tagTypeService: TagTypeService,
    public utilService: UtilService
  ) {}

  ngOnInit(): void {
    this.loadData();
  }

  get f() {
    return this.formTagType.controls;
  }

  async create() {
    this.formTagType.value.imageBase64 = this.imageBase64;

    if(this.imageBase64) {
      let fileType = 'image';
      if(this.imageBase64.includes('data:application/pdf')) {
        fileType = 'pdf';
      }
      this.formTagType.value.fileType = fileType;
    }

    const flag = await this.tagTypeService.create(
      this.formTagType.value
    );

    if(flag) {
      for(let key of Object.keys(this.dataForm)) {
        this.dataForm[key].value = '';
      }
      this.activeModal.close();
    }
  }

  async loadData() {
    this.loading = true;

    if(this.tagType) {
      this.dataForm = {
        ...this.dataForm
      };

      for(let key of Object.keys(this.dataForm)) {
        if (key in this.tagType!) {
          const tagKey = key as keyof typeof this.tagType;
          this.dataForm[key].value = this.tagType[tagKey]!;
        }
      }
    }

    this.formTagType = this.utilService.buildForm(this.dataForm);
    this.loading = false;
  }

  onFileChange(event: any) {
    const file = event.target.files[0];

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.imageBase64 = reader.result as string;
    };
  }

  async onSubmit() {
    this.submitted = true;

    if(this.formTagType.invalid) {
      return;
    }

    if(this.tagType) {
      await this.update();
    } else {
      await this.create();
    }
  }

  async update() {
    this.formTagType.value.tagTypeId = this.tagType!.tagTypeId;
    this.formTagType.value.imageBase64 = this.imageBase64;

    const flag = await this.tagTypeService.update(
      this.formTagType.value
    );

    if(flag) {
      this.activeModal.close();
    }
  }

}
