import { Component, Input } from '@angular/core';
import { SlideMenuService } from '../../services/slide-menu.service';
import { Menu } from '../../models/menu.model';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrl: './side-menu.component.scss'
})
export class SideMenuComponent {

  @Input() menuData: Array<Menu> = [];

  constructor(
    public slideMenuService: SlideMenuService
  ) {

  }

  toggleSubmenu(doc: any) {
    doc.isOpen = !doc.isOpen;
  }

}
