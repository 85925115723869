import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ChargeService } from '../../../services/charge.service';
import { Charge } from '../../../models/charge.model';
import { HouseFlat } from '../../../models/house-flat.model';
import { HouseFlatModalComponent } from '../../../components/house-flat-modal/house-flat-modal.component';
import { HouseFlatService } from '../../../services/house-flat.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PaymentModalComponent } from '../../../components/payment-modal/payment-modal.component';
import { StreetBuilding } from '../../../models/street-building.model';
import * as XLSX from 'xlsx';
import moment from 'moment';
import { NeighborhoodService } from '../../../services/neighborhood.service';

@Component({
  selector: 'app-payment-page',
  templateUrl: './payment-page.component.html',
  styleUrl: './payment-page.component.scss'
})
export class PaymentPageComponent {

  public neighborhoodId: string = '';
  public chargeId: string = '';
  public charge?: Charge;

  constructor(
    private activatedRoute: ActivatedRoute,
    public chargeService: ChargeService,
    public houseFlatService: HouseFlatService,
    private modalService: NgbModal,
    public neighborhoodService: NeighborhoodService,
  ) {

    this.activatedRoute.params.subscribe(
      async params => {

        // console.log(params);

        this.neighborhoodId = params['neighborhoodId'];          
        this.chargeId = params['paymentId'];          

        this.charge = await this.chargeService.get(
          this.neighborhoodId!,
          this.chargeId!
        );
      }
    );

  }

  /*
  ██████╗  ██████╗ ██╗    ██╗███╗   ██╗██╗      ██████╗  █████╗ ██████╗ ██████╗ ███████╗██████╗  ██████╗ ██████╗ ████████╗
  ██╔══██╗██╔═══██╗██║    ██║████╗  ██║██║     ██╔═══██╗██╔══██╗██╔══██╗██╔══██╗██╔════╝██╔══██╗██╔═══██╗██╔══██╗╚══██╔══╝
  ██║  ██║██║   ██║██║ █╗ ██║██╔██╗ ██║██║     ██║   ██║███████║██║  ██║██████╔╝█████╗  ██████╔╝██║   ██║██████╔╝   ██║
  ██║  ██║██║   ██║██║███╗██║██║╚██╗██║██║     ██║   ██║██╔══██║██║  ██║██╔══██╗██╔══╝  ██╔═══╝ ██║   ██║██╔══██╗   ██║
  ██████╔╝╚██████╔╝╚███╔███╔╝██║ ╚████║███████╗╚██████╔╝██║  ██║██████╔╝██║  ██║███████╗██║     ╚██████╔╝██║  ██║   ██║
  ╚═════╝  ╚═════╝  ╚══╝╚══╝ ╚═╝  ╚═══╝╚══════╝ ╚═════╝ ╚═╝  ╚═╝╚═════╝ ╚═╝  ╚═╝╚══════╝╚═╝      ╚═════╝ ╚═╝  ╚═╝   ╚═╝

  */
  downloadReport() {

    const data: any[] = [];
    const mergeRanges: any[] = [];

    let currentIndex = 0, 
      noPaid = 0, 
      validating = 0, 
      paid = 0, 
      noPaidAmount = 0, 
      validatingAmount = 0, 
      paidAmount = 0;

    this.charge!.streetsBuildings.forEach(
      (streetBuilding, index) => {
        data.push([
          streetBuilding.name
        ]);

        mergeRanges.push({
          s: { r: currentIndex, c: 0 }, // Celda de inicio (row, column)
          e: { r: currentIndex, c: 5 }  // Celda de fin (row, column) - 6 columnas combinadas
        });

        currentIndex++;
        data.push([
          'NÚMERO',
          'NOMBRE',
          'TELÉFONO',
          'NOTAS',
          'MONTO',
          'ESTATUS DE PAGO',
          'NOTAS DEL PAGO',
          'EXTRA'
        ]);

        streetBuilding.housesFlats.forEach(
          (houseFlat, jindex) => {
            currentIndex++;

            if(houseFlat.payment) {

              if(houseFlat.payment?.status != null) {
                if(houseFlat.payment.status) {
                  paid++;
                  paidAmount += houseFlat.payment.charge;
                } else {
                  validating++;
                  validatingAmount += houseFlat.payment.charge;
                }
              } else {
                noPaid++;
                noPaidAmount += houseFlat.payment.charge;
              }

            }

            data.push([
              houseFlat.number,
              houseFlat.fullName,
              houseFlat.phone,
              houseFlat.notes,
              houseFlat.payment?.charge,
              houseFlat.payment?.status != null ? houseFlat.payment.status ? 'Pagado' : 'Validando' : 'No pagado',
              houseFlat.notes,
              ''
            ]);
          }
        );

        currentIndex++;
      }
    );

    console.log(data);

    // Crear una hoja de Excel
    const worksheet = XLSX.utils.aoa_to_sheet(data);

    worksheet['!merges'] = mergeRanges;

    // Calcular el ancho máximo de cada columna
    const columnWidths = data[1].map((_: any, colIndex: string | number) => {
      const maxLength = data.reduce((max, row) => {
        const cell = row[colIndex];
        const cellLength = cell ? cell.toString().length : 0;
        return Math.max(max, cellLength);
      }, 0);
      return { wch: maxLength + 2 }; // Ajuste adicional para margen
    });

    // Asignar los anchos de columna al worksheet
    worksheet['!cols'] = columnWidths;

    // Crear un libro de trabajo y agregar la hoja
    const workbook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(
      workbook, 
      worksheet,
      moment(this.charge!.createdAt).add(
        this.neighborhoodService.currentNeighborhood?.timeZone, 'hours'
      ).format('MMMM YYYY')
    );

    const dataResume = [
      ['Estatus', 'No. de predios', 'Monto total'],
      ['No pagados', noPaid, noPaidAmount],
      ['Validando', validating, validatingAmount],
      ['Pagados', paid, paidAmount],
    ]

    const worksheetResume = XLSX.utils.aoa_to_sheet(dataResume);
    XLSX.utils.book_append_sheet(
      workbook, 
      worksheetResume,
      'Resumen'
    );

    // Exportar el archivo XLSX
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

    // Crear un Blob para la descarga
    const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });

    // Crear un enlace de descarga
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);

    const shorDate = moment(this.charge!.createdAt).add(
      this.neighborhoodService.currentNeighborhood?.timeZone, 'hours'
    ).format('YYYYMM')

    // Asignar el nombre del archivo
    link.download = `Reporte de mantenimeinto ${this.neighborhoodService.currentNeighborhood?.name} ${shorDate}.xlsx`;

    // Disparar la descarga
    link.click();

  }

  /*
  ██╗   ██╗██████╗ ██████╗  █████╗ ████████╗███████╗
  ██║   ██║██╔══██╗██╔══██╗██╔══██╗╚══██╔══╝██╔════╝
  ██║   ██║██████╔╝██║  ██║███████║   ██║   █████╗
  ██║   ██║██╔═══╝ ██║  ██║██╔══██║   ██║   ██╔══╝
  ╚██████╔╝██║     ██████╔╝██║  ██║   ██║   ███████╗
   ╚═════╝ ╚═╝     ╚═════╝ ╚═╝  ╚═╝   ╚═╝   ╚══════╝

  */
  async update(
    houseFlat: HouseFlat
  ) {
    const flag = await this.houseFlatService.update(
      this.neighborhoodId,
      houseFlat
    );

    if(!flag) {
      houseFlat.status = !houseFlat.status;
    }
  }

  /*
    ██████╗ ██████╗ ███████╗███╗   ██╗██╗  ██╗ ██████╗ ██╗   ██╗███████╗███████╗███████╗██╗      █████╗ ████████╗
  ██╔═══██╗██╔══██╗██╔════╝████╗  ██║██║  ██║██╔═══██╗██║   ██║██╔════╝██╔════╝██╔════╝██║     ██╔══██╗╚══██╔══╝
  ██║   ██║██████╔╝█████╗  ██╔██╗ ██║███████║██║   ██║██║   ██║███████╗█████╗  █████╗  ██║     ███████║   ██║
  ██║   ██║██╔═══╝ ██╔══╝  ██║╚██╗██║██╔══██║██║   ██║██║   ██║╚════██║██╔══╝  ██╔══╝  ██║     ██╔══██║   ██║
  ╚██████╔╝██║     ███████╗██║ ╚████║██║  ██║╚██████╔╝╚██████╔╝███████║███████╗██║     ███████╗██║  ██║   ██║
    ╚═════╝ ╚═╝     ╚══════╝╚═╝  ╚═══╝╚═╝  ╚═╝ ╚═════╝  ╚═════╝ ╚══════╝╚══════╝╚═╝     ╚══════╝╚═╝  ╚═╝   ╚═╝

  */
  openHouseFlat(
    houseFlat: HouseFlat,
  ) {
    const modalRef = this.modalService.open(
      HouseFlatModalComponent
    );

    modalRef.componentInstance.houseFlat = houseFlat;
    modalRef.componentInstance.neighborhoodId = this.neighborhoodId;
    modalRef.componentInstance.streetBuildingId = houseFlat.streetBuildingId;

    modalRef.closed.subscribe(
      async () => {
    
        this.charge = await this.chargeService.get(
          this.neighborhoodId!,
          this.chargeId!
        );

      }
    );

  }

  /*
   ██████╗ ██████╗ ███████╗███╗   ██╗██████╗  █████╗ ██╗   ██╗███╗   ███╗███████╗███╗   ██╗████████╗
  ██╔═══██╗██╔══██╗██╔════╝████╗  ██║██╔══██╗██╔══██╗╚██╗ ██╔╝████╗ ████║██╔════╝████╗  ██║╚══██╔══╝
  ██║   ██║██████╔╝█████╗  ██╔██╗ ██║██████╔╝███████║ ╚████╔╝ ██╔████╔██║█████╗  ██╔██╗ ██║   ██║
  ██║   ██║██╔═══╝ ██╔══╝  ██║╚██╗██║██╔═══╝ ██╔══██║  ╚██╔╝  ██║╚██╔╝██║██╔══╝  ██║╚██╗██║   ██║
  ╚██████╔╝██║     ███████╗██║ ╚████║██║     ██║  ██║   ██║   ██║ ╚═╝ ██║███████╗██║ ╚████║   ██║
   ╚═════╝ ╚═╝     ╚══════╝╚═╝  ╚═══╝╚═╝     ╚═╝  ╚═╝   ╚═╝   ╚═╝     ╚═╝╚══════╝╚═╝  ╚═══╝   ╚═╝

  */
  openPayment(
    streetBuilding: StreetBuilding,
    houseFlat: HouseFlat
  ) {

    const modalRef = this.modalService.open(
      PaymentModalComponent
    );

    modalRef.componentInstance.streetBuilding = streetBuilding;
    modalRef.componentInstance.houseFlat = houseFlat;
    modalRef.componentInstance.neighborhoodId = this.neighborhoodId;

    modalRef.closed.subscribe(
      async () => {
    
        this.charge = await this.chargeService.get(
          this.neighborhoodId!,
          this.chargeId!
        );

      }
    )
    
  }

}
