import { AppUser, IAppUser } from "./app-user.model";

export interface IInputOutput {
  inputOutputId: string;
  neighborhoodId: string;
  streetBuildingId: string;
  houseFlatId: string;
  visitId: string;
  status: boolean;
  uidCreatedBy?: string;
  createdAt: string;
  createdBy?: IAppUser;
}

export class InputOutput {
  inputOutputId: string;
  neighborhoodId: string;
  streetBuildingId: string;
  houseFlatId: string;
  visitId: string;
  status: boolean;
  uidCreatedBy?: string;
  createdAt: Date;
  createdBy?: AppUser;

  constructor(data: IInputOutput) {
    this.inputOutputId = data.inputOutputId;
    this.neighborhoodId = data.neighborhoodId;
    this.streetBuildingId = data.streetBuildingId;
    this.houseFlatId = data.houseFlatId;
    this.visitId = data.visitId;
    this.status = data.status;
    this.uidCreatedBy = data.uidCreatedBy;
    this.createdAt = new Date(data.createdAt);
    this.createdBy = data.createdBy 
      ? new AppUser(data.createdBy) 
      : undefined
  }
}
