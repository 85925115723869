import { FileMedia, IFileMedia } from "./file-media.model";

export interface IMovement {
  movementId: string;
  neighborhoodId: string;
  streetBuildingId?: string;
  houseFlatId?: string;
  fileId?: string;
  description: string;
  notes: string;
  movementType: boolean;
  status: boolean;
  amount: number;
  uidCreatedBy?: string;
  uidUpdatedBy?: string;
  createdAt: string;
  updatedAt: string;
  image?: IFileMedia;
}

export class Movement {
  movementId: string;
  neighborhoodId: string;
  streetBuildingId?: string;
  houseFlatId?: string;
  fileId?: string;
  description: string;
  notes: string;
  movementType: boolean;
  status: boolean;
  amount: number;
  uidCreatedBy?: string;
  uidUpdatedBy?: string;
  createdAt: Date;
  updatedAt: Date;
  image?: FileMedia;

  constructor(data: IMovement) {
    this.movementId = data.movementId;
    this.neighborhoodId = data.neighborhoodId;
    this.streetBuildingId = data.streetBuildingId;
    this.houseFlatId = data.houseFlatId;
    this.fileId = data.fileId;
    this.description = data.description;
    this.notes = data.notes;
    this.movementType = data.movementType;
    this.status = data.status;
    this.amount = data.amount;
    this.uidCreatedBy = data.uidCreatedBy;
    this.uidUpdatedBy = data.uidUpdatedBy;
    this.createdAt = new Date(data.createdAt);
    this.updatedAt = new Date(data.updatedAt);
    this.image = data.image 
      ? new FileMedia(data.image) 
      : undefined
  }

  get movementTypeDescription(): string {
    return this.movementType ? 'Ingreso' : 'Gasto';
  }

  get statusDescription(): string {
    let value: string = '';

    if(this.movementType) {

      if(this.status) {
        value = 'Cobrado';
      } else {
        value = 'Pendiente';
      }

    } else {

      if(this.status) {
        value = 'Pagado';
      } else {
        value = 'Pendiente';
      }

    }

    return value;
  }

}
