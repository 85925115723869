import { Component } from '@angular/core';

@Component({
  selector: 'app-neighborhood-modal',
  templateUrl: './neighborhood-modal.component.html',
  styleUrl: './neighborhood-modal.component.scss'
})
export class NeighborhoodModalComponent {

}
