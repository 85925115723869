import { NgModule } from '@angular/core';
import { HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule, NgbTooltipModule, NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { environment } from '../environments/environment';
import { ToastsComponent } from './components/toasts/toasts.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { SessionLayoutComponent } from './layouts/session-layout/session-layout.component';
import { LogInPageComponent } from './pages/log-in-page/log-in-page.component';
import { DashboardLayoutComponent } from './layouts/dashboard-layout/dashboard-layout.component';
import { SideBarComponent } from './components/side-bar/side-bar.component';
import { MenuItemComponent } from './components/menu-item/menu-item.component';
import { MainHeaderComponent } from './components/main-header/main-header.component';
import { DashboardPageComponent } from './pages/dashboard/dashboard-page/dashboard-page.component';
import { NeighborhoodsPageComponent } from './pages/dashboard/neighborhoods-page/neighborhoods-page.component';
import { SideMenuComponent } from './components/side-menu/side-menu.component';
import { NeighborhoodTypesPageComponent } from './pages/dashboard/neighborhood-types-page/neighborhood-types-page.component';
import { ProofTypesPageComponent } from './pages/dashboard/proof-types-page/proof-types-page.component';
import { VisitTypesPageComponent } from './pages/dashboard/visit-types-page/visit-types-page.component';
import { ModulesPageComponent } from './pages/dashboard/security/modules-page/modules-page.component';
import { ProfilesPageComponent } from './pages/dashboard/security/profiles-page/profiles-page.component';
import { UsersPageComponent } from './pages/dashboard/security/users-page/users-page.component';
import { ModuleModalComponent } from './components/module-modal/module-modal.component';
import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { BaseChartDirective } from 'ng2-charts';

// ANCHOR NGPRIME
import { TableModule } from 'primeng/table';
import { InputSwitchModule } from 'primeng/inputswitch';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { CarouselModule } from 'primeng/carousel';
import { ChartModule } from 'primeng/chart';

import { NeighborhoodTypeModalComponent } from './components/neighborhood-type-modal/neighborhood-type-modal.component';
import { ProofTypeModalComponent } from './components/proof-type-modal/proof-type-modal.component';
import { VisitTypeModalComponent } from './components/visit-type-modal/visit-type-modal.component';
import { NeighborhoodModalComponent } from './components/neighborhood-modal/neighborhood-modal.component';
import { NeighborhoodLayoutComponent } from './layouts/neighborhood-layout/neighborhood-layout.component';
import { NeighborhoodPageComponent } from './pages/neighborhood/neighborhood-page/neighborhood-page.component';
import { VisitsPageComponent } from './pages/neighborhood/visits-page/visits-page.component';
import { StreetsBuildingPageComponent } from './pages/neighborhood/streets-building-page/streets-building-page.component';
import { HousesFlatsPageComponent } from './pages/neighborhood/houses-flats-page/houses-flats-page.component';
import { SettlersPageComponent } from './pages/neighborhood/settlers-page/settlers-page.component';
import { SettingsModulesPageComponent } from './pages/neighborhood/settings/settings-modules-page/settings-modules-page.component';
import { SettingsProfilesPageComponent } from './pages/neighborhood/settings/settings-profiles-page/settings-profiles-page.component';
import { GeneralPageComponent } from './pages/neighborhood/settings/general-page/general-page.component';
import { StreetBuildingModalComponent } from './components/street-building-modal/street-building-modal.component';
import { StreetBuildingItemComponent } from './components/street-building-item/street-building-item.component';
import { HouseFlatModalComponent } from './components/house-flat-modal/house-flat-modal.component';
import { StreetBuildingItem2Component } from './components/street-building-item-2/street-building-item-2.component';
import { ResidentsModalComponent } from './components/residents-modal/residents-modal.component';
import { NotificationTypesPageComponent } from './pages/dashboard/catalogues/notification-types-page/notification-types-page.component';
import { NotificationTypeModalComponent } from './components/notification-type-modal/notification-type-modal.component';
import { ServicesPageComponent } from './pages/neighborhood/settings/services-page/services-page.component';
import { ServiceModalComponent } from './components/service-modal/service-modal.component';
import { AnnouncementsPageComponent } from './pages/neighborhood/announcements-page/announcements-page.component';
import { CreateAnnouncementModalComponent } from './components/create-announcement-modal/create-announcement-modal.component';
import { AnnouncementModalComponent } from './components/announcement-modal/announcement-modal.component';
import { GuardsPageComponent } from './pages/dashboard/guards-page/guards-page.component';
import { VisitListComponent } from './components/visit-list/visit-list.component';
import { VisitModalComponent } from './components/visit-modal/visit-modal.component';
import { NewlineToBrPipe } from './pipes/newline-to-br.pipe';
import { EmergencyNumbersPageComponent } from './pages/neighborhood/emergency-numbers-page/emergency-numbers-page.component';
import { EmergencyNumberModalComponent } from './components/emergency-number-modal/emergency-number-modal.component';
import { NeighborhoodItemComponent } from './components/neighborhood-item/neighborhood-item.component';
import { PaymentsPageComponent } from './pages/neighborhood/payments-page/payments-page.component';
import { PaymentPageComponent } from './pages/neighborhood/payment-page/payment-page.component';
import { BankAccountTypeModalComponent } from './components/bank-account-type-modal/bank-account-type-modal.component';
import { BankAccountTypesPageComponent } from './pages/dashboard/bank-account-types-page/bank-account-types-page.component';
import { BankAccountItemComponent } from './components/bank-account-item/bank-account-item.component';
import { BankAccountsPageComponent } from './pages/dashboard/bank-accounts-page/bank-accounts-page.component';
import { BankAccountModalComponent } from './components/bank-account-modal/bank-account-modal.component';
import { StreetBuildingPaymentItemComponent } from './components/street-building-payment-item/street-building-payment-item.component';
import { ChargeItemComponent } from './components/charge-item/charge-item.component';
import { DateToMomentPipe } from './pipes/date-to-moment.pipe';
import { PaymentModalComponent } from './components/payment-modal/payment-modal.component';
import { PlatformPageComponent } from './pages/dashboard/platform-page/platform-page.component';
import { PlatformsPageComponent } from './pages/dashboard/platforms-page/platforms-page.component';
import { ParcelServicesPageComponent } from './pages/dashboard/parcel-services-page/parcel-services-page.component';
import { ParcelServiceModalComponent } from './components/parcel-service-modal/parcel-service-modal.component';
import { GalleriaModule } from 'primeng/galleria';
import { ImageModule } from 'primeng/image';
import { MovementsPageComponent } from './pages/dashboard/movements-page/movements-page.component';
import { MovementModalComponent } from './components/movement-modal/movement-modal.component';
import { GatesPageComponent } from './pages/neighborhood/gates-page/gates-page.component';
import { GateModalComponent } from './components/gate-modal/gate-modal.component';
import { AccessDoorGroupTypeModalComponent } from './components/access-door-group-type-modal/access-door-group-type-modal.component';
import { AccessDoorGroupTypesPageComponent } from './pages/neighborhood/access-door-group-types-page/access-door-group-types-page.component';
import { TagTypeModalComponent } from './components/tag-type-modal/tag-type-modal.component';
import { TagTypesPageComponent } from './pages/dashboard/tag-types-page/tag-types-page.component';
import { DevicesModalComponent } from './components/devices-modal/devices-modal.component';
import { GatePageComponent } from './pages/dashboard/gate-page/gate-page.component';
import { AccessDoorGroupModalComponent } from './components/access-door-group-modal/access-door-group-modal.component';
import { AccessDoorModalComponent } from './components/access-door-modal/access-door-modal.component';



@NgModule({
  declarations: [
    AppComponent,
    ToastsComponent,
    SpinnerComponent,
    SessionLayoutComponent,
    LogInPageComponent,
    DashboardLayoutComponent,
    SideBarComponent,
    MenuItemComponent,
    MainHeaderComponent,
    DashboardPageComponent,
    NeighborhoodsPageComponent,
    SideMenuComponent,
    NeighborhoodTypesPageComponent,
    ProofTypesPageComponent,
    VisitTypesPageComponent,
    ModulesPageComponent,
    ProfilesPageComponent,
    UsersPageComponent,
    ModuleModalComponent,
    NeighborhoodTypeModalComponent,
    ProofTypeModalComponent,
    VisitTypeModalComponent,
    NeighborhoodModalComponent,
    NeighborhoodLayoutComponent,
    NeighborhoodPageComponent,
    VisitsPageComponent,
    StreetsBuildingPageComponent,
    HousesFlatsPageComponent,
    SettlersPageComponent,
    SettingsModulesPageComponent,
    SettingsProfilesPageComponent,
    GeneralPageComponent,
    StreetBuildingModalComponent,
    StreetBuildingItemComponent,
    HouseFlatModalComponent,
    StreetBuildingItem2Component,
    ResidentsModalComponent,
    NotificationTypesPageComponent,
    NotificationTypeModalComponent,
    ServicesPageComponent,
    ServiceModalComponent,
    AnnouncementsPageComponent,
    CreateAnnouncementModalComponent,
    AnnouncementModalComponent,
    GuardsPageComponent,
    VisitListComponent,
    VisitModalComponent,
    NewlineToBrPipe,
    EmergencyNumbersPageComponent,
    EmergencyNumberModalComponent,
    NeighborhoodItemComponent,
    PaymentsPageComponent,
    PaymentPageComponent,
    BankAccountTypeModalComponent,
    BankAccountTypesPageComponent,
    BankAccountItemComponent,
    BankAccountsPageComponent,
    BankAccountModalComponent,
    StreetBuildingPaymentItemComponent,
    ChargeItemComponent,
    DateToMomentPipe,
    PaymentModalComponent,
    PlatformPageComponent,
    PlatformsPageComponent,
    ParcelServicesPageComponent,
    ParcelServiceModalComponent,
    MovementsPageComponent,
    MovementModalComponent,
    GatesPageComponent,
    GateModalComponent,
    AccessDoorGroupTypeModalComponent,
    AccessDoorGroupTypesPageComponent,
    TagTypeModalComponent,
    TagTypesPageComponent,
    DevicesModalComponent,
    GatePageComponent,
    AccessDoorGroupModalComponent,
    AccessDoorModalComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    HttpClientModule,
    HttpClientJsonpModule,
    TableModule,
    InputSwitchModule,
    ButtonModule,
    DropdownModule,
    NgbCollapseModule,
    NgbTooltipModule,
    GalleriaModule,
    ImageModule,
    CarouselModule,
    NgbDatepickerModule,
    ChartModule,
  ],
  providers: [
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => getAuth())
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
