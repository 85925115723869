import { Menu } from "../models/menu.model";

export const MENU: Array<Menu> = [
  {
    icon: 'dashboard',
    route: '/dashboard',
    title: 'Dashboard',
  },
  {
    icon: 'home',
    route: 'neighborhoods',
    title: 'Colonias',
  },
  {
    icon: 'category',
    title: 'Catálogos',
    children: [
      {
        icon: '',
        title: 'Tipos de colonias',
        route: 'catalogues/neighborhood-types',
      },
      {
        icon: '',
        title: 'Tipos de pruebas',
        route: 'catalogues/proof-types',
      },
      {
        icon: '',
        title: 'Tipos de visita',
        route: 'catalogues/visit-types',
      },
    ]
  },
  {
    icon: 'security',
    title: 'Seguridad',
    children: [
      {
        icon: '',
        title: 'Módulos',
        route: 'security/modules',
      },
      {
        icon: '',
        title: 'Perfiles',
        route: 'security/profiles',
      },
      {
        icon: '',
        title: 'Usuarios',
        route: 'security/users',
      },
      
    ]
  }
];

export const MENU_NEIGH: Array<Menu> = [
  {
    icon: 'dashboard',
    route: ':neighborhoodId',
    title: 'Dashboard',
  },
  {
    icon: 'person',
    route: ':neighborhoodId/settlers',
    title: 'Colonos',
  },
  {
    icon: 'house',
    route: ':neighborhoodId/streets-buildings',
    title: 'Calles',
  },
  {
    icon: 'house',
    route: ':neighborhoodId/houses-flats',
    title: 'Casas',
  },
  {
    icon: 'confirmation_number',
    route: ':neighborhoodId/visits',
    title: 'Visitas',
  },
  {
    icon: 'settings',
    title: 'Configuración',
    children: [
      {
        icon: '',
        title: 'Módulos',
        route: ':neighborhoodId/settings/modules',
      },
      {
        icon: '',
        title: 'Perfiles',
        route: ':neighborhoodId/settings/profiles',
      },
      {
        icon: '',
        title: 'Tipos de prueba',
        route: ':neighborhoodId/settings/proof-types',
      },
      {
        icon: '',
        title: 'General',
        route: ':neighborhoodId/settings/general',
      },
      {
        icon: '',
        title: 'Usuarios',
        route: ':neighborhoodId/settings/users',
      },
      
    ]
  }
];