import { IMovementItemInsight, MovementItemInsight } from "./movement-item-insight.model";

export interface IMovementInsight {
  totalBalance: IMovementItemInsight;
  monthBalance: IMovementItemInsight;
  lastMonthBalance: IMovementItemInsight;
}

export class MovementInsight {
  totalBalance: MovementItemInsight;
  monthBalance: MovementItemInsight;
  lastMonthBalance: MovementItemInsight;

  constructor(data: IMovementInsight) {
    this.totalBalance = new MovementItemInsight(data.totalBalance);
    this.monthBalance = new MovementItemInsight(data.monthBalance);
    this.lastMonthBalance = new MovementItemInsight(data.lastMonthBalance);
  }
}
