<div class="container-fluid py-3">
  <div class="row">
    <div class="col-12">
      <p-table #dt
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        [filterDelay]="0"
        [loading]="tagTypeService.loading"
        [paginator]="true"
        [rows]="10"
        [rowHover]="true"
        [rowsPerPageOptions]="[10, 25, 50]"
        [showCurrentPageReport]="true" 
        [value]="tagTypeService.tagTypes"
        pDroppable="row">
        
        <ng-template pTemplate="caption">
          <div class="container-fluid">
            <div class="row">
              <div class="col-4 d-flex align-items-center">
                Tipos de Etiqueta
              </div>
              <div class="col-8 text-end">
                <button (click)="open()" class="btn btn-success rounded-circle me-2">
                  <i class="material-icons mt-1">add</i>
                </button>
                <button (click)="tagTypeService.getAll()" class="btn btn-primary rounded-circle me-2">
                  <i class="material-icons mt-1">refresh</i>
                </button>
              </div>
            </div>
          </div>
        </ng-template>

        <ng-template pTemplate="header">
          <tr>
            <th></th>
            <th pSortableColumn="tagTypeId">
              ID
              <p-sortIcon field="tagTypeId"></p-sortIcon>
              <p-columnFilter type="text" field="tagTypeId" display="menu"></p-columnFilter>
            </th>

            <th pSortableColumn="description">
              Descripción
              <p-sortIcon field="description"></p-sortIcon>
              <p-columnFilter type="text" field="description" display="menu"></p-columnFilter>
            </th>

            <th pSortableColumn="forVehicles">
              Para Vehículos
              <p-sortIcon field="forVehicles"></p-sortIcon>
              <p-columnFilter type="text" field="forVehicles" display="menu"></p-columnFilter>
            </th>

            <th>Acciones</th>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-tagType>
          <tr class="p-selectable-row">

            <td>
              @if(tagType.image) {
                <div 
                  class="bg-center"
                  style="width: 5vh; height: 5vh;"
                  [ngStyle]="{
                    'background-image': 'url(' + tagType.image.urlFile + ')'
                  }">
                </div>
              }
            </td>

            <td>
              <span class="p-column-title">ID</span>
              {{tagType.tagTypeId}}
            </td>

            <td>
              <span class="p-column-title">Descripción</span>
              {{tagType.description}}
            </td>

            <td>
              <span class="p-column-title">Para Vehículos</span>
              {{tagType.forVehicles ? 'Sí' : 'No'}}
            </td>

            <td>
              @if(tagType.image) {
                @if(tagType.image.fileType === 'pdf') {
                  <a 
                    class="btn btn-sm btn-light rounded-circle me-2"
                    [href]="tagType.image.urlFile"
                    target="_blank">
                    <i class="material-icons mt-1">description</i>
                  </a>
                }
              }
              <button (click)="open(tagType)" class="btn btn-sm btn-light rounded-circle me-2">
                <i class="material-icons mt-1">more_vert</i>
              </button>
              <button (click)="delete(tagType)" class="btn btn-sm btn-danger rounded-circle">
                <i class="material-icons mt-1">delete</i>
              </button>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>
