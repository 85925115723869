import { Component, Input } from '@angular/core';
import { Visit } from '../../models/visit.model';
import { VisitService } from '../../services/visit.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { VisitModalComponent } from '../visit-modal/visit-modal.component';
import { NeighborhoodService } from '../../services/neighborhood.service';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-visit-list',
  templateUrl: './visit-list.component.html',
  styleUrl: './visit-list.component.scss'
})
export class VisitListComponent {

  @Input() visits: Visit[] = [];
  @Input() loading: boolean = true;
  @Input() neighborhoodId?: string;
  @Input() enableReports: boolean = false;
  @Input() moreDetails: boolean = false;

  constructor(
    public authService: AuthService,
    private modalService: NgbModal,
    public neighborhoodService: NeighborhoodService,
    public visitService: VisitService,
  ) {
    
  }

  async delete(
    visit: Visit
  ) {
    const flag = confirm(
      `¿Esta seguro que desea borrar la visita de ${visit.fullName}?`
    );

    if(flag) {
      await this.visitService.delete(
        this.neighborhoodId!,
        visit
      );
    }
  }

  /*
   ██████╗ ██████╗ ███████╗███╗   ██╗
  ██╔═══██╗██╔══██╗██╔════╝████╗  ██║
  ██║   ██║██████╔╝█████╗  ██╔██╗ ██║
  ██║   ██║██╔═══╝ ██╔══╝  ██║╚██╗██║
  ╚██████╔╝██║     ███████╗██║ ╚████║
   ╚═════╝ ╚═╝     ╚══════╝╚═╝  ╚═══╝

  */
  open(
    visit: Visit
  ) {
    const modalRef = this.modalService.open(
      VisitModalComponent,
      { size: 'lg' }
    );
    modalRef.componentInstance.visit = visit;
  }

}
