import { Component } from '@angular/core';
import { Menu } from '../../models/menu.model';
import { MENU } from '../../data/menu.data';
import { SlideMenuService } from '../../services/slide-menu.service';

@Component({
  selector: 'app-dashboard-layout',
  templateUrl: './dashboard-layout.component.html',
  styleUrl: './dashboard-layout.component.scss'
})
export class DashboardLayoutComponent {

  public outerHeight: number = document.documentElement.clientHeight;

  public menu: Array<Menu> = MENU;

  constructor(
    public slideMenuService: SlideMenuService
  ) {}

}
