// VisitType.model.ts

import { FileMedia, IFileMedia } from "./file-media.model";
import { IProofType, ProofType } from "./proof-type.model";

export interface IVisitType {
  visitTypeId: string;
  description: string;
  fileId: string;
  showed: boolean;
  uidCreatedBy?: string; // Usando ? para indicar que es opcional
  uidUpdatedBy?: string; // Usando ? para indicar que es opcional
  createdAt: string;
  updatedAt: string;
  image?: IFileMedia;
  proofTypes?: IProofType[];
}

export class VisitType {
  visitTypeId: string;
  description: string;
  fileId: string;
  showed: boolean;
  uidCreatedBy?: string; // Usando ? para indicar que es opcional
  uidUpdatedBy?: string; // Usando ? para indicar que es opcional
  createdAt: Date;
  updatedAt: Date;
  image?: FileMedia;
  proofTypes: ProofType[];

  constructor(data: IVisitType) {
    this.visitTypeId = data.visitTypeId;
    this.description = data.description;
    this.fileId = data.fileId;
    this.showed = data.showed;
    this.uidCreatedBy = data.uidCreatedBy;
    this.uidUpdatedBy = data.uidUpdatedBy;
    this.createdAt = new Date(data.createdAt);
    this.updatedAt = new Date(data.updatedAt);
    this.image = data.image 
      ? new FileMedia(data.image) 
      : undefined;
    this.proofTypes = data.proofTypes
      ? data.proofTypes.map(
          (proofType) => new ProofType(proofType)
        )
      : [];
  }
}
