<div class="modal-header">
  <h4 class="modal-title">
    {{ streetBuilding?.name }} {{ houseFlat?.number }}
  </h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
</div>
<div class="modal-body">
  <div class="container-fluid">
    <div class="row">

      <div class="col-12 mb-3">
        <small><b class="text-secondary">Invitaciones</b></small>
      </div>

      <div class="col-12 mb-2">
        <form
          id="formInvitation"
          [formGroup]="formInvitation"
          (ngSubmit)="onSubmit()">
          <div class="row">

            <div class="col-12 col-md-7 col-lg-9 form-group">
              <input
                type="email"
                class="form-control"
                formControlName="email"
                [ngClass]="{
                  'is-invalid': submitted && formControls['email'].errors
                }"
                placeholder="Ingrese correo electrónico"
              />
              @if (submitted && formControls['email'].errors) {
                <div class="invalid-feedback">
                  @if (formControls['email'].errors['required']) {
                    <div>Por favor, ingrese el correo electrónico</div>
                  }
                </div>
              }
            </div>

            <div class="col-12 col-md-5 col-lg-3 mb-4">
              <button
                type="submit"
                form="formInvitation"
                class="btn btn-success w-100">
                Invitar                
              </button>
            </div>

          </div>
        </form>
      </div>
      <div class="col-12 mb-4">

        @for (invitation of houseFlat?.invitations; track $index) {
          <div class="row">

            <div class="col d-flex align-items-center">
              {{ invitation.email }}
            </div>

            <div class="col d-flex align-items-center text-center">
              <span class="badge rounded-pill text-bg-secondary">
                Pendiente
              </span>
            </div>

            <div class="col text-end">
              <button
                (click)="resendInvitation(invitation)"
                class="btn btn-sm btn-primary rounded-circle me-2 my-1">
                <i class="material-icons mt-1">send</i>
              </button>

              <button
                (click)="deleteInvitation(invitation)"
                class="btn btn-sm btn-danger rounded-circle me-2 my-1">
                <i class="material-icons mt-1">delete</i>
              </button>
            </div>

          </div>
        }

      </div>
        
      <hr>

      <div class="col-12 mb-3">
        <small><b class="text-secondary">Residentes</b></small>
      </div>
      <div class="col-12 mb-4">
        @for (resident of houseFlat?.residents; track $index) {
          <div class="row">

            <div class="col d-flex align-items-center">
              {{ resident.resident?.fullName }}
            </div>

            <div class="col d-flex align-items-center">
              {{ resident.resident?.email }}
            </div>

            <div class="col text-end">
              <button
                (click)="deleteResident(resident)"
                class="btn btn-sm btn-danger rounded-circle me-2 my-1">
                <i class="material-icons mt-1">delete</i>
              </button>
            </div>

          </div>
        }
      </div>

    </div>
  </div>
</div>
