import { Component } from '@angular/core';

@Component({
  selector: 'app-platforms-page',
  templateUrl: './platforms-page.component.html',
  styleUrl: './platforms-page.component.scss'
})
export class PlatformsPageComponent {

}
