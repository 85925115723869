import { Component, Input } from '@angular/core';
import { Visit } from '../../models/visit.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NeighborhoodService } from '../../services/neighborhood.service';
import { VisitService } from '../../services/visit.service';

@Component({
  selector: 'app-visit-modal',
  templateUrl: './visit-modal.component.html',
  styleUrl: './visit-modal.component.scss'
})
export class VisitModalComponent {

  @Input() visit?: Visit;

  public loading: boolean = false;
  public currentVisit?: Visit;

  constructor(
    public activeModal: NgbActiveModal,
    public neighborhoodService: NeighborhoodService,
    private visitService: VisitService,
  ) {}

  ngOnInit(): void {
    this.loadData();
  }

  /*
  ██╗      ██████╗  █████╗ ██████╗ ██████╗  █████╗ ████████╗ █████╗
  ██║     ██╔═══██╗██╔══██╗██╔══██╗██╔══██╗██╔══██╗╚══██╔══╝██╔══██╗
  ██║     ██║   ██║███████║██║  ██║██║  ██║███████║   ██║   ███████║
  ██║     ██║   ██║██╔══██║██║  ██║██║  ██║██╔══██║   ██║   ██╔══██║
  ███████╗╚██████╔╝██║  ██║██████╔╝██████╔╝██║  ██║   ██║   ██║  ██║
  ╚══════╝ ╚═════╝ ╚═╝  ╚═╝╚═════╝ ╚═════╝ ╚═╝  ╚═╝   ╚═╝   ╚═╝  ╚═╝

  */
  async loadData() {
    this.loading = true;

    this.currentVisit = await this.visitService.get(
      this.visit!
    );

    this.loading = false;
  }

}
