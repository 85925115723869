<div class="container-fluid py-3">
  <div class="row">

    @if(authService.currentUser?.profile === 'sudo') {
      <div class="col-12 mb-3 px-2 py-2 bg-white rounded">
        <button
          type="button"
          (click)="open()"
          class="btn btn-success d-flex align-items-center justify-content-center">
          <i class="material-icons">add</i>
          Agregar condominio
        </button>
      </div>
    }

    @for(neighborhood of neighborhoodService.neighborhoods; track $index) {

      <app-neighborhood-item
        [neighborhood]="neighborhood"
        class="col-12">
      </app-neighborhood-item>

    }

  </div>
</div>