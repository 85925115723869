<div class="modal-header">

  <div>
    <h4 class="modal-title">
      {{ houseFlat?.payment?.createdAt | dateToMoment: 'MMMM YYYY' }}
    </h4>
    <small>
      {{ streetBuilding?.name }} {{ houseFlat?.number }}
    </small>

  </div>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
</div>
<div class="modal-body">

  <form
    id="formPayment"
    [formGroup]="formPayment"
    (ngSubmit)="onSubmit()">
    <div class="container-fluid">
      <div class="row">

        @if(houseFlat?.payment?.file) {
          <a
            [href]="houseFlat?.payment?.file?.urlFile" 
            target="_blank"
            class="col-12 mb-3 text-decoration-none">
            <label for="file" class="form-label text-black">Comprobante de pago</label>
            <img
              [src]="houseFlat?.payment?.file?.urlFile"
              [alt]="houseFlat?.payment?.file?.fileId"
              class="img-thumbnail w-100">
          </a>
        } @else {

          <div class="col-12 form-group mb-4">
            <label htmlFor="imageBase64" class="form-label">
              Imagen del comprobante de pago
            </label>
            <input 
              class="form-control" 
              type="file" 
              accept="image/*"
              id="imageBase64"
              (change)="onFileChange($event)">
          </div>

        }

        <div class="col-12 mb-3 form-group">
          <label for="charge" class="form-label">Cantidad</label>
          <input
            type="number"
            id="charge"
            class="form-control"
            formControlName="charge"
            placeholder="Ingrese el monto"
            [ngClass]="{
              'is-invalid': submitted && formControls['charge'].errors
            }">
          <div *ngIf="submitted && formControls['charge'].errors" class="invalid-feedback">
            <div *ngIf="formControls['charge'].errors['required']">
              Por favor, ingrese el moonto
            </div>
          </div>
        </div>

        <div class="col-12 mb-3 form-group">
          <label for="notes" class="form-label">Notas</label>
          <textarea
            id="notes"
            class="form-control"
            formControlName="notes"
            rows="5"
            placeholder="Notas (Opcional)"></textarea>
        </div>

        <div class="col-12 mb-3">
          <label for="status" class="form-label">Estatus</label>
            <ng-select
            [items]="options"
            bindLabel="label"
            bindValue="value"
            formControlName="status"
            placeholder="Seleccione el estatus del pago"
            [ngClass]="{
              'is-invalid': submitted && formControls['status'].errors
            }">
            </ng-select>
            <div *ngIf="submitted && formControls['status'].errors" class="invalid-feedback">
              <div *ngIf="formControls['status'].errors['required']">
                Por favor, seleccione el estatus del pago
              </div>
            </div>
        </div>

      </div>
    </div>
  </form>
  
</div>
<div class="modal-footer">
  <div class="col-12 my-3">
    <button
      (click)="activeModal.close()"
      type="button"
      class="btn btn-outline-danger w-100">
      Cancelar
    </button>
  </div>
  <div class="col-12">
    <button
      type="submit"
      form="formPayment"
      class="btn btn-primary w-100">
      Actualizar
    </button>
  </div>
</div>