import { Menu } from "../models/menu.model";

export const MENU: Array<Menu> = [
  {
    icon: 'dashboard',
    route: '/dashboard',
    title: 'Dashboard',
    rolesCanAccess: ['sudo'],
  },
  {
    icon: 'home',
    route: 'neighborhoods',
    title: 'Colonias',
    rolesCanAccess: ['admin', 'sudo'],
  },
  {
    icon: 'category',
    title: 'Catálogos',
    rolesCanAccess: ['sudo'],
    children: [
      {
        icon: '',
        title: 'Tipos de colonias',
        route: 'catalogues/neighborhood-types',
      },
      {
        icon: '',
        title: 'Tipos de cuentas bancarias',
        route: 'catalogues/bank-account-types',
      },
      {
        icon: '',
        title: 'Tipos de pruebas',
        route: 'catalogues/proof-types',
      },
      {
        icon: '',
        title: 'Tipos de notificaciones',
        route: 'catalogues/notification-types',
      },
      {
        icon: '',
        title: 'Tipos de visita',
        route: 'catalogues/visit-types',
      },
    ]
  },
  {
    icon: 'support_agent',
    route: 'platforms',
    title: 'Soporte',
    rolesCanAccess: ['sudo'],
  },
  {
    icon: 'security',
    title: 'Seguridad',
    rolesCanAccess: ['sudo'],
    children: [
      {
        icon: '',
        title: 'Módulos',
        route: 'security/modules',
      },
      {
        icon: '',
        title: 'Perfiles',
        route: 'security/profiles',
      },
      {
        icon: '',
        title: 'Usuarios',
        route: 'security/users',
      },
      
    ]
  }
];

export const MENU_NEIGH: Array<Menu> = [
  {
    icon: 'dashboard',
    route: ':neighborhoodId',
    title: 'Dashboard',
    rolesCanAccess: ['admin', 'sudo'],
  },
  // {
  //   icon: 'person',
  //   route: ':neighborhoodId/settlers',
  //   title: 'Colonos',
  // },
  {
    icon: 'security',
    route: ':neighborhoodId/administrators',
    title: 'Administradores',
    rolesCanAccess: ['sudo'],
  },
  {
    icon: 'security',
    route: ':neighborhoodId/guards',
    title: 'Vigilantes',
    rolesCanAccess: ['sudo'],
  },
  {
    icon: 'payments',
    route: ':neighborhoodId/payments',
    title: 'Pagos',
    rolesCanAccess: ['admin', 'sudo'],
  },
  {
    icon: 'person',
    route: ':neighborhoodId/houses-flats',
    title: 'Colonos',
    rolesCanAccess: ['admin', 'sudo'],
  },
  {
    icon: 'house',
    route: ':neighborhoodId/streets-buildings',
    title: 'Casas',
    rolesCanAccess: ['admin', 'sudo'],
  },
  {
    icon: 'confirmation_number',
    route: ':neighborhoodId/visits',
    title: 'Visitas',
    rolesCanAccess: ['admin', 'sudo'],
  },
  {
    icon: 'article',
    route: ':neighborhoodId/announcements',
    title: 'Anuncios',
    rolesCanAccess: ['admin', 'sudo'],
  },
  {
    icon: 'settings',
    title: 'Configuración',
    children: [
      {
        icon: '',
        title: 'Módulos',
        route: ':neighborhoodId/settings/modules',
        rolesCanAccess: ['sudo'],
      },
      {
        icon: '',
        title: 'Perfiles',
        route: ':neighborhoodId/settings/profiles',
        rolesCanAccess: ['sudo'],
      },
      {
        icon: '',
        title: 'Tipos de prueba',
        route: ':neighborhoodId/settings/proof-types',
        rolesCanAccess: ['sudo'],
      },
      {
        icon: '',
        title: 'Cuentas bancarias',
        route: ':neighborhoodId/settings/bank-accounts',
        rolesCanAccess: ['admin', 'sudo'],
      },
      {
        icon: '',
        title: 'Número de emergencia',
        route: ':neighborhoodId/settings/emergency-numbers',
        rolesCanAccess: ['admin', 'sudo'],
      },
      {
        icon: '',
        title: 'Servicios',
        route: ':neighborhoodId/settings/services',
        rolesCanAccess: ['admin', 'sudo'],
      },
      {
        icon: '',
        title: 'General',
        route: ':neighborhoodId/settings/general',
        rolesCanAccess: ['admin', 'sudo'],
      },
      // {
      //   icon: '',
      //   title: 'Usuarios',
      //   route: ':neighborhoodId/settings/users',
      //   rolesCanAccess: ['admin', 'sudo'],
      // },
      
    ]
  }
];