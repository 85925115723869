import { Component, Input } from '@angular/core';
import { StreetBuilding } from '../../models/street-building.model';
import { StreetBuildingService } from '../../services/street-building.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StreetBuildingModalComponent } from '../street-building-modal/street-building-modal.component';
import { HouseFlat } from '../../models/house-flat.model';
import { HouseFlatService } from '../../services/house-flat.service';
import { HouseFlatModalComponent } from '../house-flat-modal/house-flat-modal.component';

@Component({
  selector: 'app-street-building-item',
  templateUrl: './street-building-item.component.html',
  styleUrl: './street-building-item.component.scss'
})
export class StreetBuildingItemComponent {

  @Input() streetBuilding?: StreetBuilding;

  isCollapsed = true;

  constructor(
    public houseFlatService: HouseFlatService,
    public streetBuildingService: StreetBuildingService,
    private modalService: NgbModal,
  ) {

  }

  /*
  ██████╗ ███████╗██╗     ███████╗████████╗███████╗
  ██╔══██╗██╔════╝██║     ██╔════╝╚══██╔══╝██╔════╝
  ██║  ██║█████╗  ██║     █████╗     ██║   █████╗
  ██║  ██║██╔══╝  ██║     ██╔══╝     ██║   ██╔══╝
  ██████╔╝███████╗███████╗███████╗   ██║   ███████╗
  ╚═════╝ ╚══════╝╚══════╝╚══════╝   ╚═╝   ╚══════╝

  */
  async delete(
    streetBuilding: StreetBuilding
  ) {

    const flag = confirm(
      `¿Esta seguro que desea borrar la calle ${streetBuilding.name}?`
    );

    if(flag) {
      await this.streetBuildingService.delete(
        streetBuilding
      );
    }

  }

  /*
  ██████╗ ███████╗██╗     ███████╗████████╗███████╗██╗  ██╗ ██████╗ ██╗   ██╗███████╗███████╗███████╗██╗      █████╗ ████████╗
  ██╔══██╗██╔════╝██║     ██╔════╝╚══██╔══╝██╔════╝██║  ██║██╔═══██╗██║   ██║██╔════╝██╔════╝██╔════╝██║     ██╔══██╗╚══██╔══╝
  ██║  ██║█████╗  ██║     █████╗     ██║   █████╗  ███████║██║   ██║██║   ██║███████╗█████╗  █████╗  ██║     ███████║   ██║
  ██║  ██║██╔══╝  ██║     ██╔══╝     ██║   ██╔══╝  ██╔══██║██║   ██║██║   ██║╚════██║██╔══╝  ██╔══╝  ██║     ██╔══██║   ██║
  ██████╔╝███████╗███████╗███████╗   ██║   ███████╗██║  ██║╚██████╔╝╚██████╔╝███████║███████╗██║     ███████╗██║  ██║   ██║
  ╚═════╝ ╚══════╝╚══════╝╚══════╝   ╚═╝   ╚══════╝╚═╝  ╚═╝ ╚═════╝  ╚═════╝ ╚══════╝╚══════╝╚═╝     ╚══════╝╚═╝  ╚═╝   ╚═╝

  */
  async deleteHouseFlat(
    houseFlat: HouseFlat
  ) {

    const flag = confirm(
      `¿Esta seguro que desea borrar la casa ${houseFlat.number}?`
    );

    if(flag) {
      await this.houseFlatService.delete(
        this.streetBuilding!.neighborhoodId,
        houseFlat
      );
    }

  }

  /*
   ██████╗ ██████╗ ███████╗███╗   ██╗
  ██╔═══██╗██╔══██╗██╔════╝████╗  ██║
  ██║   ██║██████╔╝█████╗  ██╔██╗ ██║
  ██║   ██║██╔═══╝ ██╔══╝  ██║╚██╗██║
  ╚██████╔╝██║     ███████╗██║ ╚████║
   ╚═════╝ ╚═╝     ╚══════╝╚═╝  ╚═══╝

  */
  open(
    streetBuilding?: StreetBuilding,
  ) {
    const modalRef = this.modalService.open(
      StreetBuildingModalComponent
    );

    modalRef.componentInstance.streetBuilding = streetBuilding;
    modalRef.componentInstance.neighborhoodId = this.streetBuilding!.neighborhoodId;

  }

  /*
   ██████╗ ██████╗ ███████╗███╗   ██╗██╗  ██╗ ██████╗ ██╗   ██╗███████╗███████╗███████╗██╗      █████╗ ████████╗
  ██╔═══██╗██╔══██╗██╔════╝████╗  ██║██║  ██║██╔═══██╗██║   ██║██╔════╝██╔════╝██╔════╝██║     ██╔══██╗╚══██╔══╝
  ██║   ██║██████╔╝█████╗  ██╔██╗ ██║███████║██║   ██║██║   ██║███████╗█████╗  █████╗  ██║     ███████║   ██║
  ██║   ██║██╔═══╝ ██╔══╝  ██║╚██╗██║██╔══██║██║   ██║██║   ██║╚════██║██╔══╝  ██╔══╝  ██║     ██╔══██║   ██║
  ╚██████╔╝██║     ███████╗██║ ╚████║██║  ██║╚██████╔╝╚██████╔╝███████║███████╗██║     ███████╗██║  ██║   ██║
   ╚═════╝ ╚═╝     ╚══════╝╚═╝  ╚═══╝╚═╝  ╚═╝ ╚═════╝  ╚═════╝ ╚══════╝╚══════╝╚═╝     ╚══════╝╚═╝  ╚═╝   ╚═╝

  */
  openHouseFlat(
    houseFlat?: HouseFlat,
  ) {
    const modalRef = this.modalService.open(
      HouseFlatModalComponent
    );

    modalRef.componentInstance.houseFlat = houseFlat;
    modalRef.componentInstance.neighborhoodId = this.streetBuilding!.neighborhoodId;
    modalRef.componentInstance.streetBuildingId = this.streetBuilding!.streetBuildingId;

  }

  async update(
    houseFlat: HouseFlat
  ) {
    const flag = await this.houseFlatService.update(
      this.streetBuilding!.neighborhoodId,
      houseFlat
    );

    if(!flag) {
      houseFlat.status = !houseFlat.status;
    }
  }

}
