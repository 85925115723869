import { Component, Input } from '@angular/core';
import { Gate } from '../../models/gate.model';
import { DataFormModel } from '../../models/data-form-model.model';
import { FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GateService } from '../../services/gate.service';
import { UtilService } from '../../services/util.service';

@Component({
  selector: 'app-gate-modal',
  templateUrl: './gate-modal.component.html',
  styleUrl: './gate-modal.component.scss'
})
export class GateModalComponent {

  @Input() neighborhoodId: string = '';
  @Input() gate?: Gate;

  public dataForm: DataFormModel = {
    gateName: {
      value: '',
      type: 'text',
      label: 'Nombre de la puerta de acceso',
      requiredMessage: 'Por favor, ingrese el nombre de la puerta de acceso',
      required: true,
      validators: [Validators.required]
    },
    firestoreUid: {
      value: '',
      type: 'text',
      label: 'UID de Firestore',
      required: false,
      validators: []
    }
  };

  public formGate!: FormGroup;
  public submitted: boolean = false;
  public loading: boolean = false;

  constructor(
    public activeModal: NgbActiveModal,
    public gateService: GateService,
    public utilService: UtilService
  ) {}

  ngOnInit(): void {
    this.loadData();
  }

  get f() {
    return this.formGate.controls;
  } 

  async create() {
    const flag = await this.gateService.create(
      this.neighborhoodId,
      this.formGate.value
    );

    if(flag) {
      this.activeModal.close();
    }
  }

  async loadData() {
    this.loading = true;

    if(this.gate) {
      this.dataForm = {
        ...this.dataForm,
      };

      for(let key of Object.keys(this.dataForm)) {
        if (key in this.gate!) {
          const gateKey = key as keyof typeof this.gate;
          this.dataForm[key].value = this.gate[gateKey]!;
        }
      } 
    }

    this.formGate = this.utilService.buildForm(this.dataForm);
    this.loading = false;
  }

  async onSubmit() {
    this.submitted = true;

    if(this.formGate.invalid) {
      return;
    }

    if(this.gate) {
      await this.update();
    } else {
      await this.create();
    }
  }

  async update() {
    this.formGate.value.neighborhoodId = this.gate!.neighborhoodId;
    this.formGate.value.gateId = this.gate!.gateId;

    const flag = await this.gateService.update(
      this.formGate.value
    );

    if(flag) {
      this.activeModal.close();
    }
  }

}
