<div class="container-fluid py-3">
  <div class="row">

      <div class="col-12">

          <p-table #dt
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
              [filterDelay]="0"
              [loading]="visitTypeService.loading"
              [paginator]="true"
              [rows]="10"
              [rowHover]="true"
              [rowsPerPageOptions]="[10,25,50]"
              [showCurrentPageReport]="true" 
              [value]="visitTypeService.visitTypes"
              pDroppable="row">
              <ng-template pTemplate="caption">
                <div class="container-fluid">
                  <div class="row">
                    <div class="col-4 d-flex align-items-center">
                      Tipos de visitas
                    </div>

                    <div class="col-8 text-end">
                      <button
                        (click)="open()"
                        class="btn btn-success rounded-circle me-2">
                        <i class="material-icons mt-1">add</i>
                      </button>

                      <button
                        (click)="visitTypeService.getAll()"
                        class="btn btn-primary rounded-circle me-2">
                        <i class="material-icons mt-1">refresh</i>
                      </button>

                      <button
                        class="btn btn-light rounded-circle me-2">
                        <i class="material-icons mt-1">filter_alt_off</i>
                      </button>
                    </div>
                  </div>
                </div>
              </ng-template>
              <ng-template pTemplate="header">
                  <tr>

                    <th></th>

                    <th pSortableColumn="visitTypeId">
                      ID
                      <p-sortIcon field="visitTypeId"></p-sortIcon>
                      <p-columnFilter type="text" field="visitTypeId" display="menu"></p-columnFilter>
                    </th>

                    <th pSortableColumn="description">
                      Descripción
                      <p-sortIcon field="description"></p-sortIcon>
                      <p-columnFilter type="text" field="description" display="menu"></p-columnFilter>
                    </th>

                    <th pSortableColumn="showed">
                      Estatus
                      <p-sortIcon field="showed"></p-sortIcon>
                    </th>

                    <th></th>
                  </tr>
              </ng-template>
              <ng-template pTemplate="body" let-visitType let-rowIndex="rowIndex">
                  <tr class="p-selectable-row">

                    <td>
                      @if(visitType.image) {
                        <div 
                          class="bg-center"
                          style="width: 5vh; height: 5vh;"
                          [ngStyle]="{
                            'background-image': 'url(' + visitType.image.urlFile + ')'
                          }">
                        </div>
                      }
                    </td>

                    <td>
                      <span class="p-column-title">ID</span>
                      {{visitType.visitTypeId}}
                    </td>

                    <td>
                      <span class="p-column-title">Descripción</span>
                      {{visitType.description}}
                    </td>

                    <td>
                      <span class="p-column-title">Estatus</span>
                      <p-inputSwitch
                        (onChange)="update(visitType)"
                        [(ngModel)]="visitType.showed">
                      </p-inputSwitch>
                    </td>

                    <td>

                      <button
                        (click)="open(visitType)"
                        class="btn btn-sm btn-light rounded-circle me-2 my-1">
                        <i class="material-icons mt-1">edit</i>
                      </button>

                      <button
                        class="btn btn-sm btn-danger rounded-circle me-2 my-1">
                        <i class="material-icons mt-1">delete</i>
                      </button>
                    </td>
                  </tr>
              </ng-template>
          </p-table>

      </div>

  </div>
</div>