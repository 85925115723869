import { FileMedia, IFileMedia } from "./file-media.model";
import { IProofType, ProofType } from "./proof-type.model";

export interface IProof {
  proofId: string;
  proofTypeId: string;
  visitId: string;
  fileId: string;
  uidCreatedBy?: string;
  createdAt: string;
  proofType?: IProofType;
  file?: IFileMedia;
}

export class Proof {
  proofId: string;
  proofTypeId: string;
  visitId: string;
  fileId: string;
  uidCreatedBy?: string;
  createdAt: Date;
  proofType?: ProofType;
  file?: FileMedia;

  constructor(data: IProof) {
    this.proofId = data.proofId;
    this.proofTypeId = data.proofTypeId;
    this.visitId = data.visitId;
    this.fileId = data.fileId;
    this.uidCreatedBy = data.uidCreatedBy;
    this.createdAt = new Date(data.createdAt);
    this.proofType = data.proofType 
      ? new ProofType(data.proofType) 
      : undefined;
    this.file = data.file
      ? new FileMedia(data.file)
      : undefined;
  }
}
