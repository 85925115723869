import { Component, Input } from '@angular/core';
import { GateService } from '../../services/gate.service';
import { AccessDoorGroup } from '../../models/access-door-group.model';
import { DataFormModel } from '../../models/data-form-model.model';
import { FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AccessDoorGroupService } from '../../services/access-door-group.service';
import { UtilService } from '../../services/util.service';
import { AccessDoorGroupTypeService } from '../../services/access-door-group-type.service';

@Component({
  selector: 'app-access-door-group-modal',
  templateUrl: './access-door-group-modal.component.html',
  styleUrl: './access-door-group-modal.component.scss'
})
export class AccessDoorGroupModalComponent {

  @Input() accessDoorGroup?: AccessDoorGroup;

  public dataForm: DataFormModel = {
    accessDoorGroupTypeId: {
      value: null,
      type: 'select',
      idSelect: 'accessDoorGroupTypeId',
      labelSelect: 'accessDoorGroup',
      data: [],
      label: 'Tipo de acceso',
      placeholder: 'Seleccione el tipo de acceso',
      requiredMessage: 'Por favor, seleccione el tipo de acceso',
      required: true,
      validators: [Validators.required]
    },
    accessDoorGroup: {
      value: '',
      type: 'text',
      label: 'Nombre del grupo',
      requiredMessage: 'Por favor, ingrese el nombre del grupo de acceso',
      required: true,
      validators: [Validators.required]
    },
    status: {
      value: false,
      type: 'switch',
      label: 'Mostrar',
      required: false,
      validators: []
    }
  };

  public formAccessDoorGroup!: FormGroup;
  public submitted: boolean = false;
  public loading: boolean = false;

  constructor(
    public activeModal: NgbActiveModal,
    public accessDoorGroupService: AccessDoorGroupService,
    private accessDoorGroupTypeService: AccessDoorGroupTypeService,
    public gateService: GateService,
    public utilService: UtilService
  ) { }

  ngOnInit(): void {
    this.loadData();
  }

  get f() {
    return this.formAccessDoorGroup.controls;
  } 

  async create() {
    const flag = await this.accessDoorGroupService.create(
      this.gateService.currentGate!,
      this.formAccessDoorGroup.value
    );

    if (flag) {
      this.activeModal.close();
    }
  }

  async loadData() {
    this.loading = true;

    this.dataForm['accessDoorGroupTypeId'].data = await this.accessDoorGroupTypeService.getAll();

    if (this.accessDoorGroup) {
      this.dataForm = {
        ...this.dataForm
      };

      for (let key of Object.keys(this.dataForm)) {
        if (key in this.accessDoorGroup!) {
          const standardKey = key as keyof typeof this.accessDoorGroup;
          this.dataForm[key].value = this.accessDoorGroup[standardKey]!;
        }
      }
    }

    this.formAccessDoorGroup = this.utilService.buildForm(this.dataForm);
    this.loading = false;
  }

  async onSubmit() {
    this.submitted = true;

    if (this.formAccessDoorGroup.invalid) {
      return;
    }

    if (this.accessDoorGroup) {
      await this.update();
    } else {
      await this.create();
    }
  }

  async update() {
    this.formAccessDoorGroup.value.accessDoorGroupId = this.accessDoorGroup!.accessDoorGroupId;

    const flag = await this.accessDoorGroupService.update(
      this.gateService.currentGate!,
      this.formAccessDoorGroup.value
    );

    if (flag) {
      this.activeModal.close();
    }
  }

}
