import { Component } from '@angular/core';

@Component({
  selector: 'app-settings-modules-page',
  templateUrl: './settings-modules-page.component.html',
  styleUrl: './settings-modules-page.component.scss'
})
export class SettingsModulesPageComponent {

}
