import { Component } from '@angular/core';

@Component({
  selector: 'app-visits-page',
  templateUrl: './visits-page.component.html',
  styleUrl: './visits-page.component.scss'
})
export class VisitsPageComponent {

}
