import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GateService } from '../../../services/gate.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Gate } from '../../../models/gate.model';
import { GateModalComponent } from '../../../components/gate-modal/gate-modal.component';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'app-gates-page',
  templateUrl: './gates-page.component.html',
  styleUrl: './gates-page.component.scss'
})
export class GatesPageComponent {

  public neighborhoodId?: string;

  constructor(
    public authService: AuthService,
    private activatedRoute: ActivatedRoute,
    public gateService: GateService,
    private modalService: NgbModal,
  ) {
    this.activatedRoute.params.subscribe(
      async params => {
        this.neighborhoodId = params['neighborhoodId'];
        await this.gateService.getAll(this.neighborhoodId!);
      }
    );
  }

  async delete(
    gate: Gate
  ) {
    const flag = confirm(`¿Está seguro que desea borrar la puerta de acceso "${gate.gateName}"?`);

    if (flag) {
      await this.gateService.delete(gate);
    }
  }

  open(
    gate?: Gate
  ) {
    const modalRef = this.modalService.open(
      GateModalComponent
    );

    modalRef.componentInstance.gate = gate;
    modalRef.componentInstance.neighborhoodId = this.neighborhoodId;
  }

}
