import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { HttpClient } from '@angular/common/http';
import { SpinnerService } from './spinner.service';
import { ToastService } from './toast.service';
import { environment } from '../../environments/environment';
import { IServerResponse, ServerResponse } from '../models/server-response.model';
import { ITagType, TagType } from '../models/tag-type.model';

@Injectable({
  providedIn: 'root'
})
export class TagTypeService {

  public loading: boolean = false;
  public tagTypes: TagType[] = [];

  constructor(
    public authService: AuthService,
    private http: HttpClient,
    public spinnerService: SpinnerService,
    public toastService: ToastService
  ) { }

  /*
   ██████╗██████╗ ███████╗ █████╗ ████████╗███████╗
  ██╔════╝██╔══██╗██╔════╝██╔══██╗╚══██╔══╝██╔════╝
  ██║     ██████╔╝█████╗  ███████║   ██║   █████╗
  ██║     ██╔══██╗██╔══╝  ██╔══██║   ██║   ██╔══╝
  ╚██████╗██║  ██║███████╗██║  ██║   ██║   ███████╗
   ╚═════╝╚═╝  ╚═╝╚══════╝╚═╝  ╚═╝   ╚═╝   ╚══════╝

  */
  async create(
    tagType: ITagType
  ): Promise<boolean> {

    let flag = false;

    const urlApi = `${environment.urlApi}/tag-types`;

    this.spinnerService.loading = true;
    this.spinnerService.message = 'Registrando tipo de etiqueta';

    try {
      const token = await this.authService.getToken();

      const response = await new Promise<ServerResponse>(
        (resolve, reject) => {
          this.http.post<IServerResponse>(
            urlApi,
            JSON.stringify(tagType),
            { 
              headers: { 
                "content-type": "application/json",
                'Authorization': token.toString()
              }
            }
          ).subscribe({
            next: (
              response: IServerResponse
            ) => resolve(
              new ServerResponse(response)
            ),
            error: (error) => {
              if (error.error) {
                resolve(new ServerResponse(error.error));
              } else {
                reject(error);
                this.toastService.show({
                  header: 'Error de servidor',
                  body: `${error}`,
                  classname: 'bg-danger text-white'
                });
              }
            }
          });
        }
      );

      if (response.statusCode === 201) {
        flag = true;

        this.toastService.show({
          body: `${response.message}`,
          classname: 'bg-success text-white'
        });

        this.getAll();
      } else {
        this.toastService.show({
          header: 'Error de servidor',
          body: `${response.message}`,
          classname: 'bg-danger text-white'
        });
      }

    } catch (error) {
      console.log(error);
      
      this.toastService.show({
        header: 'Error',
        body: `${error}`,
        classname: 'bg-danger text-white'
      });
    }

    this.spinnerService.loading = false;
    this.spinnerService.message = '';

    return flag;
  }

  /*
  ██████╗ ███████╗██╗     ███████╗████████╗███████╗
  ██╔══██╗██╔════╝██║     ██╔════╝╚══██╔══╝██╔════╝
  ██║  ██║█████╗  ██║     █████╗     ██║   █████╗
  ██║  ██║██╔══╝  ██║     ██╔══╝     ██║   ██╔══╝
  ██████╔╝███████╗███████╗███████╗   ██║   ███████╗
  ╚═════╝ ╚══════╝╚══════╝╚══════╝   ╚═╝   ╚══════╝

  */
  async delete(
    tagType: TagType
  ): Promise<boolean> {

    let flag = false;

    const urlApi = `${environment.urlApi}/tag-types/${tagType.tagTypeId}`;

    this.spinnerService.loading = true;
    this.spinnerService.message = 'Eliminando tipo de etiqueta';

    try {
      const token = await this.authService.getToken();

      const response = await new Promise<ServerResponse>(
        (resolve, reject) => {
          this.http.delete<IServerResponse>(
            urlApi,
            {
              headers: { 
                'Authorization': token.toString()
              }
            }
          ).subscribe({
            next: (
              response: IServerResponse
            ) => resolve(
              new ServerResponse(response)
            ),
            error: (error) => {
              if (error.error) {
                resolve(new ServerResponse(error.error));
              } else {
                reject(error);
                this.toastService.show({
                  header: 'Error de servidor',
                  body: `${error}`,
                  classname: 'bg-danger text-white'
                });
              }
            }
          });
        }
      );

      if (response.statusCode === 200) {
        flag = true;

        this.toastService.show({
          body: `${response.message}`,
          classname: 'bg-success text-white'
        });

        this.getAll();
      } else {
        this.toastService.show({
          header: 'Error de servidor',
          body: `${response.message}`,
          classname: 'bg-danger text-white'
        });
      }

    } catch (error) {
      console.log(error);
      
      this.toastService.show({
        header: 'Error',
        body: `${error}`,
        classname: 'bg-danger text-white'
      });
    }

    this.spinnerService.loading = false;
    this.spinnerService.message = '';

    return flag;
  }

  /*
   ██████╗ ███████╗████████╗
  ██╔════╝ ██╔════╝╚══██╔══╝
  ██║  ███╗█████╗     ██║
  ██║   ██║██╔══╝     ██║
  ╚██████╔╝███████╗   ██║
   ╚═════╝ ╚══════╝   ╚═╝

  */
  async get(
    tagTypeId: string
  ): Promise<TagType | null> {

    let tagType: TagType | null = null;

    const urlApi = `${environment.urlApi}/tag-types/${tagTypeId}`;

    this.spinnerService.loading = true;
    this.spinnerService.message = 'Obteniendo tipo de etiqueta';

    try {
      const token = await this.authService.getToken();

      const response = await new Promise<ServerResponse<{ tagType: ITagType }>>(
        (resolve, reject) => {
          this.http.get<IServerResponse<{ tagType: ITagType }>>(
            urlApi,
            { 
              headers: { 
                "content-type": "application/json",
                "Authorization": token.toString()
              }
            }
          ).subscribe({
            next: (
              response: IServerResponse<{ tagType: ITagType }>
            ) => resolve(
              new ServerResponse<{ tagType: ITagType }>(response)
            ),
            error: (error) => {
              if (error.error) {
                resolve(new ServerResponse(error.error));
              } else {
                reject(error);
                this.toastService.show({
                  header: 'Error de servidor',
                  body: `${error}`,
                  classname: 'bg-danger text-white'
                });
              }
            }
          });
        }
      );

      if (response.statusCode === 200) {
        tagType = new TagType(response.data!.tagType);
      } else {
        this.toastService.show({
          header: 'Error de servidor',
          body: `${response.message}`,
          classname: 'bg-danger text-white'
        });
      }

    } catch (error) {
      console.log(error);
    }

    this.spinnerService.loading = false;
    this.spinnerService.message = '';

    return tagType;
  }

  /*
   ██████╗ ███████╗████████╗ █████╗ ██╗     ██╗
  ██╔════╝ ██╔════╝╚══██╔══╝██╔══██╗██║     ██║
  ██║  ███╗█████╗     ██║   ███████║██║     ██║
  ██║   ██║██╔══╝     ██║   ██╔══██║██║     ██║
  ╚██████╔╝███████╗   ██║   ██║  ██║███████╗███████╗
   ╚═════╝ ╚══════╝   ╚═╝   ╚═╝  ╚═╝╚══════╝╚══════╝

  */
  async getAll() {
    this.loading = true;
    this.tagTypes = [];

    const urlApi = `${environment.urlApi}/tag-types`;

    try {
      const token = await this.authService.getToken();

      const response = await new Promise<ServerResponse<{ tagTypes: ITagType[] }>>(
        (resolve, reject) => {
          this.http.get<IServerResponse<{ tagTypes: ITagType[] }>>(
            urlApi,
            { 
              headers: { 
                "content-type": "application/json",
                "Authorization": token.toString()
              }
            }
          ).subscribe({
            next: (
              response: IServerResponse<{ tagTypes: ITagType[] }>
            ) => resolve(
              new ServerResponse<{ tagTypes: ITagType[] }>(response)
            ),
            error: (error) => {
              if (error.error) {
                resolve(new ServerResponse(error.error));
              } else {
                reject(error);
              }
            }
          });
        }
      );

      if (response.statusCode === 200) {
        this.tagTypes = response.data!.tagTypes.map(
          tagType => new TagType(tagType)
        );
      } else {
        this.toastService.show({
          header: 'Error de servidor',
          body: `${response.message}`,
          classname: 'bg-danger text-white'
        });
      }

    } catch (error) {
      console.log(error);
    }

    this.loading = false;
  }

  /*
  ██╗   ██╗██████╗ ██████╗  █████╗ ████████╗███████╗
  ██║   ██║██╔══██╗██╔══██╗██╔══██╗╚══██╔══╝██╔════╝
  ██║   ██║██████╔╝██║  ██║███████║   ██║   █████╗
  ██║   ██║██╔═══╝ ██║  ██║██╔══██║   ██║   ██╔══╝
  ╚██████╔╝██║     ██████╔╝██║  ██║   ██║   ███████╗
   ╚═════╝ ╚═╝     ╚═════╝ ╚═╝  ╚═╝   ╚═╝   ╚══════╝

  */
  async update(
    tagType: TagType
  ): Promise<boolean> {
  
    let flag = false;
  
    const urlApi = `${environment.urlApi}/tag-types/${tagType.tagTypeId}`;
    this.spinnerService.loading = true;
    this.spinnerService.message = 'Actualizando tipo de etiqueta';
  
    try {
      const token = await this.authService.getToken();
  
      const response = await new Promise<ServerResponse>(
        (resolve, reject) => {
          this.http.put<IServerResponse>(
            urlApi,
            JSON.stringify(tagType),
            { 
              headers: { 
                "content-type": "application/json", 
                'Authorization': token.toString() 
              } 
            }
          ).subscribe({
            next: (
              response: IServerResponse
            ) => resolve(new ServerResponse(response)),
  
            error: (error) => {
              if (error.error) {
                resolve(new ServerResponse(error.error));
              } else {
                reject(error);
                this.toastService.show({
                  header: 'Error de servidor',
                  body: `${error}`,
                  classname: 'bg-danger text-white'
                });
              }
            }
          });
        }
      );
  
      if (response.statusCode === 200) {
        flag = true;
        this.toastService.show({
          body: `${response.message}`,
          classname: 'bg-success text-white'
        });
        this.getAll();
      } else {
        this.toastService.show({
          header: 'Error de servidor',
          body: `${response.message}`,
          classname: 'bg-danger text-white'
        });
      }
  
    } catch (error) {
      console.log(error);
      this.toastService.show({
        header: 'Error',
        body: `${error}`,
        classname: 'bg-danger text-white'
      });
    }
  
    this.spinnerService.loading = false;
    this.spinnerService.message = '';
    return flag;
  }
  
}
