<div class="modal-header">
  <h4 class="modal-title">
    Nueva anuncio
  </h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
</div>
<div class="modal-body">
  
  <form
    id="formAnnouncement"
    [formGroup]="formAnnouncement"
    (ngSubmit)="onSubmit()">
    <div class="container-fluid">
      <div class="row">

        <div class="col-12 mb-3">
          <label for="announcementType" class="form-label">Tipo de anuncio</label>
            <ng-select
            [items]="announcementTypeService.announcementTypes"
            bindLabel="description"
            bindValue="announcementType"
            formControlName="announcementType"
            placeholder="Seleccione un tipo de anuncio"
            [loading]="announcementTypeService.loading"
            [ngClass]="{
              'is-invalid': submitted && formControls['announcementType'].errors
            }">
            </ng-select>
            <div *ngIf="submitted && formControls['announcementType'].errors" class="invalid-feedback">
              <div *ngIf="formControls['announcementType'].errors['required']">
                Por favor, seleccione el tipo de anuncio
              </div>
            </div>
        </div>

        @if(uploadedFiles.length > 0) {

          <div class="col-12 mb-3">
            <label for="uploadedImages" class="form-label">Imágenes cargadas</label>
            <div id="uploadedImages" class="d-flex flex-wrap">
              <div *ngFor="let file of uploadedFiles" class="p-2">
                  <img *ngIf="file.startsWith('data:image/')" [src]="file" alt="Imagen cargada" class="img-thumbnail" style="max-width: 150px; max-height: 150px;">
              </div>
            </div>
          </div>

        }

        <div class="col-12 mb-3">
          <label class="form-label">Tipo de archivo</label>
          <div class="form-check">
            <input
              class="form-check-input"
              type="radio"
              id="uploadImage"
              name="fileType"
              value="image"
              formControlName="fileType"
              [(ngModel)]="fileType">
            <label class="form-check-label" for="uploadImage">
              Imágenes
            </label>
          </div>
          <div class="form-check">
            <input
              class="form-check-input"
              type="radio"
              id="uploadPdf"
              name="fileType"
              value="pdf"
              formControlName="fileType"
              [(ngModel)]="fileType">
            <label class="form-check-label" for="uploadPdf">
              PDF
            </label>
          </div>
        </div>

        @if(fileType != null) {

          @if(fileType === 'image') {

            <div 
              class="col-12 vh-30 bg-tertiary bg-center d-flex align-items-center justify-content-center mt-2">
              <button 
                class="btn btn-primary px-5 py-2 mb-5 w-100"
                type="button"
                (click)="compressFile()">
                Subir fotos
              </button>
            </div>

          } @else {

            <div class="col-12 mb-3">
              <label for="file" class="form-label">Agregar PDF</label>
              <input
                type="file"
                id="file"
                class="form-control"
                formControlName="file"
                accept=".pdf"
                (change)="onFileChange($event)"
                multiple>
            </div>

          }

        }

        <div class="col-12 mb-3 form-group">
          <label for="title" class="form-label">Título</label>
          <input
            type="text"
            id="title"
            class="form-control"
            formControlName="title"
            placeholder="Ingrese el título del anuncio"
            [ngClass]="{
              'is-invalid': submitted && formControls['title'].errors
            }">
          <div *ngIf="submitted && formControls['title'].errors" class="invalid-feedback">
            <div *ngIf="formControls['title'].errors['required']">
              Por favor, ingrese el título del anuncio
            </div>
          </div>
        </div>

        <div class="col-12 mb-3 form-group">
          <label for="content" class="form-label">Contenido</label>
          <textarea
            id="content"
            class="form-control"
            formControlName="content"
            rows="5"
            placeholder="Ingrese el contenido del anuncio"
            [ngClass]="{
              'is-invalid': submitted && formControls['content'].errors
            }"></textarea>
          <div *ngIf="submitted && formControls['content'].errors" class="invalid-feedback">
            <div *ngIf="formControls['content'].errors['required']">
              Por favor, ingrese el contenido del anuncio
            </div>
          </div>
        </div>

      </div>
    </div>
  </form>

</div>
<div class="modal-footer">
  <div class="col-12 my-3">
    <button
      (click)="activeModal.close()"
      type="button"
      class="btn btn-outline-danger w-100">
      Cancelar
    </button>
  </div>
  <div class="col-12">
    <button
      type="submit"
      form="formAnnouncement"
      class="btn btn-primary w-100">
      Publicar
    </button>
  </div>
</div>