<div class="container-fluid py-3">
  <div class="row">

    <div class="col-12 d-flex align-items-center mb-4">
                      
      @if(years.length > 0) {

        <div class="col-4 pe-1">
          <ng-select
            [items]="years"
            bindLabel="year"
            bindValue="year"
            [(ngModel)]="yearSelected"
            placeholder="Seleccionar año"
            (keyup.enter)="getMovements()"
            (change)="onChangeYear($event)">
          </ng-select>
        </div>

        <div class="col-4 ps-1">
          <ng-select
            [items]="months"
            bindLabel="description"
            bindValue="month"
            [(ngModel)]="monthSelected"
            (keyup.enter)="getMovements()"
            placeholder="Seleccionar mes">
          </ng-select>
        </div>

        <div class="col-4 ps-2">
          <div class="row flex justify-content-end">

            <button
              (click)="getMovements()"
              type="button"
              [disabled]="!yearSelected || !monthSelected || movementService.loading"
              class="btn btn-primary rounded-circle me-2 d-flex align-items-center justify-content-center"
              style="height: 4vh; width: 4vh;">
              <i class="material-icons" style="font-size: calc(0.8rem + 0.8vh);">search</i>
            </button>

            <button
              (click)="downloadReport()"
              type="button"
              [disabled]="!yearSelected || !monthSelected || movementService.loading"
              class="btn btn-primary rounded-circle me-2 d-flex align-items-center justify-content-center"
              style="height: 4vh; width: 4vh;">
              <i class="material-icons" style="font-size: calc(0.8rem + 0.8vh);">
                download
              </i>
            </button>

            <button
              (click)="open()"
              type="button"
              class="btn btn-success rounded-circle me-2 d-flex align-items-center justify-content-center"
              style="height: 4vh; width: 4vh;">
              <i 
                class="material-icons"
                style="font-size: calc(0.8rem + 0.8vh);">
                add
              </i>
            </button>

            <button
              (click)="getMovements()"
              type="button"
              class="btn btn-light rounded-circle me-2 d-flex align-items-center justify-content-center"
              style="height: 4vh; width: 4vh;">
              <i 
                class="material-icons"
                style="font-size: calc(0.8rem + 0.8vh);">
                refresh
              </i>
            </button>

          </div>
        </div>

      }

    </div>

    <div class="col-12 mb-2">

      <div class="row">


        <div class="col-6 col-md-4 col-xl-3 mb-3">
          <div class="row px-2">
            <div class="col-12 border rounded bg-white py-2">
              <div class="row">

                <div 
                  class="col-12 mb-3 d-flex justify-content-between align-items-center">
                  <span
                    class="fw-500 text-secondary"
                    style="font-size: calc(0.7rem + 0.7vh);">
                    Balance del mes
                  </span>

                  <div class="border rounded-circle d-flex align-items-center justify-content-center"
                    style="height: 3.5vh; width: 3.5vh;">
                    <i 
                      class="material-icons"
                      style="font-size: calc(0.6rem + 0.6vh);">
                      account_balance
                    </i>
                  </div>

                </div>

                @if(movementService.loading) {

                  <div class="col-12 text-center">
                    <div class="spinner-border text-primary" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </div>

                } @else {

                  <div 
                    class="col-12" 
                    style="font-size: calc(1rem + 1vh);">
                    <b>{{ movementService.insights?.monthBalance?.balance | currency }}</b>
                  </div>

                }

              </div>
            </div>
          </div>
        </div>

        <div class="col-6 col-md-4 col-xl-3 mb-3">
          <div class="row px-2">
            <div class="col-12 border rounded bg-white py-2">
              <div class="row">

                <div 
                  class="col-12 mb-3 d-flex justify-content-between align-items-center">
                  <span
                    class="fw-500 text-secondary"
                    style="font-size: calc(0.7rem + 0.7vh);">
                    Ingresos
                  </span>

                  <div class="border rounded-circle d-flex align-items-center justify-content-center"
                    style="height: 3.5vh; width: 3.5vh;">
                    <i 
                      class="material-icons text-success"
                      style="font-size: calc(0.6rem + 0.6vh);">
                      arrow_downward
                    </i>
                  </div>

                </div>

                @if(movementService.loading) {

                  <div class="col-12 text-center">
                    <div class="spinner-border text-primary" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </div>

                } @else {

                  <div 
                    class="col-12 text-success" 
                    style="font-size: calc(1rem + 1vh);">
                    <b>{{ movementService.insights?.monthBalance?.income | currency }}</b>
                  </div>

                }

              </div>
            </div>
          </div>
        </div>

        <div class="col-6 col-md-4 col-xl-3 mb-3">
          <div class="row px-2">
            <div class="col-12 border rounded bg-white py-2">
              <div class="row">

                <div 
                  class="col-12 mb-3 d-flex justify-content-between align-items-center">
                  <span
                    class="fw-500 text-secondary"
                    style="font-size: calc(0.7rem + 0.7vh);">
                    Egresos
                  </span>

                  <div class="border rounded-circle d-flex align-items-center justify-content-center"
                    style="height: 3.5vh; width: 3.5vh;">
                    <i 
                      class="material-icons text-danger"
                      style="font-size: calc(0.6rem + 0.6vh);">
                      arrow_upward
                    </i>
                  </div>

                </div>

                @if(movementService.loading) {

                  <div class="col-12 text-center">
                    <div class="spinner-border text-primary" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </div>

                } @else {

                  <div 
                    class="col-12 text-danger" 
                    style="font-size: calc(1rem + 1vh);">
                    <b>{{ movementService.insights?.monthBalance?.expenses | currency }}</b>
                  </div>

                }

              </div>
            </div>
          </div>
        </div>

        <div class="col-6 col-md-4 col-xl-3 mb-3">
          <div class="row px-2">
            <div class="col-12 border rounded bg-white py-2">
              <div class="row">

                <div 
                  class="col-12 mb-3 d-flex justify-content-between align-items-center">
                  <span
                    class="fw-500 text-secondary"
                    style="font-size: calc(0.7rem + 0.7vh);">
                    Cuentas por pagar
                  </span>

                  <div class="border rounded-circle d-flex align-items-center justify-content-center"
                    style="height: 3.5vh; width: 3.5vh;">
                    <i 
                      class="material-icons"
                      style="font-size: calc(0.6rem + 0.6vh);">
                      attach_money
                    </i>
                  </div>

                </div>

                @if(movementService.loading) {

                  <div class="col-12 text-center">
                    <div class="spinner-border text-primary" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </div>

                } @else {

                  <div 
                    class="col-12" 
                    style="font-size: calc(1rem + 1vh);">
                    <b>{{ movementService.insights?.monthBalance?.pendingExpenses | currency }}</b>
                  </div>

                }

              </div>
            </div>
          </div>
        </div>

        <div class="col-6 col-md-4 col-xl-6 mb-3">
          <div class="row px-2">
            <div class="col-12 border rounded bg-white py-2">
              <div class="row">

                <div 
                  class="col-12 mb-3 d-flex justify-content-between align-items-center">
                  <span
                    class="fw-500 text-secondary"
                    style="font-size: calc(0.7rem + 0.7vh);">
                    Cobros pendientes
                  </span>

                  <div class="border rounded-circle d-flex align-items-center justify-content-center"
                    style="height: 3.5vh; width: 3.5vh;">
                    <i 
                      class="material-icons"
                      style="font-size: calc(0.6rem + 0.6vh);">
                      history
                    </i>
                  </div>

                </div>

                @if(movementService.loading) {

                  <div class="col-12 text-center">
                    <div class="spinner-border text-primary" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </div>

                } @else {

                  <div 
                    class="col-12" 
                    style="font-size: calc(1rem + 1vh);">
                    <b>{{ movementService.insights?.monthBalance?.pendingIncome | currency }}</b>
                  </div>

                }

              </div>
            </div>
          </div>
        </div>

        <div class="col-6 col-md-4 col-xl-6 mb-3">
          <div class="row px-2">
            <div class="col-12 border rounded bg-white py-2">
              <div class="row">

                <div 
                  class="col-12 mb-3 d-flex justify-content-between align-items-center">
                  <span
                    class="fw-500 text-secondary"
                    style="font-size: calc(0.7rem + 0.7vh);">
                    Balance total
                  </span>

                  <div class="border rounded-circle d-flex align-items-center justify-content-center"
                    style="height: 3.5vh; width: 3.5vh;">
                    <i 
                      class="material-icons"
                      style="font-size: calc(0.6rem + 0.6vh);">
                      account_balance_wallet
                    </i>
                  </div>

                </div>

                @if(movementService.loading) {

                  <div class="col-12 text-center">
                    <div class="spinner-border text-primary" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </div>

                } @else {

                  <div 
                    class="col-12" 
                    style="font-size: calc(1rem + 1vh);">
                    <b>{{ movementService.insights?.totalBalance?.balance | currency }}</b>
                  </div>

                }

              </div>
            </div>
          </div>
        </div>

      </div>

    </div>

    <!-- <div class="col-12 col-lg-7 col-xl-8 mb-4">

      <div class="card">
        <p-chart type="bar" [data]="data" [options]="options" />
      </div>

    </div>

    <div class="col-12 col-lg-5 col-xl-4 mb-4">
      <div class="card flex justify-content-center">
        <p-chart type="doughnut" [data]="dataMonth" [options]="optionsMonth" />
      </div>
    </div> -->

    <div class="col-12">

        <p-table #dt
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            [filterDelay]="0"
            [loading]="movementService.loading"
            [paginator]="true"
            [rows]="10"
            [rowHover]="true"
            [rowsPerPageOptions]="[10,25,50]"
            [showCurrentPageReport]="true" 
            [value]="movementService.movements"
            pDroppable="row">
            <ng-template pTemplate="caption">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-4 d-flex align-items-center mb-2">
                    Estado de cuenta
                  </div>

                  <div class="col-8 text-end mb-3">

                    <button
                      class="btn btn-light rounded-circle me-2">
                      <i class="material-icons mt-1">filter_alt_off</i>
                    </button>
                  </div>

                </div>
              </div>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>

                  <th></th>

                  <th pSortableColumn="description">
                    Descripción
                    <p-sortIcon field="description"></p-sortIcon>
                    <p-columnFilter type="text" field="description" display="menu"></p-columnFilter>
                  </th>

                  <th>
                    Cantidad
                    <p-sortIcon field="amount"></p-sortIcon>
                    <p-columnFilter type="number" field="amount" display="menu"></p-columnFilter>
                  </th>

                  <th pSortableColumn="status">
                    Estatus
                    <p-sortIcon field="status"></p-sortIcon>
                  </th>

                  <th>
                    Fecha
                    <p-sortIcon field="createdAt"></p-sortIcon>
                    <p-columnFilter type="date" field="createdAt" display="menu"></p-columnFilter>
                  </th>

                  <th >
                    Comprobante
                  </th>

                  <th></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-movement let-rowIndex="rowIndex">
                <tr class="p-selectable-row">

                  <td>
                    <i 
                      class="material-icons mt-1"
                      [ngClass]="{
                        'text-success': movement.movementType,
                        'text-danger': !movement.movementType
                      }">
                      
                      @if(movement.movementType) {
                        arrow_downward
                      } @else {
                        arrow_upward
                      }

                    </i>
                  </td>

                  <td>
                    <span class="p-column-title">Descripción</span>
                    {{movement.description}} {{ movement.notes != '' ? "(" + movement.notes + ")" : '' }}
                  </td>

                  <td
                    [ngClass]="{
                      'text-success': movement.movementType && movement.status,
                      'text-danger': !movement.movementType && movement.status,
                      'text-secondary': !movement.status
                    }">
                    <span class="p-column-title">Cantidad</span>
                    {{ !movement.movementType ? '-' : '' }}{{ movement.amount | currency }}
                  </td>

                  <td>
                    <span class="p-column-title">Estatus</span>
                    
                    <span 
                      class="badge rounded-pill"
                      [ngClass]="{
                        'text-bg-success': movement.movementType && movement.status,
                        'text-bg-danger': !movement.movementType && movement.status,
                        'text-bg-secondary': !movement.status
                      }">
                      {{ movement.statusDescription }}
                    </span>
                  </td>

                  <td>
                    <span class="p-column-title">Fecha</span>
                    {{movement.createdAt | dateToMoment: 'DD MMM yyyy' : neighborhoodService.currentNeighborhood?.timeZone }}
                  </td>

                  <td>
                    <i 
                      class="material-icons mt-1"
                      [ngClass]="{
                        'text-success': movement.fileId,
                        'text-danger': !movement.fileId
                      }">
                      @if(movement.fileId) {
                        done
                      } @else {
                        close
                      }
                    </i>
                  </td>

                  <td>

                    <button
                      (click)="open(movement)"
                      class="btn btn-sm btn-light rounded-circle me-2 my-1">
                      <i class="material-icons mt-1">edit</i>
                    </button>

                    <button
                      (click)="delete(movement)"
                      class="btn btn-sm btn-danger rounded-circle me-2 my-1">
                      <i class="material-icons mt-1">delete</i>
                    </button>
                  </td>
                </tr>
            </ng-template>
        </p-table>

    </div>

  </div>
</div>