import { FileMedia, IFileMedia } from "./file-media.model";

export interface IParcelService {
  parcelServiceId: string;
  parcelService: string;
  showExtraField: boolean;
  status: boolean;
  fileId?: string;
  uidCreatedBy?: string;
  uidUpdatedBy?: string;
  createdAt: string;
  updatedAt: string;
  image?: IFileMedia;
}

export class ParcelService {
  parcelServiceId: string;
  parcelService: string;
  showExtraField: boolean;
  status: boolean;
  fileId?: string;
  uidCreatedBy?: string;
  uidUpdatedBy?: string;
  createdAt: Date;
  updatedAt: Date;
  image?: FileMedia;

  constructor(data: IParcelService) {
    this.parcelServiceId = data.parcelServiceId;
    this.parcelService = data.parcelService;
    this.showExtraField = data.showExtraField;
    this.status = data.status;
    this.fileId = data.fileId;
    this.uidCreatedBy = data.uidCreatedBy;
    this.uidUpdatedBy = data.uidUpdatedBy;
    this.createdAt = new Date(data.createdAt);
    this.updatedAt = new Date(data.updatedAt);
    this.image = data.image 
      ? new FileMedia(data.image) 
      : undefined;
  }
}
