<div class="container-fluid py-3">
  <div class="row">

    <div 
      class="col-12 bg-white py-3 px-3 rounded mb-2 text-decoration-none text-dark">
      <div class="row">

        <div class="col d-flex align-items-center">
          <h4 class="m-0"><b>
            {{ charge?.createdAt | dateToMoment: 'MMMM YYYY' }}
          </b></h4>
        </div>

        <div class="col">
          <div class="row">

            <div class="col text-danger d-flex align-items-center">
              <i class="material-icons me-2">cancel</i>{{ charge?.overdue }}
            </div>
            <div class="col text-secondary d-flex align-items-center">
              <i class="material-icons me-2">pending</i>{{ charge?.pending }}
            </div>
            <div class="col text-success d-flex align-items-center">
              <i class="material-icons me-2">check_circle</i>{{ charge?.done }}
            </div>

          </div>
        </div>

        <div class="col text-center">
          {{ charge?.charge | currency }}
        </div>

      </div>
    </div>

    <div 
      class="col-12 bg-white py-3 px-3 rounded mb-3">
      <div class="row">

        <div class="col d-flex align-items-center">
          
          <button
            (click)="downloadReport()"
            class="btn btn-primary d-flex align-items-center justify-content-center me-2"
            type="button">
            <i class="material-icons me-2">download</i> Descargar reporte
          </button>

        </div>

      </div>
    </div>

    @for (streetBuilding of charge?.streetsBuildings; track $index) {

      <div class="col-12 text-secondary mb-1">
        <small><b>{{ streetBuilding?.name }}</b></small>
      </div>

      <div class="col-12 bg-white px-3 rounded mb-4">
        <div class="row">
  
          @for(houseFlat of streetBuilding!.housesFlats; track $index) {
  
            <div class="col-12 py-2 border-bottom">
              <div class="row">
  
                <div class="col d-flex align-items-center">
                  {{ houseFlat.number }}
                </div>
  
                <div class="col d-flex align-items-center">
                  {{ houseFlat.fullName }}
                </div>
                
                <div class="col d-flex align-items-center">
                  {{ houseFlat.residentType?.description }}
                </div>
  
                <div class="col d-flex align-items-center">
                  <div [innerHTML]="houseFlat.notes | newlineToBr"></div>
                </div>
  
                <div class="col text-center d-flex align-items-center">
                  @if(houseFlat.payment?.status != null) {
                    @if(houseFlat.payment?.status) {
                      <span class="badge rounded-pill text-bg-success">
                        Pagado
                      </span>
                    } @else {
                      <span class="badge rounded-pill text-bg-secondary">
                        Pendiente
                      </span>
                    }
                  } @else {
                    <span class="badge rounded-pill text-bg-danger">
                      Sin pago
                    </span>
                  }
                </div>
  
                <div class="col-auto text-end d-flex align-items-center justify-content-end">
  
                  <p-inputSwitch
                    class="me-2"
                    (onChange)="update(houseFlat)"
                    [(ngModel)]="houseFlat.status">
                  </p-inputSwitch>

                  <button
                    (click)="openPayment(streetBuilding, houseFlat)"
                    class="btn btn-sm btn-light rounded-circle me-2 my-1">
                    <i class="material-icons mt-1">payments</i>
                  </button>
  
                  <button
                    (click)="openHouseFlat(houseFlat)"
                    class="btn btn-sm btn-light rounded-circle me-2 my-1">
                    <i class="material-icons mt-1">edit</i>
                  </button>
  
                  <!-- <a
                    [routerLink]="[houseFlat.houseFlatId]"
                    class="btn btn-sm btn-light rounded-circle me-2 my-1">
                    <i class="material-icons mt-1">more_vert</i>
                  </a> -->
                </div>
  
              </div>
            </div>
  
          }
  
          @if(streetBuilding!.housesFlats.length === 0) {
            <div class="col-12 text-center">
              <small class="text-secondary">
                No hay casas registradas
              </small>
            </div>
          }
  
        </div>
      </div>  

    }
    
  </div>
</div>