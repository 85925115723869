import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SpinnerService } from '../../../services/spinner.service';
import { TagType } from '../../../models/tag-type.model';
import { TagTypeModalComponent } from '../../../components/tag-type-modal/tag-type-modal.component';
import { TagTypeService } from '../../../services/tag-type.service';

@Component({
  selector: 'app-tag-types-page',
  templateUrl: './tag-types-page.component.html',
  styleUrl: './tag-types-page.component.scss'
})
export class TagTypesPageComponent {

  constructor(
    public tagTypeService: TagTypeService,
    private modalService: NgbModal,
    public spinnerService: SpinnerService
  ) {
    this.tagTypeService.getAll();
  }

  async delete(
    tagType: TagType
  ) {
    const flag = confirm(`¿Está seguro que desea borrar el tipo de etiqueta "${tagType.description}"?`);

    if (flag) {
      await this.tagTypeService.delete(tagType);
    }
  }

  open(
    tagType?: TagType
  ) {
    const modalRef = this.modalService.open(
      TagTypeModalComponent
    );

    modalRef.componentInstance.tagType = tagType;
  }

}
