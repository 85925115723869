import { Component } from '@angular/core';
import { NeighborhoodService } from '../../../../services/neighborhood.service';
import { AuthService } from '../../../../services/auth.service';
import { VisitType } from '../../../../models/visit-type.model';

@Component({
  selector: 'app-general-page',
  templateUrl: './general-page.component.html',
  styleUrl: './general-page.component.scss'
})
export class GeneralPageComponent {

  constructor(
    public authService: AuthService,
    public neighborhoodService: NeighborhoodService
  ) {

  }

  update() {

    this.neighborhoodService.update();
  }

  async setProofRequired(
    visitTypeId: string,
    proofTypeId: string,
    status: boolean
  ) {
    console.log('visitTypeId', visitTypeId);
    console.log('proofTypeId', proofTypeId);
    console.log('status', status);

    this.neighborhoodService.setProofRequired(
      visitTypeId,
      proofTypeId,
      status
    )
    
  } 

}
