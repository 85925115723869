import { FileMedia, IFileMedia } from "./file-media.model";

export interface ITagType {
  tagTypeId: string;
  description: string;
  forVehicles: boolean;
  fileId?: string;
  image?: IFileMedia;
  uidCreatedBy: string;
  uidUpdatedBy: string;
  createdAt: string;
  updatedAt: string;
}

export class TagType {
  tagTypeId: string;
  description: string;
  forVehicles: boolean;
  fileId?: string;
  image?: FileMedia;
  uidCreatedBy: string;
  uidUpdatedBy: string;
  createdAt: string;
  updatedAt: string;

  constructor(data: ITagType) {
    this.tagTypeId = data.tagTypeId;
    this.description = data.description;
    this.forVehicles = data.forVehicles;
    this.fileId = data.fileId;
    this.uidCreatedBy = data.uidCreatedBy;
    this.uidUpdatedBy = data.uidUpdatedBy;
    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;
    this.image = data.image 
      ? new FileMedia(
          data.image
        ) 
      : undefined;
  }
}
