import { AccessDoorGroup, IAccessDoorGroup } from "./access-door-group.model";

export interface IGate {
  gateId: string;
  neighborhoodId: string;
  gateName: string;
  firestoreUid: string;
  uidCreatedBy?: string; // Opcional
  uidUpdatedBy?: string; // Opcional
  createdAt: string;
  updatedAt: string;
  accessDoorGroups?: IAccessDoorGroup[];
}

export class Gate {
  gateId: string;
  neighborhoodId: string;
  gateName: string;
  firestoreUid: string;
  uidCreatedBy?: string;
  uidUpdatedBy?: string;
  createdAt: Date;
  updatedAt: Date;
  accessDoorGroups?: AccessDoorGroup[];

  constructor(data: IGate) {
    this.gateId = data.gateId;
    this.neighborhoodId = data.neighborhoodId;
    this.gateName = data.gateName;
    this.firestoreUid = data.firestoreUid;
    this.uidCreatedBy = data.uidCreatedBy;
    this.uidUpdatedBy = data.uidUpdatedBy;
    this.createdAt = new Date(data.createdAt);
    this.updatedAt = new Date(data.updatedAt);
    this.accessDoorGroups = data.accessDoorGroups
      ? data.accessDoorGroups.map(
          (accessDoorGroup) => new AccessDoorGroup(accessDoorGroup)
        )
      : [];
  }
}
