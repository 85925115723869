import { Component } from '@angular/core';
import { ParcelServiceService } from '../../../services/parcel-service.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SpinnerService } from '../../../services/spinner.service';
import { IParcelService, ParcelService } from '../../../models/parcel-service.model';
import { ParcelServiceModalComponent } from '../../../components/parcel-service-modal/parcel-service-modal.component';

@Component({
  selector: 'app-parcel-services-page',
  templateUrl: './parcel-services-page.component.html',
  styleUrl: './parcel-services-page.component.scss'
})
export class ParcelServicesPageComponent {

  constructor(
    public parcelServiceService: ParcelServiceService,
    private modalService: NgbModal,
    public spinnerService: SpinnerService,
  ) {

  }

  /*
  ██████╗ ███████╗██╗     ███████╗████████╗███████╗
  ██╔══██╗██╔════╝██║     ██╔════╝╚══██╔══╝██╔════╝
  ██║  ██║█████╗  ██║     █████╗     ██║   █████╗
  ██║  ██║██╔══╝  ██║     ██╔══╝     ██║   ██╔══╝
  ██████╔╝███████╗███████╗███████╗   ██║   ███████╗
  ╚═════╝ ╚══════╝╚══════╝╚══════╝   ╚═╝   ╚══════╝

  */
  async delete(
    parcelService: ParcelService
  ) {

    const flag = confirm(
      `¿Esta seguro que desea borrar la paquetería ${parcelService.parcelService}?`
    );

    if(flag) {
      await this.parcelServiceService.delete(
        parcelService
      );
    }

  }

  /*
    ██████╗ ██████╗ ███████╗███╗   ██╗
  ██╔═══██╗██╔══██╗██╔════╝████╗  ██║
  ██║   ██║██████╔╝█████╗  ██╔██╗ ██║
  ██║   ██║██╔═══╝ ██╔══╝  ██║╚██╗██║
  ╚██████╔╝██║     ███████╗██║ ╚████║
    ╚═════╝ ╚═╝     ╚══════╝╚═╝  ╚═══╝

  */
  open(
    parcelService?: ParcelService,
  ) {
    const modalRef = this.modalService.open(
      ParcelServiceModalComponent
    );

    modalRef.componentInstance.parcelService = parcelService;

  }

  /*
  ██╗   ██╗██████╗ ██████╗  █████╗ ████████╗███████╗
  ██║   ██║██╔══██╗██╔══██╗██╔══██╗╚══██╔══╝██╔════╝
  ██║   ██║██████╔╝██║  ██║███████║   ██║   █████╗
  ██║   ██║██╔═══╝ ██║  ██║██╔══██║   ██║   ██╔══╝
  ╚██████╔╝██║     ██████╔╝██║  ██║   ██║   ███████╗
    ╚═════╝ ╚═╝     ╚═════╝ ╚═╝  ╚═╝   ╚═╝   ╚══════╝

  */
  /**
   * FUNCTION update
   * 
   * @param parcelService 
   */
  async update(
    parcelService: IParcelService
  ) {

    console.log(parcelService);
    

    this.spinnerService.loading = true;
    this.spinnerService.message = 'Actualizando paquetería';

    await this.parcelServiceService.update(
      parcelService
    );

  }

}
