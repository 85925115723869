<div class="modal-header">

  <div>
    <h4 class="modal-title">
      Dispositivos
    </h4>
    <small>
      Casa {{ houseFlat?.number }}
    </small>

  </div>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
</div>
<div class="modal-body">
  <div class="container-fluid">
    <div class="row">

      @for (device of houseFlat?.devices; track $index) {

        <div class="col-12 rounded mb-3 bg-light py-3 px-2">
          <div class="row">

            <div class="col-6 mb-3">
              <div class="row">

                <div class="col-12">
                  <b>SO</b>
                </div>

                <div class="col-12">
                  {{ device.os }}
                </div>

              </div>
            </div>

            <div class="col-6 mb-3">
              <div class="row">

                <div class="col-12">
                  <b>Versión</b>
                </div>

                <div class="col-12">
                  {{ device.version }}
                </div>

              </div>
            </div>

            <div class="col-6 mb-3">
              <div class="row">

                <div class="col-12">
                  <b>Marca</b>
                </div>

                <div class="col-12">
                  {{ device.brand }}
                </div>

              </div>
            </div>

            <div class="col-6 mb-3">
              <div class="row">

                <div class="col-12">
                  <b>Modelo</b>
                </div>

                <div class="col-12">
                  {{ device.model }}
                </div>

              </div>
            </div>

            <div class="col-6 mb-3">
              <div class="row">

                <div class="col-12">
                  <b>Descripción</b>
                </div>

                <div class="col-12">
                  {{ device.description }}
                </div>

              </div>
            </div>

            <div class="col-6 mb-3">
              <div class="row">

                <div class="col-12">
                  <b>Última modificación</b>
                </div>

                <div class="col-12">
                  {{ device.updatedAt | dateToMoment: 'ddd DD MMM yyyy HH:mm' : neighborhoodService.currentNeighborhood?.timeZone }}
                </div>

              </div>
            </div>

          </div>
        </div>

      }

    </div>
  </div>
</div>
<div class="modal-footer">
  <div class="col-12 my-3">
    <button
      (click)="activeModal.close()"
      type="button"
      class="btn btn-success w-100">
      OK
    </button>
  </div>
</div>