<div class="container-fluid py-3">
  <div class="row">
    <div class="col-12">
      <p-table #dt
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        [filterDelay]="0"
        [loading]="gateService.loading"
        [paginator]="true"
        [rows]="10"
        [rowHover]="true"
        [rowsPerPageOptions]="[10, 25, 50]"
        [showCurrentPageReport]="true"
        [value]="gateService.gates"
        pDroppable="row">

        <ng-template pTemplate="caption">
          <div class="container-fluid">
            <div class="row">
              <div class="col-4 d-flex align-items-center">
                Puertas de acceso
              </div>
              <div class="col-8 text-end">
                
                @if(authService.currentUser?.profile === 'sudo') {
                  <button (click)="open()" class="btn btn-success rounded-circle me-2">
                    <i class="material-icons mt-1">add</i>
                  </button>
                }

                <button (click)="gateService.getAll(neighborhoodId!)" class="btn btn-primary rounded-circle me-2">
                  <i class="material-icons mt-1">refresh</i>
                </button>
              </div>
            </div>
          </div>
        </ng-template>

        <ng-template pTemplate="header">
          <tr>
            <th pSortableColumn="gateId">
              ID
              <p-sortIcon field="gateId"></p-sortIcon>
              <p-columnFilter type="text" field="gateId" display="menu"></p-columnFilter>
            </th>

            <th pSortableColumn="firestoreUid">
              UID
              <p-sortIcon field="firestoreUid"></p-sortIcon>
              <p-columnFilter type="text" field="firestoreUid" display="menu"></p-columnFilter>
            </th>

            <th pSortableColumn="gateName">
              Nombre
              <p-sortIcon field="gateName"></p-sortIcon>
              <p-columnFilter type="text" field="gateName" display="menu"></p-columnFilter>
            </th>

            <th>Acciones</th>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-gate>
          <tr class="p-selectable-row">
            <td>
              <span class="p-column-title">ID</span>
              {{ gate.gateId }}
            </td>

            <td>
              <span class="p-column-title">UID</span>
              {{ gate.firestoreUid }}
            </td>

            <td>
              <span class="p-column-title">Nombre</span>
              {{ gate.gateName }}
            </td>

            <td>
              <a
                [routerLink]="[gate.gateId]"
                class="btn btn-sm btn-light rounded-circle me-2"
                placement="top" 
                ngbTooltip="Ver más">
                <i class="material-icons mt-1">more_vert</i>
              </a>
              @if(authService.currentUser?.profile === 'sudo') {
                <button (click)="open(gate)" class="btn btn-sm btn-light rounded-circle me-2">
                  <i class="material-icons mt-1">edit</i>
                </button>
                <button (click)="delete(gate)" class="btn btn-sm btn-danger rounded-circle">
                  <i class="material-icons mt-1">delete</i>
                </button>
              }
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>
