<div class="container-fluid py-3">
  <div class="row">
    <div class="col-12">
      <p-table #dt
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        [filterDelay]="0"
        [loading]="guardService.loading"
        [paginator]="true"
        [rows]="10"
        [rowHover]="true"
        [rowsPerPageOptions]="[10, 25, 50]"
        [showCurrentPageReport]="true" 
        [value]="guardService.guards"
        pDroppable="row">
        
        <ng-template pTemplate="caption">
          <div class="container-fluid">
            <div class="row">
              <div class="col-4 d-flex align-items-center">
                Vigilantes
              </div>
              <div class="col-8 text-end">

                <div class="d-inline-block" ngbDropdown #myDrop="ngbDropdown">
                  <button
                    type="button"
                    class="btn btn-success me-2"
                    id="dropdownManual"
                    ngbDropdownAnchor
                    (focus)="myDrop.open()">
                    Agregar
                  </button>
                  <div ngbDropdownMenu aria-labelledby="dropdownManual">
                    <button 
                      (click)="create()"
                      ngbDropdownItem>
                      Nuevo usuario
                    </button>
                    <button 
                      (click)="linkUser()"
                      ngbDropdownItem>
                      Vincular usuario
                    </button>
                  </div>
                </div>

                <button (click)="guardService.getAll(neighborhoodId!)" class="btn btn-primary rounded-circle me-2">
                  <i class="material-icons mt-1">refresh</i>
                </button>
              </div>
            </div>
          </div>
        </ng-template>

        <ng-template pTemplate="header">
          <tr>

            <th>
              Usuario
              <p-sortIcon></p-sortIcon>
              <p-columnFilter type="text" display="menu"></p-columnFilter>
            </th>

            <th>Acciones</th>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-guard>
          <tr class="p-selectable-row">
            <td>
              <span class="p-column-title">Usuario</span>
              {{ guard.email != '' ? guard.email : guard.username }}
            </td>

            <td>
              <button
                class="btn btn-sm btn-light rounded-circle me-2">
                <i class="material-icons mt-1">more_vert</i>
              </button>

              <button 
                (click)="delete(guard)"
                class="btn btn-sm btn-danger rounded-circle">
                <i class="material-icons mt-1">delete</i>
              </button>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>
