import { Injectable } from '@angular/core';
import { SpinnerService } from './spinner.service';
import { NgxImageCompressService } from 'ngx-image-compress';

@Injectable({
  providedIn: 'root'
})
export class ImageService {

  private limitSizeMB: number = 3;
  private ratioPercent: number = 100;
  private qualityPercent: number = 100;
  private sizeMB: number = 1024 * 1024;

  constructor(
    private imageCompressService: NgxImageCompressService,
    public spinnerService: SpinnerService,
  ) { }

  /*
   ██████╗ ██████╗ ███╗   ███╗██████╗ ██████╗ ███████╗███████╗███████╗███████╗██╗██╗     ███████╗
  ██╔════╝██╔═══██╗████╗ ████║██╔══██╗██╔══██╗██╔════╝██╔════╝██╔════╝██╔════╝██║██║     ██╔════╝
  ██║     ██║   ██║██╔████╔██║██████╔╝██████╔╝█████╗  ███████╗███████╗█████╗  ██║██║     █████╗
  ██║     ██║   ██║██║╚██╔╝██║██╔═══╝ ██╔══██╗██╔══╝  ╚════██║╚════██║██╔══╝  ██║██║     ██╔══╝
  ╚██████╗╚██████╔╝██║ ╚═╝ ██║██║     ██║  ██║███████╗███████║███████║██║     ██║███████╗███████╗
   ╚═════╝ ╚═════╝ ╚═╝     ╚═╝╚═╝     ╚═╝  ╚═╝╚══════╝╚══════╝╚══════╝╚═╝     ╚═╝╚══════╝╚══════╝

  */
   async compressFile(): Promise<string[]> {

    let compressedImages: string[] = [];

    try {
      const images = await this.imageCompressService.uploadMultipleFiles();

      // console.log(
      //   'Tamaño de la imagen original: ',
      //   this.imageCompressService.byteCount(image)
      // );

      // console.log(
      //   'Tamaño según convertido: ',
      //   (this.imageCompressService.byteCount(image) / this.sizeMB)
      // );

      this.spinnerService.loading = true;
      this.spinnerService.message = 'Procesando imágenes...';

      
      for(const image of images) {
        
        this.ratioPercent = 100;
        this.qualityPercent = 100;

        if ((this.imageCompressService.byteCount(image.image) / this.sizeMB) > this.limitSizeMB) {
          this.ratioPercent = 30;
          this.qualityPercent = 30;

          const imageCompressed = await this.imageCompressService.compressFile(
            image.image,
            image.orientation,
            this.ratioPercent,
            this.qualityPercent
          );

          compressedImages.push(imageCompressed);

        } else {
          compressedImages.push(image.image);
        }

        this.spinnerService.loading = false;
        this.spinnerService.message = '';

      }

    } catch (error) {
      console.error(error);
    }
    
    return compressedImages;

  }

}
