export interface IResidentType {
  residentTypeId: string;
  description: string;
}

export class ResidentType {
  residentTypeId: string;
  description: string;

  constructor(data: IResidentType) {
    this.residentTypeId = data.residentTypeId;
    this.description = data.description;
  }
}
